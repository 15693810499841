.sem-quote-bubble {
  right: -19%;
  left: auto;
  top: -50%;
  width: 236px;
}
@media (max-width: 767px) {
  .sem-quote-bubble {
    top: -20px !important;
    right: 0 !important;
  }
}
@media (max-width: 1500px) {
  .sem-quote-bubble {
    right: -43px;
    top: -25px;
    width: 150px;
    height: 150px;
    margin: 0 auto;
    padding: 0;
    margin-bottom: 60px !important;
  }
  .sem-quote-bubble span {
    font-size: 16px;
    display: block;
    line-height: 1.4;
    padding: 34px 0;
    margin: 0;
  }
  .sem-testimonials h3.h1 {
    font-size: 30px;
  }
}
@media (max-width: 1030px) {
  .sem-testimonials {
    background-position: center;
    width: auto;
  }
}
@media (max-width: 1500px) {
  .sem-testimonials {
    min-height: 875px;
  }
}
@media (max-width: 500px) {
  .sem-testimonials {
    min-height: 1027px;
    background-size: auto 81%;
    background-position: bottom;
  }
}
@media (max-width: 767px) {
  .sem-testimonials {
    background-size: auto 81%;
    background-position: bottom;
  }
}
