::-moz-selection {
  background: #b3d4fc;
  text-shadow: none;
}
::selection {
  background: #b3d4fc;
  text-shadow: none;
}
a,
abbr,
acronym,
address,
applet,
article,
aside,
audio,
b,
big,
blockquote,
body,
canvas,
caption,
center,
cite,
code,
dd,
del,
details,
dfn,
div,
dl,
dt,
em,
embed,
fieldset,
figcaption,
figure,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
hgroup,
html,
i,
iframe,
img,
ins,
kbd,
label,
legend,
li,
mark,
menu,
nav,
object,
ol,
output,
p,
pre,
q,
ruby,
s,
samp,
section,
small,
span,
strike,
strong,
sub,
summary,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
time,
tr,
tt,
u,
ul,
var,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
blockquote,
q {
  quotes: none;
}
blockquote:after,
blockquote:before,
q:after,
q:before {
  content: '';
  content: none;
}
html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block;
}
audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}
progress {
  display: inline-block;
  vertical-align: baseline;
}
audio:not([controls]) {
  display: none;
  height: 0;
}
[hidden],
template {
  display: none;
}
a {
  background-color: transparent;
}
a:active,
a:hover {
  outline: 0;
}
dfn {
  font-style: italic;
}
mark {
  background: #ff0;
  color: #121c4e;
}
small {
  font-size: 87.5%;
}
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
sup {
  top: -0.5em;
}
sub {
  bottom: -0.25em;
}
img {
  border: 0;
  vertical-align: middle;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
svg:not(:root) {
  overflow: hidden;
}
hr {
  box-sizing: content-box;
}
pre {
  overflow: auto;
}
code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}
button,
input,
optgroup,
select,
textarea {
  color: inherit;
  font: inherit;
  margin: 0;
}
button {
  overflow: visible;
}
button,
select {
  text-transform: none;
}
button,
html input[type='button'],
input[type='reset'],
input[type='submit'] {
  -webkit-appearance: button;
  cursor: pointer;
}
button[disabled],
html input[disabled] {
  cursor: default;
}
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}
input {
  line-height: normal;
}
input[type='checkbox'],
input[type='radio'] {
  box-sizing: border-box;
  padding: 0;
}
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  height: auto;
}
input[type='search'] {
  -webkit-appearance: textfield;
  box-sizing: content-box;
}
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}
fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}
legend {
  border: 0;
  padding: 0;
}
textarea {
  overflow: auto;
  resize: vertical;
}
optgroup {
  font-weight: 700;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
td,
th {
  padding: 0;
}
.browserupgrade {
  margin: 0.2em 0;
  background: #ccc;
  color: #121c4e;
  padding: 0.2em 0;
}
*,
:after,
:before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
#ph-body,
body {
  overflow-x: hidden;
  font-family: Specter_regular, Arial, sans-serif;
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: 0.2px;
  font-weight: 400;
  color: #121c4e;
  background-color: #f7f6f4;
  width: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
button,
input,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
}
a:focus,
a:hover {
  text-decoration: underline;
}

.cta__sub-text a {
  font-weight: bold;
  color: #cf095e;
}

.app-links a,
footer a,
form a,
header a {
  color: #121c4e;
  font-weight: 300;
}
figure {
  margin: 0;
}
img.lazy[data-lazy] {
  opacity: 0;
}
.img-responsive {
  display: inline-block;
  max-width: 100%;
  height: auto;
}
.img-rounded {
  border-radius: 0;
}
.img-thumbnail {
  padding: 4px;
  line-height: 1.5;
  background-color: #f7f6f4;
  border: 1px solid #ddd;
  border-radius: 0;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  display: inline-block;
  max-width: 100%;
  height: auto;
}
.img-circle {
  border-radius: 50%;
}
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #c2c2c2;
  margin: 1em 0;
  padding: 0;
  width: 100%;
}
hr.blue {
  display: block;
  height: 3px;
  border: 0;
  border-top: 3px solid #121c4e;
  margin: 30px 0;
  padding: 0;
  width: 100%;
}
@media (min-width: 768px) {
  .vr {
    border-right: 1px solid #c2c2c2;
  }
}

@media (max-width: 767px) {
  .vr {
    border-bottom: 1px solid #c2c2c2;
  }
}
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
.sr-only-focusable:active,
.sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  clip: auto;
}
[role='button'] {
  cursor: pointer;
}
.clearfix::after,
.clearfix::before,
.container-fluid::after,
.container-fluid::before,
.container::after,
.container::before,
.row::after,
.row::before {
  content: ' ';
  display: table;
}
.clearfix::after,
.container-fluid::after,
.container::after,
.row::after {
  clear: both;
}
.specter-regular {
  font-family: Specter_regular, Arial, sans-serif !important;
  font-weight: 400;
}
.specter-italic,
em {
  font-family: Specter_italic, Arial, sans-serif !important;
  font-style: italic;
}
.specter-bold,
.strong,
strong {
  font-family: Specter_bold, Arial, sans-serif !important;
}
.specter-ultra {
  font-family: Specter_ultra, Arial, sans-serif !important;
}
.specter-bold em,
.specter-bolditalic,
.specter-italic strong,
em .specter-bold,
em strong,
strong em {
  font-family: Specter_bolditalic, Arial, sans-serif !important;
  font-style: italic;
}
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600;
  line-height: 1.2;
  color: inherit;
}
.h1.hr,
.h2.hr,
.h3.hr,
.h4.hr,
.h5.hr,
.h6.hr,
h1.hr,
h2.hr,
h3.hr,
h4.hr,
h5.hr,
h6.hr {
  border-bottom: 3px solid #121c4e;
  padding-bottom: 20px;
  width: 100% '';
}
.h1 .small,
.h1 small,
.h2 .small,
.h2 small,
.h3 .small,
.h3 small,
.h4 .small,
.h4 small,
.h5 .small,
.h5 small,
.h6 .small,
.h6 small,
h1 .small,
h1 small,
h2 .small,
h2 small,
h3 .small,
h3 small,
h4 .small,
h4 small,
h5 .small,
h5 small,
h6 .small,
h6 small {
  font-weight: 400;
  line-height: 1;
  color: #121c4e;
}
.h1,
.h2,
.h3,
h1,
h2,
h3 {
  font-family: Specter_ultra, Arial, sans-serif;
  margin-top: 24px;
  margin-bottom: 24px;
  letter-spacing: 0.68px;
}
.h1 .small,
.h1 small,
.h2 .small,
.h2 small,
.h3 .small,
.h3 small,
h1 .small,
h1 small,
h2 .small,
h2 small,
h3 .small,
h3 small {
  font-size: 65%;
}
.h4,
.h5,
.h6,
h4,
h5,
h6 {
  font-family: Specter_bold, Arial, sans-serif;
  margin-top: 24px;
  margin-bottom: 24px;
}
.h4 .small,
.h4 small,
.h5 .small,
.h5 small,
.h6 .small,
.h6 small,
h4 .small,
h4 small,
h5 .small,
h5 small,
h6 .small,
h6 small {
  font-size: 75%;
}
.h1,
h1 {
  font-size: 48px;
}
.h2,
h2 {
  font-size: 38px;
}
.h3,
h3 {
  font-size: 30px;
}
.h4,
h4 {
  font-size: 24px;
}
.h5,
h5 {
  font-size: 20px;
}
.h6,
h6 {
  font-size: 16px;
}
@media (max-width: 767px) {
  .h1,
  h1 {
    font-size: 37px;
  }
  .h2,
  h2 {
    font-size: 30px;
  }
  .h3,
  h3 {
    font-size: 24px;
  }
}
p {
  margin: 0 0 24px;
}

.p {
  margin: 0 0 24px;
}

.lead {
  margin-bottom: 24px;
  font-size: 20px;
  font-weight: 300;
  line-height: 1.4;
}
ol,
ul {
  margin-top: 0;
  margin-bottom: 24px;
}
ol ol,
ol ul,
ul ol,
ul ul {
  margin-bottom: 0;
}
.list-unstyled {
  padding-left: 0;
  list-style: none;
}
.list-inline {
  padding-left: 0;
  list-style: none;
  margin-left: -5px;
}
.list-inline > li {
  display: inline-block;
  padding-right: 5px;
  padding-left: 5px;
}
.list-check-inline li {
  padding: 0 40px;
  position: relative;
}
.list-check-inline li::before {
  content: '';
  width: 20px;
  height: 10px;
  border: 3px solid #4300d5;
  border-top: none;
  border-right: none;
  transform: rotate(-55deg);
  position: absolute;
  left: 8px;
  top: 6px;
}
@media (max-width: 767px) {
  .list-check-inline li {
    padding: 0 0 0 34px;
  }
  .list-check-inline li::before {
    left: 2px;
  }
}
.roll-list {
  list-style: none;
}
.roll-list li {
  padding: 10px 1px 10px 32px;
  border-bottom: 1px dashed #7b7c7e;
  position: relative;
}
.roll-list li::before {
  content: '';
  width: 16px;
  height: 8px;
  border: 3px solid #4300d5;
  border-top: none;
  border-right: none;
  transform: rotate(-55deg);
  position: absolute;
  left: 0;
  top: 16px;
}
abbr[data-original-title],
abbr[title] {
  cursor: help;
  border-bottom: 1px dotted #121c4e;
}
.initialism {
  font-size: 90%;
  text-transform: uppercase;
}
figcaption {
  padding-top: 7px;
  font-size: 80%;
  line-height: 110%;
  color: #999;
}
blockquote {
  padding: 0;
  margin: 0;
  font-size: 20px;
  border-left: none;
}
blockquote footer {
  font-family: Specter_bold, Arial, sans-serif;
  font-size: 16px;
  padding: 24px 0 0;
}
blockquote footer p {
  margin-bottom: 8px;
}
blockquote footer cite {
  font-family: Specter_regular, Arial, sans-serif;
}
blockquote ol:last-child,
blockquote p:last-child,
blockquote ul:last-child {
  margin-bottom: 0;
}
blockquote .small,
blockquote small {
  display: block;
  font-size: 80%;
  line-height: 1.5;
  color: #121c4e;
}
blockquote .small:before,
blockquote small:before {
  content: '\2014 \00A0';
}
.blockquote-reverse,
blockquote.float-right {
  padding-right: 15px;
  padding-left: 0;
  border-right: 5px solid #c2c2c2;
  border-left: 0;
  text-align: right;
}
.blockquote-reverse .small::before,
.blockquote-reverse small::before,
blockquote.float-right .small::before,
blockquote.float-right small::before {
  content: '';
}
.blockquote-reverse .small::after,
.blockquote-reverse small::after,
blockquote.float-right .small::after,
blockquote.float-right small::after {
  content: '\00A0 \2014';
}
address {
  margin-bottom: 24px;
  font-style: normal;
  line-height: 1.5;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.text-center {
  text-align: center;
}
.text-justify {
  text-align: justify;
}
.text-nowrap {
  white-space: nowrap;
}
.text-lowercase {
  text-transform: lowercase;
}
.text-uppercase {
  text-transform: uppercase;
}
.text-capitalize {
  text-transform: capitalize;
}
.text-underline {
  text-decoration: underline;
}
.text-muted {
  color: #c2c2c2;
}
.aria-accordion__panel[aria-hidden='true'] {
  display: none;
}
.js-accordion__panel {
  padding: 40px 0;
}
.js-accordion__panel[role='tabpanel'] .js-accordion__header {
  display: none;
}
button.js-accordion__header {
  display: block !important;
  height: 50px;
  width: 100%;
  background-color: #121c4e;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  color: #fff;
  font-size: 20px;
  font-family: Specter_bold, Arial, sans-serif;
  line-height: 48px;
  text-align: left;
  padding: 0 0 0 15px;
  margin: 0 0 4px;
}
button.js-accordion__header:focus {
  box-shadow: none;
}
button.js-accordion__header::after {
  content: '';
  display: block;
  float: right;
  margin: 16px 16px 0 0;
  height: 16px;
  width: 16px;
  /* background: url(../images/ui/icn-plus-white.svg) center center/contain no-repeat; */
}
button.js-accordion__header[aria-expanded='true'] {
  background-color: #41d4ff;
}
button.js-accordion__header[aria-expanded='true']::after {
  /* background-image: url(../images/ui/icn-minus-white.svg); */
}
div.banner-cta section[class*='bg-blue'] h2,
div.banner-cta section[class*='bg-blue'] label,
div.banner-cta section[class*='bg-blue'] p,
div.banner-cta[class*='bg-blue'] h2,
div.banner-cta[class*='bg-blue'] label,
div.banner-cta[class*='bg-blue'] p {
  color: #fff;
}
div.banner-cta section[class*='bg-blue'] a.btn:not([class*='btn-outline']):not(.btn-red):hover,
div.banner-cta section[class*='bg-blue'] input.btn:hover,
div.banner-cta[class*='bg-blue'] a.btn:not([class*='btn-outline']):not(.btn-red):hover,
div.banner-cta[class*='bg-blue'] input.btn:hover {
  background-color: inherit;
}
div.banner-cta.mb {
  margin-bottom: 100px;
}
div.banner-cta h2,
div.banner-cta p {
  margin: 0 0 12px;
}
div.banner-cta h2 {
  color: #121c4e;
}
div.banner-cta .img-main {
  padding: 0;
}
div.banner-cta .text-main {
  padding: 36px;
}
div.banner-cta .text-main .copy {
  float: left;
  width: 100%;
  padding-right: 36px;
}
div.banner-cta .text-main .btn-block {
  padding: 0;
}
div.banner-cta .text-main .btn-block .btn {
  width: 100%;
}
div.banner-cta .text-main .btn-block .btn + .btn {
  margin-top: 12px;
}
div.banner-cta .text-main .btn-row {
  padding: 0;
  text-align: center;
}
div.banner-cta .text-main .btn-row .btn {
  padding: 11px 15px;
}
div.banner-cta .text-main .btn-row .btn.btn-outline-rev {
  padding: 9px 14px;
}
div.banner-cta .form-group {
  margin-bottom: 0;
}
div.banner-cta .form-group label {
  display: block;
}
div.banner-cta .form-group input {
  height: 42px;
  padding: 10px 12px;
}
div.banner-cta .form-group .btn-sm {
  height: 42px;
}
.bg-blue-horizontal-gradient {
  background-color: #121c4e;
}
.bio-listing,
.content-listing,
.resource-listing {
  padding-left: 0;
  padding-right: 0;
}
.content-listing .img-align .img-main {
  height: 170px;
  line-height: 170px;
  margin-bottom: 30px;
}
.content-listing .img-align .img-main .card-img {
  max-height: 170px;
  display: inline-block;
  vertical-align: middle;
  margin-bottom: 0;
}
.resource-listing .card {
  cursor: pointer;
}
.resource-listing .card .abstract {
  max-height: 76px;
  overflow: hidden;
}
.resource-listing .card:hover .link-arrow {
  color: inherit;
}
.resource-listing .card:hover .link-arrow::after {
  right: -33px;
  opacity: 1;
}
.resource-listing .CoveoResultList .card {
  margin-bottom: 38px;
}
.resource-listing .slick-slide .col-xs-12 {
  margin-bottom: 0 !important;
}
.resource-listing.hide-header .card {
  border-bottom: none !important;
  padding-bottom: 0 !important;
}
.resource-listing.hide-header .card .card-header {
  display: none;
}
.bio-listing div.row:not(.slick-slider):last-child {
  padding-bottom: 48px;
}
.bio-listing .card {
  cursor: pointer;
  text-align: center;
  color: #cd0058;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.bio-listing .card:hover {
  color: inherit;
}
.bio-listing .card-img {
  width: 180px;
}
.bio-listing .slick-slide .col-xs-12 {
  margin-bottom: 0 !important;
}
.bio-listing .slick-arrow {
  top: 20% !important;
}
.card .date {
  display: block;
  float: right;
  font-family: Specter_regular, Arial, sans-serif;
  text-transform: capitalize;
  padding-left: 15px;
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group:first-child .list-group-item:first-child {
  border-top-radius: 0;
}
.card > .list-group:last-child .list-group-item:last-child {
  border-bottom-radius: 0;
}
.card-header {
  display: block;
  text-align: center;
  margin: 0;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 3px;
  font-size: 12px;
}
.card-type {
  font-family: Specter_bold, Arial, sans-serif;
  font-size: 16px;
  line-height: 20px;
  text-transform: uppercase;
  margin: 0 0 12px;
  color: #767676;
}
.card-body .title {
  font-size: 20px;
  line-height: 24px;
  margin: 0 0 12px;
  display: block;
}
.card-body p {
  margin: 0;
}
.card-img {
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: block;
  height: auto;
}
.video-block .card-img {
  max-width: none;
  width: 100%;
}
.card-demo {
  border-bottom: 4px solid #cd0058;
}
.card-demo .card-header {
  background-color: #cd0058;
}
.card-testimonial {
  border-bottom: 4px solid red;
}
.card-testimonial .card-header {
  background-color: red;
}
.card-insight {
  border-bottom: 4px solid #121c4e;
}
.card-insight .card-header {
  background-color: #121c4e;
}
.card-tool {
  border-bottom: 4px solid #41d4ff;
}
.card-tool .card-header {
  background-color: #41d4ff;
}
.card-product {
  border-bottom: 4px solid #4300d5;
}
.card-product .card-header {
  background-color: #4300d5;
}
.card-event {
  border-bottom: 4px solid #fff1f1;
}
.card-event .card-header {
  background-color: #fff1f1;
}
.card-box {
  background: #fff;
  border-radius: 30px;
  padding: 46px;
  height: 100%;
}

.bg-transparent {
  background: none;
}

@media (max-width: 767px) {
  div.banner-cta.mb {
    margin-bottom: 60px;
  }
  div.banner-cta .text-main {
    padding: 24px;
  }
  div.banner-cta .text-main .copy {
    padding-right: 0;
  }
  div.banner-cta .btn {
    width: 100%;
  }
  div.banner-cta .form-group .btn {
    margin-top: 12px;
  }
  .card-box {
    padding: 42px;
  }
  .card-img,
  .content-listing .img-align .img-main {
    margin-bottom: 20px;
  }
  .card-body .title {
    font-size: 18px;
    line-height: 22px;
  }
  .bio-listing .col-xs-12,
  .content-listing .col-xs-12,
  .resource-listing .col-xs-12 {
    margin-bottom: 38px;
  }
  .bio-listing .card .btn,
  .bio-listing .card .link-arrow,
  .content-listing .card .btn,
  .content-listing .card .link-arrow,
  .resource-listing .card .btn,
  .resource-listing .card .link-arrow {
    margin-top: 20px;
  }
  .bio-listing .slick-slide .col-xs-12,
  .content-listing .slick-slide .col-xs-12,
  .resource-listing .slick-slide .col-xs-12 {
    padding-left: 30px;
    padding-right: 30px;
  }
  .bio-listing .slick-arrow,
  .content-listing .slick-arrow,
  .resource-listing .slick-arrow {
    top: 53%;
  }
  .bio-listing .slick-prev,
  .content-listing .slick-prev,
  .resource-listing .slick-prev {
    left: 3px;
  }
  .bio-listing .slick-next,
  .content-listing .slick-next,
  .resource-listing .slick-next {
    right: 3px;
  }
  .content-listing .col-xs-12:last-of-type {
    margin-bottom: 0;
  }
  .content-listing:not(.video-block) .card-body .title {
    font-size: 20px;
    line-height: 24px;
  }
  .resource-listing .card {
    padding-bottom: 20px;
  }
  .bio-listing div.row:not(.slick-slider):last-child {
    padding-bottom: 24px;
  }
  .bio-listing .link-wrap {
    margin-top: 38px;
  }
  .cxl-table-section .compare-pkgs .comparison-chart {
    display: none;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  div.banner-cta.mb {
    margin-bottom: 80px;
  }
  div.banner-cta .text-main {
    padding: 30px;
  }
  div.banner-cta .text-main .copy {
    padding-right: 30px;
  }
  div.banner-cta .btn-row.min-width485 input {
    display: block;
    width: 100%;
    max-width: 345px;
    margin: 0 auto 12px;
  }
  .card-img,
  .content-listing .img-align .img-main {
    margin-bottom: 24px;
  }
  .bio-listing .slick-slider,
  .content-listing .slick-slider,
  .resource-listing .slick-slider {
    padding-left: 20px;
    padding-right: 20px;
  }
  .bio-listing .slick-arrow,
  .content-listing .slick-arrow,
  .resource-listing .slick-arrow {
    top: 34%;
  }
  .bio-listing .slick-prev,
  .content-listing .slick-prev,
  .resource-listing .slick-prev {
    left: 3px;
  }
  .bio-listing .slick-next,
  .content-listing .slick-next,
  .resource-listing .slick-next {
    right: 3px;
  }
  .content-listing .col-xs-12:not(.col-sm-4) .video-thumb::before {
    top: calc(50% - 40px);
    left: calc(50% - 40px);
    height: 80px;
    width: 80px;
  }
  .content-listing .card .btn,
  .content-listing .card .link-arrow {
    margin-top: 24px;
  }
  .content-listing .js-carousel-2-col-content .slick-arrow {
    top: 44%;
  }
  .resource-listing .CoveoResultList .card,
  .resource-listing .col-xs-12 {
    margin-bottom: 48px;
  }
  .resource-listing .card {
    padding-bottom: 24px;
  }
  .resource-listing .card .link-arrow {
    bottom: 24px;
  }
  .bio-listing div.row:not(.slick-slider):last-child {
    padding-bottom: 36px;
  }
  .bio-listing .col-xs-12 {
    margin-bottom: 48px;
  }
  .bio-listing .card .btn,
  .bio-listing .card .link-arrow {
    margin-top: 24px;
  }
  .bio-listing .link-wrap {
    margin-top: 48px;
  }
  .bio-listing .slick-slider {
    max-width: 580px;
  }
}
@media (min-width: 768px) {
  div.banner-cta .text-main {
    display: table;
  }
  div.banner-cta .text-main .copy p:last-child {
    margin: 0;
  }
  div.banner-cta .text-main .btn-block,
  div.banner-cta .text-main .btn-row {
    display: table-cell;
    vertical-align: middle;
    float: none;
  }
  div.banner-cta .form-group input {
    width: 100%;
    max-width: 250px;
    float: left;
    margin-right: 12px;
  }
  .content-listing:not(.video-block) .card-body .title {
    font-size: 24px;
    line-height: 28px;
  }
  .resource-listing .card {
    position: relative;
    height: 437px;
  }
  .resource-listing .card .link-arrow {
    position: absolute;
  }
  .resource-listing.hide-header .card {
    height: 389px;
  }
  .resource-listing.hide-header .card .link-arrow {
    bottom: 0;
  }
  .resource-listing .card-body {
    min-height: 132px;
  }
  .cxl-table-section .compare-pkgs .workers-comp.td-rb td {
    border-right: 1px solid #c9c9c9;
  }
}
@media (min-width: 992px) {
  div.banner-cta .btn-row.min-width485 {
    min-width: 485px;
  }
  div.banner-cta .btn-row input {
    width: 25% !important;
    display: inline-block;
    margin-right: 15px;
    margin-bottom: 0;
  }
  .card-img {
    margin-bottom: 30px;
  }
  .content-listing .slick-arrow,
  .resource-listing .slick-arrow {
    top: 37%;
  }
  .content-listing div.col-xs-12:not(.col-sm-4) .video-thumb::before {
    top: calc(50% - 48px);
    left: calc(50% - 48px);
    height: 96px;
    width: 96px;
  }
  .content-listing div.col-xs-12:not(.col-sm-4).col-sm-6 .video-thumb::before {
    top: calc(50% - 40px);
    left: calc(50% - 40px);
    height: 80px;
    width: 80px;
  }
  .content-listing .card .btn,
  .content-listing .card .link-arrow {
    margin-top: 30px;
  }
  .content-listing .js-carousel-2-col-content .slick-arrow {
    top: 46%;
  }
  .resource-listing .CoveoResultList .card,
  .resource-listing .col-xs-12 {
    margin-bottom: 58px;
  }
  .resource-listing .card {
    padding-bottom: 30px;
  }
  .resource-listing .card .link-arrow {
    bottom: 30px;
  }
  .bio-listing .col-xs-12 {
    margin-bottom: 58px;
  }
  .bio-listing .card .btn,
  .bio-listing .card .link-arrow {
    margin-top: 30px;
  }
  .bio-listing .link-wrap {
    margin-top: 58px;
  }
  .bio-listing .slick-slider {
    max-width: 810px;
  }
}
.height-card-img-20 img.card-img {
  height: 20px;
}
.height-card-img-50 img.card-img {
  height: 50px;
}
.height-card-img-60 img.card-img {
  height: 60px;
}
.height-card-img-70 img.card-img {
  height: 70px;
}
.height-card-img-80 img.card-img {
  height: 80px;
}
.height-card-img-90 img.card-img {
  height: 90px;
}
.height-card-img-100 img.card-img {
  height: 100px;
}
.height-card-img-110 img.card-img {
  height: 110px;
}
.height-card-img-120 img.card-img {
  height: 120px;
}
.height-card-img-130 img.card-img {
  height: 130px;
}
.height-card-img-140 img.card-img {
  height: 140px;
}
.height-card-img-150 img.card-img {
  height: 150px;
}
.height-card-img-160 img.card-img {
  height: 160px;
}
.height-card-img-170 img.card-img {
  height: 170px;
}
.height-card-img-180 img.card-img {
  height: 180px;
}
.height-card-img-190 img.card-img {
  height: 190px;
}
.height-card-img-200 img.card-img {
  height: 200px;
}
.square-card-img-20 img.card-img {
  height: 20px;
  max-width: 20px;
}
.square-card-img-50 img.card-img {
  height: 50px;
  max-width: 50px;
}
.square-card-img-60 img.card-img {
  height: 60px;
  max-width: 60px;
}
.square-card-img-70 img.card-img {
  height: 70px;
  max-width: 70px;
}
.square-card-img-80 img.card-img {
  height: 80px;
  max-width: 80px;
}
.square-card-img-90 img.card-img {
  height: 90px;
  max-width: 90px;
}
.square-card-img-100 img.card-img {
  height: 100px;
  max-width: 100px;
}
.square-card-img-110 img.card-img {
  height: 110px;
  max-width: 110px;
}
.square-card-img-120 img.card-img {
  height: 120px;
  max-width: 120px;
}
.square-card-img-130 img.card-img {
  height: 130px;
  max-width: 130px;
}
.square-card-img-140 img.card-img {
  height: 140px;
  max-width: 140px;
}
.square-card-img-150 img.card-img {
  height: 150px;
  max-width: 150px;
}
.square-card-img-160 img.card-img {
  height: 160px;
  max-width: 160px;
}
.square-card-img-170 img.card-img {
  height: 170px;
  max-width: 170px;
}
.square-card-img-180 img.card-img {
  height: 180px;
  max-width: 180px;
}
.square-card-img-190 img.card-img {
  height: 190px;
  max-width: 190px;
}
.square-card-img-200 img.card-img {
  height: 200px;
  max-width: 200px;
}
.js-card-clickable .card {
  cursor: pointer;
}
.js-card-clickable .card:hover a:not(.link-arrow):not(.link) {
  color: inherit;
  text-decoration: underline;
}
#secondary-chat-btn,
#start-support-chat {
  background: 0 0;
  padding: 0;
  width: auto;
  position: fixed;
  bottom: 15px;
  right: 15px;
  z-index: 550;
}
#secondary-chat-btn,
.grecaptcha-badge {
  display: none;
}
.cxl-table-section .compare-pkgs .header {
  background-repeat: repeat-x;
  background-position: top;
}
.cxl-table-section .compare-pkgs .header.red {
  /* background-image: url(../assets/ui/chart-border-red.png); */
  background-color: rgba(208, 39, 29, 0.1);
}
.cxl-table-section .compare-pkgs .header.purple {
  /* background-image: url(../assets/ui/chart-border-purple.png); */
  background-color: rgba(121, 103, 174, 0.1);
}
.cxl-table-section .compare-pkgs .header.blue {
  /* background-image: url(../assets/ui/chart-border-blue.png); */
  background-color: rgba(38, 50, 140, 0.1);
}
.cxl-table-section .compare-pkgs .header.pink {
  /* background-image: url(../assets/ui/chart-border-pink.png); */
  background-color: rgba(242, 99, 93, 0.1);
}
.cxl-table-section .compare-pkgs .header.brown {
  /* background-image: url(../assets/ui/chart-border-brown.png); */
  background-color: rgba(125, 53, 32, 0.1);
}
.cxl-table-section .compare-pkgs .header.tan {
  /* background-image: url(../assets/ui/chart-border-tan.png); */
  background-color: rgba(249, 172, 132, 0.1);
}
.cxl-table-section .compare-pkgs .comparison-chart {
  text-align: left;
}
.cxl-table-section .compare-pkgs .comparison-chart .logo {
  width: 31%;
  padding: 30px 40px 20px;
}
.cxl-table-section .compare-pkgs .comparison-chart .header {
  width: 23%;
  padding: 25px;
  position: relative;
  border-right: 1px solid #fff;
}
.cxl-table-section .compare-pkgs .comparison-chart .header:last-of-type {
  border-right: none;
}
.cxl-table-section .compare-pkgs .comparison-chart .header .btn-red,
.cxl-table-section .compare-pkgs .comparison-chart .header .link-arrow {
  position: absolute;
  bottom: 20px;
}
.cxl-table-section .compare-pkgs .comparison-chart .heading,
.cxl-table-section .compare-pkgs .comparison-chart h2,
.cxl-table-section .compare-pkgs .comparison-chart h3 {
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 18px;
  font-weight: 700;
}
.cxl-table-section .compare-pkgs .comparison-chart .header strong {
  font-family: OpenSans_medium, Arial, sans-serif !important;
  font-size: 26px;
}
.cxl-table-section .compare-pkgs .comparison-chart .header.red .heading {
  color: #cf2925;
}
.cxl-table-section .compare-pkgs .comparison-chart .header.purple .heading {
  color: #7a6bab;
}
.cxl-table-section .compare-pkgs .comparison-chart .header.pink .heading {
  color: #ef6561;
}
.cxl-table-section .compare-pkgs .comparison-chart .header.brown .heading {
  color: #7b3523;
}
.cxl-table-section .compare-pkgs .comparison-chart .header .badge {
  color: #fff;
  background: #5378d6;
  border-radius: 4px;
  font-size: 12px;
  padding: 0 8px;
  position: absolute;
  right: 15px;
  top: 20px;
}
.cxl-table-section .compare-pkgs .comparison-chart h3 {
  margin-bottom: 10px;
}
.cxl-table-section .compare-pkgs .comparison-chart p {
  font-size: 14px;
  line-height: 20px;
}
.cxl-table-section .compare-pkgs .comparison-chart tr {
  background-color: #f9f9f9;
  border-bottom: 1px solid #c9c9c9;
}
.cxl-table-section .compare-pkgs .comparison-chart tr.top,
.cxl-table-section .compare-pkgs .comparison-chart tr:nth-child(even) {
  background-color: #fff;
}
.cxl-table-section .compare-pkgs .comparison-chart tr.affix {
  position: fixed;
  top: 0;
  right: 0;
}
.cxl-table-section .compare-pkgs .comparison-chart tr.grey {
  background-color: #dbdbdb;
  color: #222;
  font-family: OpenSans_medium, Arial, sans-serif;
}
.cxl-table-section .compare-pkgs .comparison-chart tr.grey.last {
  height: 6px;
  padding: 0;
}
.cxl-table-section .compare-pkgs .comparison-chart tr.grey td {
  padding: 5px 35px;
}
.cxl-table-section .compare-pkgs .comparison-chart td {
  position: relative;
  border-left: 1px solid #c9c9c9;
  text-align: left;
  padding: 35px 20px;
  background: #f9f9f9;
}
.cxl-table-section .compare-pkgs .comparison-chart td:first-child {
  border-left: none;
  padding: 10px 15px 0 35px;
  text-align: left;
}
.cxl-table-section .compare-pkgs .comparison-chart td.dropdown {
  cursor: pointer;
  position: relative;
}
.cxl-table-section .compare-pkgs .comparison-chart td.dropdown::before {
  display: block;
  content: '';
  position: absolute;
  top: 13px;
  left: 10px;
  width: 15px;
  height: 15px;
}
.cxl-table-section .compare-pkgs .comparison-chart td.dropdown.open::before {
  /* background-image: url(../assets/ui/icn-contract-black.svg); */
}
.cxl-table-section .compare-pkgs .comparison-chart td img {
  width: 20px;
}
.cxl-table-section .compare-pkgs .comparison-chart td p {
  margin-bottom: 10px;
}
.cxl-table-section .compare-pkgs .comparison-chart td[class^='check'] {
  position: relative;
}
.cxl-table-section .compare-pkgs .comparison-chart td[class^='check']::before {
  position: absolute;
  content: '';
  top: 13px;
  left: calc(41%);
  display: block;
  width: 23px;
  height: 17px;
  background: center center/contain no-repeat;
}
.cxl-table-section .compare-pkgs .comparison-chart td.check-red::before {
  /* background-image: url(../assets/ui/icn-check-thick-red.svg); */
}
.cxl-table-section .compare-pkgs .comparison-chart td.check-purple::before {
  /* background-image: url(../assets/ui/icn-check-thick-purple.svg); */
}
.cxl-table-section .compare-pkgs .comparison-chart td.check-pink::before {
  /* background-image: url(../assets/ui/icn-check-thick-pink.svg); */
}
.cxl-table-section .compare-pkgs .comparison-chart td.check-brown::before {
  /* background-image: url(../assets/ui/icn-check-thick-brown.svg); */
}
.cxl-table-section .compare-pkgs .comparison-chart td.check-tan::before {
  /* background-image: url(../assets/ui/icn-check-thick-tan.svg); */
}
.cxl-table-section .compare-pkgs .comparison-list {
  display: none;
}
.cxl-table-section .compare-pkgs .comparison-list .header {
  background-repeat: repeat-x;
  padding: 20px 18px 16px;
  margin-top: 40px;
}
.cxl-table-section .compare-pkgs .comparison-list .header:first-child {
  margin-top: 0;
}
.cxl-table-section .compare-pkgs .comparison-list .header h3 {
  font-size: 18px;
  margin-bottom: 6px;
}
.cxl-table-section .compare-pkgs .comparison-list .header p {
  font-size: 14px;
  margin-bottom: 15px;
}
.cxl-table-section .compare-pkgs .comparison-list .dropdown-mobile {
  position: relative;
  background-color: #26328c;
  cursor: pointer;
}
.cxl-table-section .compare-pkgs .comparison-list .dropdown-mobile::after {
  content: '';
  display: block;
  position: absolute;
  top: 13px;
  right: 13px;
  height: 14px;
  width: 14px;
  /* background: url(../assets/ui/icn-plus-white.svg) center center/contain no-repeat; */
}
.cxl-table-section .compare-pkgs .comparison-list .dropdown-mobile.open {
  background-color: #121c4e;
}
.cxl-table-section .compare-pkgs .comparison-list .dropdown-mobile.open::after {
  /* background-image: url(../assets/ui/icn-minus-white.svg); */
}
.cxl-table-section .compare-pkgs .comparison-list .dropdown-mobile h4 {
  margin-bottom: 0;
  font-size: 17px;
  color: #fff;
  position: relative;
  padding: 8px 11px;
}
.cxl-table-section .compare-pkgs .comparison-list .reveal {
  background-color: #fff;
  font-size: 14px;
  line-height: 22px;
  cursor: initial;
  font-weight: 600;
}
.cxl-table-section .compare-pkgs .comparison-list .reveal .dropdown {
  cursor: pointer;
  position: relative;
}
.cxl-table-section .compare-pkgs .comparison-list .reveal .dropdown::before {
  display: block;
  content: '';
  position: absolute;
  top: 2px;
  left: 0;
  width: 17px;
  height: 17px;
  /* background: url(../assets/ui/icn-list-open.svg) center center/contain no-repeat; */
}
.cxl-table-section .compare-pkgs .comparison-list .reveal .dropdown.open::before {
  /* background-image: url(../assets/ui/icn-list-close-hover.svg); */
}
.cxl-table-section .compare-pkgs .comparison-list .reveal ul {
  padding-top: 7px;
  display: block;
  list-style-type: none;
  margin-bottom: 15px;
}
.cxl-table-section .compare-pkgs .comparison-list .reveal ul li {
  padding: 4px 11px 4px 18px;
  background-color: #fff;
}
.cxl-table-section .compare-pkgs .comparison-list .reveal ul li:nth-child(even) {
  background-color: rgba(243, 243, 243, 0.5);
}
.cxl-table-section .compare-pkgs .comparison-list .reveal .btn {
  margin: 0 auto;
  display: block;
  width: 200px;
  padding: 12px;
}
.cxl-table-section .compare-pkgs .comparison-list .reveal.active {
  display: block !important;
}
.cxl-table-section .compare-pkgs .disclaimer {
  padding-top: 40px;
}
.cxl-table-section .compare-pkgs sup {
  top: 0;
  font-size: 120%;
}
.cxl-table-section .compare-pkgs .run .comparison-chart .logo {
  width: 24%;
  padding: 30px 15px 15px;
}
.cxl-table-section .compare-pkgs .run .comparison-chart .logo .btn {
  padding: 12px 22px;
}
.cxl-table-section .compare-pkgs .run .comparison-chart .header {
  width: 19%;
  padding: 20px 18px 65px;
  vertical-align: top;
}
.cxl-table-section .compare-pkgs .run .comparison-chart .dropdown > p {
  font-size: 15px;
  line-height: 23px;
  font-family: OpenSans_medium, Arial, sans-serif;
}
.cxl-table-section .compare-pkgs .run .comparison-chart .tableFloatingHeaderOriginal.sticky-thead {
  border-top: 5px solid #fff;
  border-bottom: 1px solid #c9c9c9;
  margin-top: -1px !important;
}
.cxl-table-section
  .compare-pkgs
  .run
  .comparison-chart
  .tableFloatingHeaderOriginal.sticky-thead
  .logo
  .heading {
  display: none;
}
.cxl-table-section
  .compare-pkgs
  .run
  .comparison-chart
  .tableFloatingHeaderOriginal.sticky-thead
  .logo {
  padding: 25px 15px 20px;
}
.cxl-table-section
  .compare-pkgs
  .run
  .comparison-chart
  .tableFloatingHeaderOriginal.sticky-thead
  .logo
  .btn {
  padding: 10px 20px;
}
.cxl-table-section
  .compare-pkgs
  .run
  .comparison-chart
  .tableFloatingHeaderOriginal.sticky-thead
  .logo
  p {
  margin: 0 0 15px;
}
.cxl-table-section
  .compare-pkgs
  .run
  .comparison-chart
  .tableFloatingHeaderOriginal.sticky-thead
  .logo
  p
  img {
  height: 45px;
}
.cxl-table-section
  .compare-pkgs
  .run
  .comparison-chart
  .tableFloatingHeaderOriginal.sticky-thead
  .header
  .heading {
  position: relative;
}
.cxl-table-section
  .compare-pkgs
  .run
  .comparison-chart
  .tableFloatingHeaderOriginal.sticky-thead
  .header
  .heading:hover {
  cursor: pointer;
}
.cxl-table-section
  .compare-pkgs
  .run
  .comparison-chart
  .tableFloatingHeaderOriginal.sticky-thead
  .header
  .heading::after {
  content: '';
  width: 18px;
  height: 18px;
  /* background: url(../assets/ui/icn-info.svg) center no-repeat; */
  display: inline-block;
  margin: 0 0 -2px 7px;
}
.cxl-table-section
  .compare-pkgs
  .run
  .comparison-chart
  .tableFloatingHeaderOriginal.sticky-thead
  .header
  .pkg-info {
  position: absolute;
  top: 77px;
  left: 10px;
  right: 10px;
  padding: 10px;
  z-index: 200;
  background: #fff;
  border: 1px solid #c9c9c9;
  display: none;
  opacity: 0;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  pointer-events: none;
}
.cxl-table-section
  .compare-pkgs
  .run
  .comparison-chart
  .tableFloatingHeaderOriginal.sticky-thead
  .header
  .pkg-info::before {
  position: absolute;
  top: -8px;
  left: 10px;
  content: '';
  /* background-image: url(../assets/ui/crt-pkg-info.png); */
  width: 28px;
  height: 8px;
  z-index: 300;
  overflow: hidden;
}
.cxl-table-section
  .compare-pkgs
  .run
  .comparison-chart
  .tableFloatingHeaderOriginal.sticky-thead
  .header
  .pkg-info
  p {
  margin: 0;
}
.cxl-table-section
  .compare-pkgs
  .run
  .comparison-chart
  .tableFloatingHeaderOriginal.sticky-thead
  .header
  .pkg-info.active {
  display: block;
  opacity: 1;
}
.cxl-table-section
  .compare-pkgs
  .run
  .comparison-chart
  .tableFloatingHeaderOriginal.sticky-thead
  .header.pink
  .pkg-info {
  top: 101px;
}
.cxl-table-section .compare-pkgs .run .comparison-chart .tableFloatingHeader th {
  border: 0 !important;
}
.cxl-table-section .compare-pkgs .workforcenow .comparison-chart tr {
  background-color: #fff;
}
.cxl-table-section .compare-pkgs .workforcenow .comparison-chart tr.grey {
  background-color: #dbdbdb;
}
.cxl-table-section .compare-pkgs .workforcenow .comparison-chart tr.grey td {
  text-align: center;
  padding: 5px 15px;
}
.cxl-table-section .compare-pkgs .workforcenow .comparison-chart tr.grey.last {
  padding: 0;
}
.cxl-table-section .compare-pkgs .workforcenow .comparison-chart .header {
  width: 20%;
  text-align: center;
  padding: 30px 0 20px;
}
.cxl-table-section .compare-pkgs .workforcenow .comparison-chart .header .heading {
  font-size: 18px;
  font-family: OpenSans_medium, Arial, sans-serif;
  margin-bottom: 0;
}
.cxl-table-section .compare-pkgs .workforcenow .comparison-chart td {
  padding: 10px;
  text-align: left;
}
.cxl-table-section .compare-pkgs .workforcenow .comparison-chart td:first-child {
  padding: 10px;
}
.cxl-table-section .compare-pkgs .workforcenow .comparison-chart td .dropdown {
  padding-left: 24px;
  margin-bottom: 10px;
  cursor: pointer;
  position: relative;
}
.cxl-table-section .compare-pkgs .workforcenow .comparison-chart td .dropdown::before {
  display: block;
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 17px;
  height: 17px;
  /* background: url(../assets/ui/icn-list-open.svg) center center/contain no-repeat; */
}
.cxl-table-section .compare-pkgs .workforcenow .comparison-chart td .dropdown.open::before {
  /* background-image: url(../assets/ui/icn-list-close-hover.svg); */
}
.cxl-table-section .compare-pkgs .workforcenow .comparison-chart td .dropdown > p {
  margin-bottom: 5px;
}
.cxl-table-section .compare-pkgs .workforcenow .comparison-chart td .dropdown .reveal p {
  color: #898989;
  margin-bottom: 0;
}
.cxl-table-section .compare-pkgs .workforcenow .comparison-list .header {
  text-align: center;
  padding-bottom: 10px;
}
.cxl-table-section .compare-pkgs .workforcenow .comparison-list div.plus {
  /* background-image: url(../assets/ui/plus.png); */
  height: 29px;
  width: 28px;
  background-size: cover;
  position: absolute;
  left: 6px;
  top: 6px;
}
.cxl-table-section .compare-pkgs .workforcenow .comparison-list div.plus.minus {
  /* background-image: url(../assets/ui/minus.png) !important; */
}
.cxl-table-section .compare-pkgs .workforcenow .comparison-list .reveal {
  padding: 18px;
}
.cxl-table-section .compare-pkgs .workforcenow .comparison-list .reveal .dropdown {
  padding-left: 24px;
  margin-bottom: 6px;
  cursor: pointer;
}
.cxl-table-section .compare-pkgs .workforcenow .comparison-list .reveal .dropdown p {
  margin-bottom: 0;
}
.cxl-table-section .compare-pkgs .workforcenow .comparison-list .reveal .dropdown .drop-reveal p {
  color: #898989;
}
.cxl-table-section .compare-pkgs .globalview .comparison-chart tr {
  background-color: rgba(255, 255, 255, 0);
}
.cxl-table-section .compare-pkgs .globalview .comparison-chart tr:nth-child(even) {
  background-color: rgba(243, 243, 243, 0.5);
}
.cxl-table-section .compare-pkgs .globalview .comparison-chart tr th.header p {
  margin-bottom: 0;
}
.cxl-table-section .compare-pkgs .globalview .comparison-chart tr th.header p.heading {
  margin-bottom: 18px;
}
.cxl-table-section .compare-pkgs .globalview .comparison-chart tr td:first-child {
  padding: 10px 15px 9px 10px;
}
.cxl-table-section .compare-pkgs .globalview .comparison-chart tr td p {
  font-size: 15px;
  line-height: 23px;
  font-family: OpenSans_medium, Arial, sans-serif;
  margin: 0;
}
.cxl-table-section .compare-pkgs .globalview .comparison-list .header p {
  margin-bottom: 0;
}
.cxl-table-section .compare-pkgs .workers-comp .comparison-chart tr,
.cxl-table-section .compare-pkgs .workers-comp.comparison-chart tr {
  background-color: #fff;
}
.cxl-table-section .compare-pkgs .workers-comp .comparison-chart tr:nth-child(even),
.cxl-table-section .compare-pkgs .workers-comp.comparison-chart tr:nth-child(even) {
  background-color: #f9f9f9;
}
.cxl-table-section .compare-pkgs .workers-comp .comparison-chart td,
.cxl-table-section .compare-pkgs .workers-comp.comparison-chart td {
  padding: 8px 25px;
  display: block;
}
.cxl-table-section .compare-pkgs .workers-comp .comparison-chart p,
.cxl-table-section .compare-pkgs .workers-comp.comparison-chart p {
  margin-bottom: 0;
}
@media (max-width: 991px) {
  #start-support-chat {
    display: none;
  }
  .cxl-table-section .compare-pkgs .comparison-chart .logo {
    padding: 36px 18px;
    width: 30%;
  }
  .cxl-table-section .compare-pkgs .comparison-chart .logo p {
    margin-bottom: 13px;
  }
  .cxl-table-section .compare-pkgs .comparison-chart .logo .btn {
    width: 100%;
    padding: 12px;
  }
  .cxl-table-section .compare-pkgs .comparison-chart .header {
    padding: 25px 20px;
    vertical-align: top;
  }
  .cxl-table-section .compare-pkgs .comparison-chart .header .link-arrow {
    font-size: 14px;
    bottom: 18px;
  }
  .cxl-table-section .compare-pkgs .comparison-chart .heading,
  .cxl-table-section .compare-pkgs .comparison-chart h2,
  .cxl-table-section .compare-pkgs .comparison-chart h3 {
    font-size: 18px;
    line-height: 22px;
  }
  .cxl-table-section .compare-pkgs .comparison-chart h3 {
    margin-bottom: 9px;
  }
  .cxl-table-section .compare-pkgs .comparison-chart p {
    line-height: 18px;
  }
  .cxl-table-section .compare-pkgs .comparison-chart tr.grey {
    padding: 8px 37px;
  }
  .cxl-table-section .compare-pkgs .comparison-chart td:first-child {
    padding-left: 37px;
  }
  .cxl-table-section .compare-pkgs .comparison-chart td.dropdown ul {
    display: block;
  }
  .cxl-table-section .compare-pkgs .comparison-chart td p {
    margin-bottom: 7px;
  }
  .cxl-table-section .compare-pkgs .workforcenow .comparison-chart .header {
    padding: 40px 0 10px;
  }
  .cxl-table-section .compare-pkgs .workforcenow .comparison-chart .header .heading {
    font-size: 17px;
    line-height: 17px;
  }
  .cxl-table-section .compare-pkgs .workforcenow .comparison-list div.plus {
    left: -9px;
    top: -3px;
  }
}
.cxl-table-section {
  background: #f9f9f9;
}
.cxl-table-section .compare-pkgs .comparison-chart td.cxl-dropdown {
  cursor: pointer;
  position: relative;
}
.cxl-table-section .compare-pkgs .comparison-chart td.cxl-dropdown::before {
  display: block;
  content: '';
  position: absolute;
  top: 13px;
  left: 10px;
  width: 15px;
  height: 15px;
}
.cxl-table-section .compare-pkgs .comparison-chart td.cxl-dropdown.open::before {
  /* background-image: url(https://www.adp.com/-/media/adp/Redesign2018/ui/icn-contract-black.svg); */
}
.cxl-table-section .compare-pkgs .comparison-chart .cxl-dropdown > p {
  font-size: 15px;
  line-height: 23px;
  font-family: OpenSans_medium, Arial, sans-serif;
}
.cxl-table-section .compare-pkgs .comparison-chart p.cxl-included-text {
  text-align: center;
  font-size: 17px;
  line-height: 25px;
  letter-spacing: 0.2px;
  font-weight: 400;
  color: #222;
  max-width: 160px;
  margin-left: auto;
  margin-right: auto;
  min-height: 105px;
}
td ul.cxl-brown-list,
td ul.cxl-pink-list,
td ul.cxl-purple-list,
ul.cxl-red-list {
  margin: 0;
  padding: 0;
  list-style: none;
}
ul.cxl-brown-list li,
ul.cxl-pink-list li,
ul.cxl-purple-list li,
ul.cxl-red-list li {
  position: relative;
  font-size: 17px;
  line-height: 25px;
  letter-spacing: 0.2px;
  font-weight: 400;
  color: #222;
  padding-left: 25px;
  margin-bottom: 15px;
}
ul.cxl-brown-list li:before,
ul.cxl-pink-list li:before,
ul.cxl-purple-list li:before,
ul.cxl-red-list li:before {
  position: absolute;
  content: '';
  top: 6px;
  left: 0;
  width: 14px;
  height: 11px;
  display: block;
  /* background: url(https://www.adp.com/-/media/adp/Redesign2018/ui/icn-check-thick-red.svg) center
    center/contain no-repeat; */
}
ul.cxl-purple-list li:before {
  /* background-image: url(https://www.adp.com/-/media/adp/Redesign2018/ui/icn-check-thick-purple.svg); */
}
ul.cxl-pink-list li:before {
  /* background-image: url(https://www.adp.com/-/media/adp/Redesign2018/ui/icn-check-thick-pink.svg); */
}
ul.cxl-brown-list li:before {
  /* background-image: url(https://www.adp.com/-/media/adp/Redesign2018/ui/icn-check-thick-brown.svg); */
}
.cxl-content {
  position: relative;
  transition: all 0.5s ease;
}
@media (min-width: 768px) {
  .cxl-content.cxl-content-collapse {
    height: 341px;
    overflow: hidden;
  }
}
.cxl-content.cxl-content-collapse .cxl-content-cta {
  position: absolute;
  bottom: 0;
}
.cxl-compare-cta {
  margin-top: 30px;
  margin-bottom: 0;
}
.cxl-content-cta {
  position: relative;
  left: 0;
  width: 100%;
  padding-top: 30px;
  background: #f9f9f9;
}
.cxl-content-cta p {
  margin: 0;
}
.cxl-content-cta:hover {
  text-decoration: none;
}
.cxl-see-more-cta {
  padding: 0;
  color: #5478d0;
  font-size: 20px;
  position: relative;
}
.cxl-see-more-cta:before {
  content: '';
  display: block;
  position: absolute;
  left: -35px;
  top: 0;
  /* background: url(../assets/ui/icn-contract-hover.svg) center center/cover no-repeat; */
  width: 25px;
  height: 25px;
}
.cxl-see-more-cta:hover {
  text-decoration: none;
}
.compare-pkgs .comparison-list {
  display: none;
}
.compare-pkgs .comparison-list .header {
  background-repeat: repeat-x;
  padding: 20px 18px 16px;
  margin-top: 40px;
}
.compare-pkgs .comparison-list .header:first-child {
  margin-top: 0;
}
.compare-pkgs .comparison-list .header h3 {
  font-size: 18px;
  margin-bottom: 6px;
}
.compare-pkgs .comparison-list .header p {
  font-size: 14px;
  margin-bottom: 15px;
}
.compare-pkgs .comparison-list .dropdown-mobile {
  position: relative;
  background-color: #26328c;
  cursor: pointer;
}
.compare-pkgs .comparison-list .dropdown-mobile::after {
  content: '';
  display: block;
  position: absolute;
  top: 13px;
  right: 13px;
  height: 14px;
  width: 14px;
  /* background: url(../assets/ui/icn-plus-white.svg) center center/contain no-repeat; */
}
.compare-pkgs .comparison-list .dropdown-mobile.open {
  background-color: #121c4e;
}
.compare-pkgs .comparison-list .dropdown-mobile.open::after {
  /* background-image: url(../assets/ui/icn-minus-white.svg); */
}
.compare-pkgs .comparison-list .dropdown-mobile h4 {
  margin-bottom: 0;
  font-size: 17px;
  color: #fff;
  position: relative;
  padding: 8px 11px;
}
.compare-pkgs .comparison-list .reveal {
  background-color: #fff;
  font-size: 14px;
  line-height: 22px;
  cursor: initial;
  font-weight: 600;
}
.compare-pkgs .comparison-list .reveal .dropdown {
  cursor: pointer;
  position: relative;
}
.compare-pkgs .comparison-list .reveal .dropdown::before {
  display: block;
  content: '';
  position: absolute;
  top: 2px;
  left: 0;
  width: 17px;
  height: 17px;
  /* background: url(../assets/ui/icn-list-open.svg) center center/contain no-repeat; */
}
.compare-pkgs .comparison-list .reveal .dropdown.open::before {
  /* background-image: url(../assets/ui/icn-list-close-hover.svg); */
}
.compare-pkgs .comparison-list .reveal ul {
  padding-top: 7px;
  display: block;
  list-style-type: none;
  margin-bottom: 15px;
}
.compare-pkgs .comparison-list .reveal ul li {
  padding: 4px 11px 4px 18px;
  background-color: #fff;
}
.compare-pkgs .comparison-list .reveal ul li:nth-child(even) {
  background-color: rgba(243, 243, 243, 0.5);
}
.compare-pkgs .comparison-list .reveal .btn {
  margin: 0 auto;
  display: block;
  width: 200px;
  padding: 12px;
}
.compare-pkgs .comparison-list .reveal.active {
  display: block !important;
}
@media (max-width: 767px) {
  .compare-pkgs .comparison-list,
  .cxl-table-section .compare-pkgs .comparison-list {
    display: block;
  }
}
table.data-table {
  width: 100%;
  border-collapse: collapse;
  border-bottom: 7px solid #d4d4d4;
  margin: 0 auto 20px;
}
table.data-table.table-striping tbody tr:nth-child(even) {
  background: #f3f3f3;
}
table.data-table.table-security {
  font-family: Specter_bold, Arial, sans-serif !important;
  margin: 0 0 20px;
  border: none;
  border-bottom: 1px dashed #979797;
}
table.data-table.table-security tr {
  border-top: 1px dashed #979797;
}
table.data-table.table-security td {
  padding: 21px 8px;
  border: none;
}
table.data-table.table-security td a {
  font-size: 16px;
}
table.data-table.table-security td:first-child {
  width: 30%;
  font-size: 16px;
}
table.data-table tbody tr th,
table.data-table thead tr th {
  font-family: Specter_bold, Arial, sans-serif;
  border-left: 1px solid #d4d4d4;
  border-right: 1px solid #d4d4d4;
  border-top: 6px solid #41d4ff;
  background: padding-box #f2fafd;
  position: relative;
  padding: 24px 8px 15px;
  font-size: 18px;
}
table.data-table tbody tr td,
table.data-table thead tr td {
  border: 1px solid #d4d4d4;
  padding: 10px 8px 25px;
  font-size: 14px;
}
table.data-table tbody tr td,
table.data-table tbody tr th,
table.data-table thead tr td,
table.data-table thead tr th {
  text-align: left;
}
table.data-table tbody tr td a,
table.data-table tbody tr th a,
table.data-table thead tr td a,
table.data-table thead tr th a {
  color: #009edd;
  font-size: 14px;
}
table.data-table tbody tr td a:hover,
table.data-table tbody tr th a:hover,
table.data-table thead tr td a:hover,
table.data-table thead tr th a:hover {
  color: #0069a6;
}
table.data-table.hide-border {
  border: none !important;
}
table.data-table.hide-border tbody tr,
table.data-table.hide-border thead tr {
  border: none !important;
  margin-bottom: 0 !important;
}
table.data-table.hide-border tbody tr td,
table.data-table.hide-border tbody tr th,
table.data-table.hide-border thead tr td,
table.data-table.hide-border thead tr th,
table.data-table.hide-td-border tbody tr td,
table.data-table.hide-td-border tbody tr th,
table.data-table.hide-td-border thead tr td,
table.data-table.hide-td-border thead tr th {
  border: none !important;
  padding: 5px 0 !important;
}
table.data-table.hide-tr-border tbody tr,
table.data-table.hide-tr-border thead tr {
  border: none !important;
  margin-bottom: 0 !important;
}
@media only screen and (max-width: 767px) {
  table.data-table {
    display: block;
    border-bottom: none;
  }
  table.data-table tbody,
  table.data-table td,
  table.data-table th,
  table.data-table thead,
  table.data-table tr {
    display: block;
  }
  table.data-table thead tr {
    display: none;
  }
  table.data-table tr {
    border-top: 6px solid #41d4ff;
    border-bottom: 0;
    margin-bottom: 20px;
  }
  table.data-table td,
  table.data-table th {
    position: relative;
    padding: 5px !important;
  }
  table.data-table td:empty,
  table.data-table th:empty {
    display: none;
  }
  table.data-table td:before,
  table.data-table th:before {
    position: absolute;
    top: 0;
    left: 6px;
    width: 45%;
    padding-right: 10px;
    white-space: nowrap;
  }
  table.data-table.table-security tr {
    border-top: 1px dashed #979797;
    margin-bottom: 0;
  }
  table.data-table.table-security td,
  table.data-table.table-security td:first-child {
    width: 100%;
  }
  .demo .demo-steps ul li.step {
    margin: 0 9px 0 0;
  }
}
.flatpickr-calendar {
  background: #fff;
  opacity: 0;
  display: none;
  text-align: center;
  visibility: hidden;
  padding: 0;
  -webkit-animation: none;
  animation: none;
  direction: ltr;
  border: 0;
  font-size: 14px;
  line-height: 24px;
  border-radius: 5px;
  position: absolute;
  width: 307.875px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  -webkit-box-shadow: 1px 0 0 #e6e6e6, -1px 0 0 #e6e6e6, 0 1px 0 #e6e6e6, 0 -1px 0 #e6e6e6,
    0 3px 13px rgba(0, 0, 0, 0.08);
  box-shadow: 1px 0 0 #e6e6e6, -1px 0 0 #e6e6e6, 0 1px 0 #e6e6e6, 0 -1px 0 #e6e6e6,
    0 3px 13px rgba(0, 0, 0, 0.08);
}
.flatpickr-calendar.inline,
.flatpickr-calendar.open {
  opacity: 1;
  max-height: 640px;
  visibility: visible;
}
.flatpickr-calendar.open {
  display: inline-block;
  z-index: 99999;
}
.flatpickr-calendar.animate.open {
  -webkit-animation: 0.3s cubic-bezier(0.23, 1, 0.32, 1) fpFadeInDown;
  animation: 0.3s cubic-bezier(0.23, 1, 0.32, 1) fpFadeInDown;
}
.flatpickr-calendar.inline {
  display: block;
  position: relative;
  top: 2px;
}
.flatpickr-calendar.static {
  position: absolute;
  top: calc(102%);
}
.flatpickr-calendar.static.open {
  z-index: 999;
  display: block;
}
.flatpickr-calendar.multiMonth
  .flatpickr-days
  .dayContainer:nth-child(n + 1)
  .flatpickr-day.inRange:nth-child(7n + 7) {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.flatpickr-calendar.multiMonth
  .flatpickr-days
  .dayContainer:nth-child(n + 2)
  .flatpickr-day.inRange:nth-child(7n + 1) {
  -webkit-box-shadow: -2px 0 0 #e6e6e6, 5px 0 0 #e6e6e6;
  box-shadow: -2px 0 0 #e6e6e6, 5px 0 0 #e6e6e6;
}
.flatpickr-calendar .hasTime .dayContainer,
.flatpickr-calendar .hasWeeks .dayContainer {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.flatpickr-calendar .hasWeeks .dayContainer {
  border-left: 0;
}
.flatpickr-calendar.showTimeInput.hasTime .flatpickr-time {
  height: 40px;
  border-top: 1px solid #e6e6e6;
}
.flatpickr-calendar.noCalendar.hasTime .flatpickr-time {
  height: auto;
}
.flatpickr-calendar:after,
.flatpickr-calendar:before {
  position: absolute;
  display: block;
  pointer-events: none;
  border: solid transparent;
  content: '';
  height: 0;
  width: 0;
  left: 22px;
}
.flatpickr-calendar.rightMost:after,
.flatpickr-calendar.rightMost:before {
  left: auto;
  right: 22px;
}
.flatpickr-calendar:before {
  border-width: 5px;
  margin: 0 -5px;
}
.flatpickr-calendar:after {
  border-width: 4px;
  margin: 0 -4px;
}
.flatpickr-calendar.arrowTop:after,
.flatpickr-calendar.arrowTop:before {
  bottom: 100%;
}
.flatpickr-calendar.arrowTop:before {
  border-bottom-color: #e6e6e6;
}
.flatpickr-calendar.arrowTop:after {
  border-bottom-color: #fff;
}
.flatpickr-calendar.arrowBottom:after,
.flatpickr-calendar.arrowBottom:before {
  top: 100%;
}
.flatpickr-calendar.arrowBottom:before {
  border-top-color: #e6e6e6;
}
.flatpickr-calendar.arrowBottom:after {
  border-top-color: #fff;
}
.flatpickr-calendar:focus {
  outline: 0;
}
.flatpickr-wrapper {
  position: relative;
  display: inline-block;
}
.flatpickr-months {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
.flatpickr-months .flatpickr-month {
  background: 0 0;
  color: rgba(0, 0, 0, 0.9);
  fill: rgba(0, 0, 0, 0.9);
  height: 34px;
  line-height: 1;
  text-align: center;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  overflow: hidden;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.flatpickr-months .flatpickr-next-month,
.flatpickr-months .flatpickr-prev-month {
  text-decoration: none;
  cursor: pointer;
  position: absolute;
  top: 0;
  height: 34px;
  padding: 10px;
  z-index: 3;
  color: rgba(0, 0, 0, 0.9);
  fill: rgba(0, 0, 0, 0.9);
}
.flatpickr-months .flatpickr-next-month.flatpickr-disabled,
.flatpickr-months .flatpickr-prev-month.flatpickr-disabled {
  display: none;
}
.flatpickr-months .flatpickr-next-month i,
.flatpickr-months .flatpickr-prev-month i {
  position: relative;
}
.flatpickr-months .flatpickr-next-month.flatpickr-prev-month,
.flatpickr-months .flatpickr-prev-month.flatpickr-prev-month {
  left: 0;
}
.flatpickr-months .flatpickr-next-month.flatpickr-next-month,
.flatpickr-months .flatpickr-prev-month.flatpickr-next-month {
  right: 0;
}
.flatpickr-months .flatpickr-next-month:hover,
.flatpickr-months .flatpickr-prev-month:hover {
  color: #959ea9;
}
.flatpickr-months .flatpickr-next-month:hover svg,
.flatpickr-months .flatpickr-prev-month:hover svg {
  fill: #f64747;
  vertical-align: top;
}
.flatpickr-months .flatpickr-next-month svg,
.flatpickr-months .flatpickr-prev-month svg {
  width: 14px;
  height: 14px;
}
.flatpickr-months .flatpickr-next-month svg path,
.flatpickr-months .flatpickr-prev-month svg path {
  -webkit-transition: fill 0.1s;
  transition: fill 0.1s;
  fill: inherit;
}
.numInputWrapper {
  position: relative;
  height: auto;
}
.numInputWrapper input,
.numInputWrapper span {
  display: inline-block;
}
.numInputWrapper input {
  width: 100%;
}
.numInputWrapper input::-ms-clear {
  display: none;
}
.numInputWrapper input::-webkit-inner-spin-button,
.numInputWrapper input::-webkit-outer-spin-button {
  margin: 0;
  -webkit-appearance: none;
}
.numInputWrapper span {
  position: absolute;
  right: 0;
  width: 14px;
  padding: 0 4px 0 2px;
  height: 50%;
  line-height: 50%;
  opacity: 0;
  cursor: pointer;
  border: 1px solid rgba(57, 57, 57, 0.15);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.numInputWrapper span:hover {
  background: rgba(0, 0, 0, 0.1);
}
.numInputWrapper span:active {
  background: rgba(0, 0, 0, 0.2);
}
.numInputWrapper span:after {
  display: block;
  content: '';
  position: absolute;
}
.numInputWrapper span.arrowUp {
  top: 0;
  border-bottom: 0;
}
.numInputWrapper span.arrowUp:after {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-bottom: 4px solid rgba(57, 57, 57, 0.6);
  top: 26%;
}
.numInputWrapper span.arrowDown {
  top: 50%;
}
.numInputWrapper span.arrowDown:after {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid rgba(57, 57, 57, 0.6);
  top: 40%;
}
.numInputWrapper span svg {
  width: inherit;
  height: auto;
}
.numInputWrapper span svg path {
  fill: rgba(0, 0, 0, 0.5);
}
.numInputWrapper:hover {
  background: rgba(0, 0, 0, 0.05);
}
.numInputWrapper:hover span {
  opacity: 1;
}
.flatpickr-current-month {
  font-size: 135%;
  font-weight: 300;
  color: inherit;
  position: absolute;
  width: 75%;
  left: 12.5%;
  padding: 7.48px 0 0;
  line-height: 1;
  height: 34px;
  display: inline-block;
  text-align: center;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.flatpickr-current-month span.cur-month {
  font-family: inherit;
  font-weight: 700;
  color: inherit;
  display: inline-block;
  margin-left: 0.5ch;
  padding: 0;
}
.flatpickr-current-month span.cur-month:hover {
  background: rgba(0, 0, 0, 0.05);
}
.flatpickr-current-month .numInputWrapper {
  width: 6ch;
  display: inline-block;
}
.flatpickr-current-month .numInputWrapper span.arrowUp:after {
  border-bottom-color: rgba(0, 0, 0, 0.9);
}
.flatpickr-current-month .numInputWrapper span.arrowDown:after {
  border-top-color: rgba(0, 0, 0, 0.9);
}
.flatpickr-current-month input.cur-year {
  background: 0 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: inherit;
  cursor: text;
  padding: 0 0 0 0.5ch;
  margin: 0;
  margin-bottom: 0 !important;
  display: inline-block;
  font-size: inherit;
  font-family: inherit;
  font-weight: 300;
  line-height: inherit;
  height: auto;
  border: 0;
  border-radius: 0;
  vertical-align: initial;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}
.flatpickr-current-month input.cur-year:focus {
  outline: 0;
}
.flatpickr-current-month input.cur-year[disabled],
.flatpickr-current-month input.cur-year[disabled]:hover {
  font-size: 100%;
  color: rgba(0, 0, 0, 0.5);
  background: 0 0;
  pointer-events: none;
}
.flatpickr-current-month .flatpickr-monthDropdown-months {
  appearance: menulist;
  background: 0 0;
  border: none;
  border-radius: 0;
  box-sizing: border-box;
  color: inherit;
  cursor: pointer;
  font-size: inherit;
  font-family: inherit;
  font-weight: 300;
  height: auto;
  line-height: inherit;
  margin: -1px 0 0;
  outline: 0;
  padding: 0 0 0 0.5ch;
  position: relative;
  vertical-align: initial;
  -webkit-box-sizing: border-box;
  -webkit-appearance: menulist;
  -moz-appearance: menulist;
  width: auto !important;
  display: inline-block;
}
.flatpickr-current-month .flatpickr-monthDropdown-months:active,
.flatpickr-current-month .flatpickr-monthDropdown-months:focus {
  outline: 0;
}
.flatpickr-current-month .flatpickr-monthDropdown-months:hover {
  background: rgba(0, 0, 0, 0.05);
}
.flatpickr-current-month .flatpickr-monthDropdown-months .flatpickr-monthDropdown-month {
  background-color: transparent;
  outline: 0;
  padding: 0;
}
.flatpickr-weekdays {
  background: 0 0;
  text-align: center;
  overflow: hidden;
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  height: 28px;
}
.flatpickr-weekdays .flatpickr-weekdaycontainer {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
span.flatpickr-weekday {
  cursor: default;
  font-size: 90%;
  background: 0 0;
  color: rgba(0, 0, 0, 0.54);
  line-height: 1;
  margin: 0;
  text-align: center;
  display: block;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  font-weight: bolder;
}
.dayContainer,
.flatpickr-weeks {
  padding: 1px 0 0;
}
.flatpickr-days {
  position: relative;
  overflow: hidden;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  width: 307.875px;
}
.flatpickr-days:focus {
  outline: 0;
}
.dayContainer {
  padding: 0;
  outline: 0;
  text-align: left;
  width: 307.875px;
  min-width: 307.875px;
  max-width: 307.875px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: inline-block;
  display: -ms-flexbox;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-around;
  justify-content: space-around;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  opacity: 1;
}
.dayContainer + .dayContainer {
  -webkit-box-shadow: -1px 0 0 #e6e6e6;
  box-shadow: -1px 0 0 #e6e6e6;
}
.flatpickr-day {
  background: 0 0;
  border: 1px solid transparent;
  border-radius: 150px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #393939;
  cursor: pointer;
  font-weight: 400;
  width: 14.2857143%;
  -webkit-flex-basis: 14.2857143%;
  -ms-flex-preferred-size: 14.2857143%;
  flex-basis: 14.2857143%;
  max-width: 39px;
  height: 39px;
  line-height: 39px;
  margin: 0;
  display: inline-block;
  position: relative;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
}
.flatpickr-day.inRange,
.flatpickr-day.nextMonthDay.inRange,
.flatpickr-day.nextMonthDay.today.inRange,
.flatpickr-day.nextMonthDay:focus,
.flatpickr-day.nextMonthDay:hover,
.flatpickr-day.prevMonthDay.inRange,
.flatpickr-day.prevMonthDay.today.inRange,
.flatpickr-day.prevMonthDay:focus,
.flatpickr-day.prevMonthDay:hover,
.flatpickr-day.today.inRange,
.flatpickr-day:focus,
.flatpickr-day:hover {
  cursor: pointer;
  outline: 0;
  background: #e6e6e6;
  border-color: #e6e6e6;
}
.flatpickr-day.today {
  border-color: #959ea9;
}
.flatpickr-day.today:focus,
.flatpickr-day.today:hover {
  border-color: #959ea9;
  background: #959ea9;
  color: #fff;
}
.flatpickr-day.endRange,
.flatpickr-day.endRange.inRange,
.flatpickr-day.endRange.nextMonthDay,
.flatpickr-day.endRange.prevMonthDay,
.flatpickr-day.endRange:focus,
.flatpickr-day.endRange:hover,
.flatpickr-day.selected,
.flatpickr-day.selected.inRange,
.flatpickr-day.selected.nextMonthDay,
.flatpickr-day.selected.prevMonthDay,
.flatpickr-day.selected:focus,
.flatpickr-day.selected:hover,
.flatpickr-day.startRange,
.flatpickr-day.startRange.inRange,
.flatpickr-day.startRange.nextMonthDay,
.flatpickr-day.startRange.prevMonthDay,
.flatpickr-day.startRange:focus,
.flatpickr-day.startRange:hover {
  background: #569ff7;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #fff;
  border-color: #569ff7;
}
.flatpickr-day.endRange.startRange,
.flatpickr-day.selected.startRange,
.flatpickr-day.startRange.startRange {
  border-radius: 50px 0 0 50px;
}
.flatpickr-day.endRange.endRange,
.flatpickr-day.selected.endRange,
.flatpickr-day.startRange.endRange {
  border-radius: 0 50px 50px 0;
}
.flatpickr-day.endRange.startRange + .endRange:not(:nth-child(7n + 1)),
.flatpickr-day.selected.startRange + .endRange:not(:nth-child(7n + 1)),
.flatpickr-day.startRange.startRange + .endRange:not(:nth-child(7n + 1)) {
  -webkit-box-shadow: -10px 0 0 #569ff7;
  box-shadow: -10px 0 0 #569ff7;
}
.flatpickr-day.endRange.startRange.endRange,
.flatpickr-day.selected.startRange.endRange,
.flatpickr-day.startRange.startRange.endRange {
  border-radius: 50px;
}
.flatpickr-day.inRange {
  border-radius: 0;
  -webkit-box-shadow: -5px 0 0 #e6e6e6, 5px 0 0 #e6e6e6;
  box-shadow: -5px 0 0 #e6e6e6, 5px 0 0 #e6e6e6;
}
.flatpickr-day.flatpickr-disabled,
.flatpickr-day.flatpickr-disabled:hover,
.flatpickr-day.nextMonthDay,
.flatpickr-day.notAllowed,
.flatpickr-day.notAllowed.nextMonthDay,
.flatpickr-day.notAllowed.prevMonthDay,
.flatpickr-day.prevMonthDay {
  color: rgba(57, 57, 57, 0.3);
  background: 0 0;
  border-color: transparent;
  cursor: default;
}
.flatpickr-day.flatpickr-disabled,
.flatpickr-day.flatpickr-disabled:hover {
  cursor: not-allowed;
  color: rgba(57, 57, 57, 0.1);
}
.flatpickr-day.week.selected {
  border-radius: 0;
  -webkit-box-shadow: -5px 0 0 #569ff7, 5px 0 0 #569ff7;
  box-shadow: -5px 0 0 #569ff7, 5px 0 0 #569ff7;
}
.flatpickr-day.hidden {
  visibility: hidden;
}
.rangeMode .flatpickr-day {
  margin-top: 1px;
}
.flatpickr-weekwrapper {
  float: left;
}
.flatpickr-weekwrapper .flatpickr-weeks {
  padding: 0 12px;
  -webkit-box-shadow: 1px 0 0 #e6e6e6;
  box-shadow: 1px 0 0 #e6e6e6;
}
.flatpickr-weekwrapper .flatpickr-weekday {
  float: none;
  width: 100%;
  line-height: 28px;
}
.flatpickr-weekwrapper span.flatpickr-day,
.flatpickr-weekwrapper span.flatpickr-day:hover {
  display: block;
  width: 100%;
  max-width: none;
  color: rgba(57, 57, 57, 0.3);
  background: 0 0;
  cursor: default;
  border: none;
}
.flatpickr-innerContainer {
  display: block;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow: hidden;
}
.flatpickr-rContainer {
  display: inline-block;
  padding: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.flatpickr-time {
  text-align: center;
  outline: 0;
  display: block;
  height: 0;
  line-height: 40px;
  max-height: 40px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow: hidden;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
.flatpickr-time:after {
  content: '';
  display: table;
  clear: both;
}
.flatpickr-time .numInputWrapper {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  width: 40%;
  height: 40px;
  float: left;
}
.flatpickr-time .numInputWrapper span.arrowUp:after {
  border-bottom-color: #393939;
}
.flatpickr-time .numInputWrapper span.arrowDown:after {
  border-top-color: #393939;
}
.flatpickr-time.hasSeconds .numInputWrapper {
  width: 26%;
}
.flatpickr-time.time24hr .numInputWrapper {
  width: 49%;
}
.flatpickr-time input {
  background: 0 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: 0;
  text-align: center;
  margin: 0;
  padding: 0;
  height: inherit;
  line-height: inherit;
  color: #393939;
  font-size: 14px;
  position: relative;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
  border: none !important;
  margin-bottom: 0 !important;
  display: inline-block !important;
}
.flatpickr-time input.flatpickr-hour {
  font-weight: 700;
}
.flatpickr-time input.flatpickr-minute,
.flatpickr-time input.flatpickr-second {
  font-weight: 400;
}
.flatpickr-time input:focus {
  outline: 0;
  border: 0;
}
.flatpickr-time .flatpickr-am-pm,
.flatpickr-time .flatpickr-time-separator {
  height: inherit;
  float: left;
  line-height: inherit;
  color: #393939;
  font-weight: 700;
  width: 2%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
}
.flatpickr-time .flatpickr-am-pm {
  outline: 0;
  width: 18%;
  cursor: pointer;
  text-align: center;
  font-weight: 400;
}
.flatpickr-time .flatpickr-am-pm:focus,
.flatpickr-time .flatpickr-am-pm:hover,
.flatpickr-time input:focus,
.flatpickr-time input:hover {
  background: #eee;
}
.flatpickr-input[readonly] {
  cursor: pointer;
}
@-webkit-keyframes fpFadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes fpFadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
.demo {
  padding-left: 0;
  padding-right: 0;
}
.demo .demo-image-wrap img {
  max-width: 100%;
  display: none;
}
.demo .demo-steps {
  text-align: center;
  margin-top: 30px;
}
.demo .demo-steps ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 0 10px;
}
@media only screen and (max-width: 991px) {
  .demo .demo-steps ul {
    padding: 0;
  }
}
.demo .demo-steps ul li.step {
  cursor: pointer;
}
@media only screen and (max-width: 340px) {
  .demo .demo-steps ul li.step {
    margin: 0 2px 0 0;
  }
}
.demo .demo-steps ul li.step a {
  font-size: 12px;
  display: inline-block;
  border: 1px solid #9e9fa0;
  color: #9e9fa0;
  line-height: 28px;
  width: 30px;
  vertical-align: middle;
  border-radius: 50%;
}
.demo .demo-steps ul li.step a.step-active {
  background-color: #121c4e;
  border: 1px solid #121c4e !important;
  color: #fff !important;
}
.demo .demo-steps ul li.step a:hover {
  text-decoration: none;
}
@media only screen and (max-width: 767px) {
  .demo .demo-steps ul li.step a {
    width: 24px;
    height: 24px;
    line-height: 22px;
  }
}
.demo .demo-steps ul li.step span {
  color: #9e9fa0;
  display: block;
  margin: 20px auto 0;
  font-size: 12px;
  font-weight: 600;
  line-height: 14px;
}
.demo .demo-steps ul li.step:last-of-type {
  margin: 0;
}
.demo .demo-copy {
  display: flex;
  flex-direction: column;
  height: 370px;
}
@media only screen and (max-width: 767px) {
  .demo .demo-steps ul li.step span {
    display: none;
  }
  .demo .demo-copy {
    height: auto;
    margin-top: 25px;
  }
}
.demo .demo-copy a.btn,
.demo .demo-copy div.btn-group {
  margin-top: auto;
}
.demo .demo-copy div.btn-group a.btn {
  margin-top: 10px;
}
.demo .fadeIn {
  animation-name: fadeIn;
  animation-duration: 1s;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.bg-demo-gradient {
  background: #f7f6f4;
}
.ellip {
  display: block;
  height: 100%;
}
.ellip-line {
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
}
.ellip,
.ellip-line {
  position: relative;
  overflow: hidden;
  max-width: 100%;
}
.hide-contact #support-menu .btn.trigger-modal {
  display: none;
}
#support-menu {
  position: fixed;
  top: 0;
  right: -320px;
  bottom: 0;
  width: 320px;
  background-color: #121c4e;
  color: #fff;
  text-align: left;
  padding: 80px 80px 30px 30px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  z-index: 1100;
}
#support-menu.open {
  -ms-transform: translateX(-320px);
  -webkit-transform: translate3d(-320px, 0, 0);
  transform: translate3d(-320px, 0, 0);
  -webkit-transform-origin: right;
  -ms-transform-origin: right;
  transform-origin: right;
}
#support-menu .close-icn {
  position: absolute;
  cursor: pointer;
  top: 15px;
  right: 15px;
  width: 19px;
  height: 19px;
  /* background: url(../images/ui/icn-close-white.svg) center center/contain no-repeat; */
}
#support-menu .btn {
  width: 100%;
}
#support-menu .btn.btn-outline-rev,
#support-menu .btn.trigger-modal {
  margin-bottom: 45px;
}
#support-menu label {
  display: block;
  font-size: 15px;
  line-height: 1.5;
  text-transform: uppercase;
  margin-bottom: 5px;
}
#support-menu .link-arrow-rev {
  margin-bottom: 8px;
}
#support-menu .anchor-links li {
  border-bottom: 1px solid #fff;
  margin: 0 0 0 -30px;
  width: 320px;
}
#support-menu .anchor-links li:first-child {
  border-top: 1px solid #fff;
}
#support-menu .anchor-links li a {
  color: #fff;
  text-transform: uppercase;
  padding: 20px 30px;
  display: inline-block;
  width: 100%;
}
.container-fluid,
.global-header,
footer {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.container-fluid.menu-open,
.global-header.menu-open,
footer.menu-open {
  -ms-transform: translateX(-320px);
  -webkit-transform: translate3d(-320px, 0, 0);
  transform: translate3d(-320px, 0, 0);
  -webkit-transform-origin: right;
  -ms-transform-origin: right;
  transform-origin: right;
}
.global-header .mobile-menu-hamburger {
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;
  padding: 0;
}
.global-header .hamburger-box {
  width: 25px;
  height: 24px;
  display: inline-block;
  position: relative;
  margin-top: 11px;
}
.global-header .hamburger-inner,
.global-header .hamburger-inner::after,
.global-header .hamburger-inner::before {
  display: block;
  position: absolute;
  height: 2px;
  background-color: #222;
  border-radius: 4px;
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease;
}
.global-header .hamburger--slider .hamburger-inner,
.global-header .hamburger--slider-r .hamburger-inner,
.global-header .hamburger-inner {
  top: 50%;
  width: 24px;
}
.global-header .hamburger--slider .hamburger-inner::before,
.global-header .hamburger--slider-r .hamburger-inner::before,
.global-header .hamburger-inner::before {
  content: '';
  top: -8px;
  width: 100%;
}
.global-header .hamburger--slider .hamburger-inner::after,
.global-header .hamburger--slider-r .hamburger-inner::after,
.global-header .hamburger-inner::after {
  content: '';
  bottom: -8px;
  width: 100%;
}
.global-header .hamburger--slider.active .hamburger-inner {
  width: 32px;
  top: 2px;
  transform: translate3d(-4px, 10px, 0) rotate(45deg);
}
.global-header .hamburger--slider.active .hamburger-inner::before {
  width: 32px;
  transform: rotate(-45deg) translate3d(-5.71429px, -6px, 0);
  opacity: 0;
}
.global-header .hamburger--slider.active .hamburger-inner::after {
  width: 32px;
  top: 20px;
  transform: translate3d(0, -20px, 0) rotate(-90deg);
}
.global-header .hamburger--slider-r.active .hamburger-inner {
  width: 32px;
  top: 2px;
  transform: translate3d(0, 10px, 0) rotate(-45deg);
}
.global-header .hamburger--slider-r.active .hamburger-inner::before {
  width: 32px;
  transform: rotate(45deg) translate3d(-5.71429px, -6px, 0);
  opacity: 0;
}
.global-header .hamburger--slider-r.active .hamburger-inner::after {
  width: 32px;
  top: 20px;
  transform: translate3d(0, -20px, 0) rotate(90deg);
}
footer {
  background-color: transparent;
  padding: 0 0 40px;
}
@media (max-width: 992px) {
  footer ul.dot-border {
    border-top: 2px dashed #e4e4e4;
    padding-top: 15px;
    margin-bottom: 15px;
  }
  footer ul.dot-border-bottom {
    border-bottom: 2px dashed #e4e4e4;
  }
}
footer li {
  margin-bottom: 6px;
}
footer li a {
  line-height: 24px;
}
footer li a.bold {
  display: block;
  position: relative;
  font-family: Specter_bold, Arial, sans-serif;
}
footer li > div.footer-dropmenu-content {
  display: block;
  z-index: 1;
  left: 0;
  padding-top: 10px;
  padding-bottom: 20px;
}
footer li > div.footer-dropmenu-content a {
  display: block;
  font-family: Specter_regular, Arial, sans-serif;
  font-size: 16px;
  text-transform: inherit;
}
footer li > div.footer-dropmenu-content a:hover {
  background-color: #ecfaff;
  color: #121c4e;
}
footer li > div.footer-dropmenu-content ul {
  list-style: none;
}
@media (max-width: 991px) {
  footer li a.bold.has-sub-menu:after {
    content: '';
    display: block;
    float: right;
    margin-top: 7px;
    height: 14px;
    width: 14px;
    background: url('./images/ui/icn-carat-down-ui2.svg') center center/contain no-repeat;
    -webkit-transition: all 0.2s linear;
    transition: all 0.2s linear;
  }
  footer li a.bold.has-sub-menu.minus:after {
    background: url('./images/ui/icn-carat-up-ui2.svg') center center/contain no-repeat;
  }
  footer li > div.footer-dropmenu-content {
    display: none;
    position: relative;
    box-shadow: none;
    padding-top: 10px;
    left: 0;
    padding-bottom: 0;
  }
  footer li > div.footer-dropmenu-content.show {
    display: block;
  }
}
footer .legal-links {
  display: inline-block;
  clear: both;
}
footer .legal-links li {
  display: inline-block;
}
footer .legal-links li a {
  padding-left: 10px;
}
footer .legal-links li a::after {
  content: '|';
  display: inline-block;
  padding-left: 10px;
  color: #121c4e;
  font-family: Specter_regular;
}
footer .legal-links li:first-child a {
  padding-left: 0;
}
footer .legal-links li:last-child a::after {
  content: '';
  padding-left: 0;
}
footer .legal-links.white li a::after {
  color: #fff;
}
footer p.size14 {
  margin: 0 0 18px;
  line-height: 25px;
  clear: both;
}
@media (max-width: 767px) {
  footer li > div.footer-dropmenu-content {
    position: relative;
    box-shadow: none;
    padding-top: 10px;
    left: 0;
    padding-bottom: 0;
  }
  footer .legal-links,
  footer .sm-links {
    margin-bottom: 14px;
  }
  footer .legal-links.white a {
    font-family: Specter_bold;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  footer .legal-links,
  footer .sm-links {
    margin-bottom: 16px;
  }
}
.container {
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
}
@media (min-width: 768px) {
  .container {
    width: 720px;
  }
}
@media (min-width: 992px) {
  .container {
    width: 940px;
  }
}
@media (min-width: 1200px) {
  .container {
    width: 1140px;
  }
}
.container-fluid {
  margin-right: auto;
  margin-left: auto;
  padding: 40px 15px;
}
.container-fluid.no-gutter {
  padding-left: 0;
  padding-right: 0;
}
.row {
  margin-left: -15px;
  margin-right: -15px;
  width: 100%;
  max-width: 1160px;
}
.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-xs-1,
.col-xs-10,
.col-xs-11,
.col-xs-12,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9 {
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
}
.col-xs-1,
.col-xs-10,
.col-xs-11,
.col-xs-12,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9 {
  float: left;
}
.col-xs-12 {
  width: 100%;
}
.col-xs-11 {
  width: 91.66666667%;
}
.col-xs-10 {
  width: 83.33333333%;
}
.col-xs-9 {
  width: 75%;
}
.col-xs-8 {
  width: 66.66666667%;
}
.col-xs-7 {
  width: 58.33333333%;
}
.col-xs-6 {
  width: 50%;
}
.col-xs-5 {
  width: 41.66666667%;
}
.col-xs-4 {
  width: 33.33333333%;
}
.col-xs-3 {
  width: 25%;
}
.col-xs-2 {
  width: 16.66666667%;
}
.col-xs-1 {
  width: 8.33333333%;
}
.col-xs-pull-12 {
  right: 100%;
}
.col-xs-pull-11 {
  right: 91.66666667%;
}
.col-xs-pull-10 {
  right: 83.33333333%;
}
.col-xs-pull-9 {
  right: 75%;
}
.col-xs-pull-8 {
  right: 66.66666667%;
}
.col-xs-pull-7 {
  right: 58.33333333%;
}
.col-xs-pull-6 {
  right: 50%;
}
.col-xs-pull-5 {
  right: 41.66666667%;
}
.col-xs-pull-4 {
  right: 33.33333333%;
}
.col-xs-pull-3 {
  right: 25%;
}
.col-xs-pull-2 {
  right: 16.66666667%;
}
.col-xs-pull-1 {
  right: 8.33333333%;
}
.col-xs-pull-0 {
  right: auto;
}
.col-xs-push-12 {
  left: 100%;
}
.col-xs-push-11 {
  left: 91.66666667%;
}
.col-xs-push-10 {
  left: 83.33333333%;
}
.col-xs-push-9 {
  left: 75%;
}
.col-xs-push-8 {
  left: 66.66666667%;
}
.col-xs-push-7 {
  left: 58.33333333%;
}
.col-xs-push-6 {
  left: 50%;
}
.col-xs-push-5 {
  left: 41.66666667%;
}
.col-xs-push-4 {
  left: 33.33333333%;
}
.col-xs-push-3 {
  left: 25%;
}
.col-xs-push-2 {
  left: 16.66666667%;
}
.col-xs-push-1 {
  left: 8.33333333%;
}
.col-xs-push-0 {
  left: auto;
}
.col-xs-offset-12 {
  margin-left: 100%;
}
.col-xs-offset-11 {
  margin-left: 91.66666667%;
}
.col-xs-offset-10 {
  margin-left: 83.33333333%;
}
.col-xs-offset-9 {
  margin-left: 75%;
}
.col-xs-offset-8 {
  margin-left: 66.66666667%;
}
.col-xs-offset-7 {
  margin-left: 58.33333333%;
}
.col-xs-offset-6 {
  margin-left: 50%;
}
.col-xs-offset-5 {
  margin-left: 41.66666667%;
}
.col-xs-offset-4 {
  margin-left: 33.33333333%;
}
.col-xs-offset-3 {
  margin-left: 25%;
}
.col-xs-offset-2 {
  margin-left: 16.66666667%;
}
.col-xs-offset-1 {
  margin-left: 8.33333333%;
}
.col-xs-offset-0 {
  margin-left: 0;
}
@media (min-width: 768px) {
  .container-fluid {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .col-sm-1,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9 {
    float: left;
  }
  .col-sm-12 {
    width: 100%;
  }
  .col-sm-11 {
    width: 91.66666667%;
  }
  .col-sm-10 {
    width: 83.33333333%;
  }
  .col-sm-9 {
    width: 75%;
  }
  .col-sm-8 {
    width: 66.66666667%;
  }
  .col-sm-7 {
    width: 58.33333333%;
  }
  .col-sm-6 {
    width: 50%;
  }
  .col-sm-5 {
    width: 41.66666667%;
  }
  .col-sm-4 {
    width: 33.33333333%;
  }
  .col-sm-3 {
    width: 25%;
  }
  .col-sm-2 {
    width: 16.66666667%;
  }
  .col-sm-1 {
    width: 8.33333333%;
  }
  .col-sm-pull-12 {
    right: 100%;
  }
  .col-sm-pull-11 {
    right: 91.66666667%;
  }
  .col-sm-pull-10 {
    right: 83.33333333%;
  }
  .col-sm-pull-9 {
    right: 75%;
  }
  .col-sm-pull-8 {
    right: 66.66666667%;
  }
  .col-sm-pull-7 {
    right: 58.33333333%;
  }
  .col-sm-pull-6 {
    right: 50%;
  }
  .col-sm-pull-5 {
    right: 41.66666667%;
  }
  .col-sm-pull-4 {
    right: 33.33333333%;
  }
  .col-sm-pull-3 {
    right: 25%;
  }
  .col-sm-pull-2 {
    right: 16.66666667%;
  }
  .col-sm-pull-1 {
    right: 8.33333333%;
  }
  .col-sm-pull-0 {
    right: auto;
  }
  .col-sm-push-12 {
    left: 100%;
  }
  .col-sm-push-11 {
    left: 91.66666667%;
  }
  .col-sm-push-10 {
    left: 83.33333333%;
  }
  .col-sm-push-9 {
    left: 75%;
  }
  .col-sm-push-8 {
    left: 66.66666667%;
  }
  .col-sm-push-7 {
    left: 58.33333333%;
  }
  .col-sm-push-6 {
    left: 50%;
  }
  .col-sm-push-5 {
    left: 41.66666667%;
  }
  .col-sm-push-4 {
    left: 33.33333333%;
  }
  .col-sm-push-3 {
    left: 25%;
  }
  .col-sm-push-2 {
    left: 16.66666667%;
  }
  .col-sm-push-1 {
    left: 8.33333333%;
  }
  .col-sm-push-0 {
    left: auto;
  }
  .col-sm-offset-12 {
    margin-left: 100%;
  }
  .col-sm-offset-11 {
    margin-left: 91.66666667%;
  }
  .col-sm-offset-10 {
    margin-left: 83.33333333%;
  }
  .col-sm-offset-9 {
    margin-left: 75%;
  }
  .col-sm-offset-8 {
    margin-left: 66.66666667%;
  }
  .col-sm-offset-7 {
    margin-left: 58.33333333%;
  }
  .col-sm-offset-6 {
    margin-left: 50%;
  }
  .col-sm-offset-5 {
    margin-left: 41.66666667%;
  }
  .col-sm-offset-4 {
    margin-left: 33.33333333%;
  }
  .col-sm-offset-3 {
    margin-left: 25%;
  }
  .col-sm-offset-2 {
    margin-left: 16.66666667%;
  }
  .col-sm-offset-1 {
    margin-left: 8.33333333%;
  }
  .col-sm-offset-0 {
    margin-left: 0;
  }
}
@media (min-width: 992px) {
  .container-fluid {
    padding-top: 45px;
    padding-bottom: 45px;
  }
  .col-md-1,
  .col-md-10,
  .col-md-11,
  .col-md-12,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9 {
    float: left;
  }
  .col-md-12 {
    width: 100%;
  }
  .col-md-11 {
    width: 91.66666667%;
  }
  .col-md-10 {
    width: 83.33333333%;
  }
  .col-md-9 {
    width: 75%;
  }
  .col-md-8 {
    width: 66.66666667%;
  }
  .col-md-7 {
    width: 58.33333333%;
  }
  .col-md-6 {
    width: 50%;
  }
  .col-md-5 {
    width: 41.66666667%;
  }
  .col-md-4 {
    width: 33.33333333%;
  }
  .col-md-3 {
    width: 25%;
  }
  .col-md-2 {
    width: 16.66666667%;
  }
  .col-md-1 {
    width: 8.33333333%;
  }
  .col-md-pull-12 {
    right: 100%;
  }
  .col-md-pull-11 {
    right: 91.66666667%;
  }
  .col-md-pull-10 {
    right: 83.33333333%;
  }
  .col-md-pull-9 {
    right: 75%;
  }
  .col-md-pull-8 {
    right: 66.66666667%;
  }
  .col-md-pull-7 {
    right: 58.33333333%;
  }
  .col-md-pull-6 {
    right: 50%;
  }
  .col-md-pull-5 {
    right: 41.66666667%;
  }
  .col-md-pull-4 {
    right: 33.33333333%;
  }
  .col-md-pull-3 {
    right: 25%;
  }
  .col-md-pull-2 {
    right: 16.66666667%;
  }
  .col-md-pull-1 {
    right: 8.33333333%;
  }
  .col-md-pull-0 {
    right: auto;
  }
  .col-md-push-12 {
    left: 100%;
  }
  .col-md-push-11 {
    left: 91.66666667%;
  }
  .col-md-push-10 {
    left: 83.33333333%;
  }
  .col-md-push-9 {
    left: 75%;
  }
  .col-md-push-8 {
    left: 66.66666667%;
  }
  .col-md-push-7 {
    left: 58.33333333%;
  }
  .col-md-push-6 {
    left: 50%;
  }
  .col-md-push-5 {
    left: 41.66666667%;
  }
  .col-md-push-4 {
    left: 33.33333333%;
  }
  .col-md-push-3 {
    left: 25%;
  }
  .col-md-push-2 {
    left: 16.66666667%;
  }
  .col-md-push-1 {
    left: 8.33333333%;
  }
  .col-md-push-0 {
    left: auto;
  }
  .col-md-offset-12 {
    margin-left: 100%;
  }
  .col-md-offset-11 {
    margin-left: 91.66666667%;
  }
  .col-md-offset-10 {
    margin-left: 83.33333333%;
  }
  .col-md-offset-9 {
    margin-left: 75%;
  }
  .col-md-offset-8 {
    margin-left: 66.66666667%;
  }
  .col-md-offset-7 {
    margin-left: 58.33333333%;
  }
  .col-md-offset-6 {
    margin-left: 50%;
  }
  .col-md-offset-5 {
    margin-left: 41.66666667%;
  }
  .col-md-offset-4 {
    margin-left: 33.33333333%;
  }
  .col-md-offset-3 {
    margin-left: 25%;
  }
  .col-md-offset-2 {
    margin-left: 16.66666667%;
  }
  .col-md-offset-1 {
    margin-left: 8.33333333%;
  }
  .col-md-offset-0 {
    margin-left: 0;
  }
}
@media (min-width: 1200px) {
  .col-lg-1,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9 {
    float: left;
  }
  .col-lg-12 {
    width: 100%;
  }
  .col-lg-11 {
    width: 91.66666667%;
  }
  .col-lg-10 {
    width: 83.33333333%;
  }
  .col-lg-9 {
    width: 75%;
  }
  .col-lg-8 {
    width: 66.66666667%;
  }
  .col-lg-7 {
    width: 58.33333333%;
  }
  .col-lg-6 {
    width: 50%;
  }
  .col-lg-5 {
    width: 41.66666667%;
  }
  .col-lg-4 {
    width: 33.33333333%;
  }
  .col-lg-3 {
    width: 25%;
  }
  .col-lg-2 {
    width: 16.66666667%;
  }
  .col-lg-1 {
    width: 8.33333333%;
  }
  .col-lg-pull-12 {
    right: 100%;
  }
  .col-lg-pull-11 {
    right: 91.66666667%;
  }
  .col-lg-pull-10 {
    right: 83.33333333%;
  }
  .col-lg-pull-9 {
    right: 75%;
  }
  .col-lg-pull-8 {
    right: 66.66666667%;
  }
  .col-lg-pull-7 {
    right: 58.33333333%;
  }
  .col-lg-pull-6 {
    right: 50%;
  }
  .col-lg-pull-5 {
    right: 41.66666667%;
  }
  .col-lg-pull-4 {
    right: 33.33333333%;
  }
  .col-lg-pull-3 {
    right: 25%;
  }
  .col-lg-pull-2 {
    right: 16.66666667%;
  }
  .col-lg-pull-1 {
    right: 8.33333333%;
  }
  .col-lg-pull-0 {
    right: auto;
  }
  .col-lg-push-12 {
    left: 100%;
  }
  .col-lg-push-11 {
    left: 91.66666667%;
  }
  .col-lg-push-10 {
    left: 83.33333333%;
  }
  .col-lg-push-9 {
    left: 75%;
  }
  .col-lg-push-8 {
    left: 66.66666667%;
  }
  .col-lg-push-7 {
    left: 58.33333333%;
  }
  .col-lg-push-6 {
    left: 50%;
  }
  .col-lg-push-5 {
    left: 41.66666667%;
  }
  .col-lg-push-4 {
    left: 33.33333333%;
  }
  .col-lg-push-3 {
    left: 25%;
  }
  .col-lg-push-2 {
    left: 16.66666667%;
  }
  .col-lg-push-1 {
    left: 8.33333333%;
  }
  .col-lg-push-0 {
    left: auto;
  }
  .col-lg-offset-12 {
    margin-left: 100%;
  }
  .col-lg-offset-11 {
    margin-left: 91.66666667%;
  }
  .col-lg-offset-10 {
    margin-left: 83.33333333%;
  }
  .col-lg-offset-9 {
    margin-left: 75%;
  }
  .col-lg-offset-8 {
    margin-left: 66.66666667%;
  }
  .col-lg-offset-7 {
    margin-left: 58.33333333%;
  }
  .col-lg-offset-6 {
    margin-left: 50%;
  }
  .col-lg-offset-5 {
    margin-left: 41.66666667%;
  }
  .col-lg-offset-4 {
    margin-left: 33.33333333%;
  }
  .col-lg-offset-3 {
    margin-left: 25%;
  }
  .col-lg-offset-2 {
    margin-left: 16.66666667%;
  }
  .col-lg-offset-1 {
    margin-left: 8.33333333%;
  }
  .col-lg-offset-0 {
    margin-left: 0;
  }
}
input:not([type='radio']):not([type='checkbox']):not(.btn),
select {
  margin-bottom: 12px;
  position: relative;
}
input:not([type='radio']):not([type='checkbox']):not(.btn):active,
input:not([type='radio']):not([type='checkbox']):not(.btn):focus,
select:active,
select:focus {
  border: 1px solid #121c4e !important;
  background: #fff;
}
input[type='email'],
input[type='number'],
input[type='tel'],
input[type='text'],
select,
textarea {
  color: #121c4e;
  display: block;
  width: 100% !important;
  padding: 14px 20px;
  border-radius: 10px;
  background-color: #efeee9;
  border: none;
}
input[type='radio'] {
  display: inline-block;
  margin-top: 10px;
  margin-right: 5px;
}
input.btn,
input[type='submit'] {
  border: none;
  font-family: Specter_bold, Arial, sans-serif;
}
label {
  font-family: Specter_bold, Arial, sans-serif;
  padding: 14px 0;
}
.form-group {
  margin-bottom: 12px;
}
.form-group input:not([type='checkbox']),
.form-group select {
  margin-bottom: 0 !important;
}
.form-group.field-client label,
.form-group.field-client-center label {
  font-size: 16px;
}
.form-group.field-client label:last-of-type input[type='radio'],
.form-group.field-client-center label:last-of-type input[type='radio'] {
  margin-left: 8px;
}
.form-group.field-client label.control-label:first-of-type,
.form-group.field-client-center label.control-label:first-of-type {
  display: block;
}
.form-group.field-client-center {
  padding-top: 2px;
  float: none !important;
  text-align: center !important;
  margin-left: auto;
  margin-right: auto;
  clear: both;
}
.form-group.field-comply {
  width: 100% !important;
}
.form-group.field-comply .checkbox,
.form-group.field-comply span.help-block {
  width: 100%;
}
.form-group.field-comply span.help-block {
  margin-top: 3px;
}
.form-group.field-comply label {
  line-height: 18px;
  display: inline-block;
}
.form-group.field-comply input[type='checkbox'] {
  float: left;
  font-size: 16px;
  margin: 1px 9px 0 0;
}
.form-group.field-comply.has-error .checkbox {
  color: red;
}
.form-submit-border {
  width: 100%;
  float: left;
  text-align: center;
}
.form-container .hidden {
  display: none !important;
}
form select {
  position: relative;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
}
.help-block,
.validation-summary-errors,
.validation-summary-valid {
  display: none;
}
.has-feedback {
  position: relative;
}
.has-error .control-label {
  color: red;
}
.has-error .form-control {
  border: 1px solid red;
  z-index: 100;
  background: #fff1f1;
}
.has-error input[type='radio'] {
  margin-bottom: 12px !important;
}
.has-error .help-block {
  display: block;
  text-align: left;
  color: red;
  padding: 6px 6px 0;
}
.guidebook-form .form-container {
  background-color: #fff;
}
.guidebook-form .page-header p {
  text-align: center;
  font-family: Specter_bold, Arial, sans-serif;
  font-size: 28px;
  margin: 0 0 12px !important;
}
.guidebook-form .form-group {
  margin-bottom: 12px;
}
.guidebook-form .form-group.field-employees,
.guidebook-form .form-group.field-zip {
  float: left;
  width: 48%;
}
.guidebook-form .form-group.field-employees {
  margin-right: 4%;
}
.guidebook-form .form-group.field-country {
  clear: both;
}
.guidebook-form .form-group.field-contact {
  text-align: left;
  clear: both;
}
.guidebook-form .form-group.field-contact label {
  position: relative;
  display: block;
  font-size: 16px;
  padding-left: 24px;
}
.guidebook-form .form-group.field-contact label input[type='checkbox'] {
  position: absolute;
  left: 0;
  top: 5px;
}
@media (max-width: 767px) {
  .form-group.field-comply input[type='checkbox'] {
    margin: 3px 8px 0 0;
  }
  .guidebook-form .form-container {
    padding: 60px 15px;
  }
  .guidebook-form .form-group.field-contact label input[type='checkbox'] {
    top: 2px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .guidebook-form .form-container {
    padding: 80px 120px;
  }
  .guidebook-form .form-group.field-contact label input[type='checkbox'] {
    top: 7px;
  }
}
@media (min-width: 340px) {
  .guidebook-form .form-group.field-client label.control-label:first-of-type {
    display: inline-block;
    margin-right: 25px;
  }
  .guidebook-form .form-group.field-client input[type='radio'] {
    margin-top: 0;
  }
}
.guidebook-form .form-submit-border input {
  margin-top: 10px;
  margin-bottom: 12px;
}
.guidebook-form p:last-child {
  margin: 0;
}
.quote-form {
  background-color: #f3f3f3;
  text-align: center;
}
.quote-form .middle {
  text-align: center;
  width: 100%;
  max-width: 810px;
  margin-left: auto;
  margin-right: auto;
}
.quote-form h2,
.quote-form p {
  margin: 0 0 12px;
}
.quote-form h2:empty,
.quote-form p:empty {
  display: none;
}
.quote-form p:last-child {
  margin: 0;
  clear: both;
}
.quote-form .form-group {
  width: 100%;
  margin-bottom: 30px;
  text-align: left;
}
.quote-form .form-group.field-zip {
  margin-left: 4%;
}
.quote-form .form-group.field-comply {
  margin: 0 auto -20px;
}
.quote-form input.btn[type='submit'] {
  margin: 58px auto 30px;
}
.sales-form .middle {
  width: 100%;
}
.sales-form h2,
.sales-form p {
  margin: 0 0 12px;
}
.sales-form h2:empty,
.sales-form p:empty {
  display: none;
}
.sales-form p:last-child {
  text-align: center;
  margin: 0;
  clear: both;
}
.sales-form .form-group {
  width: 100%;
  margin-bottom: 30px;
  text-align: left;
}
.sales-form .form-group.field-zip {
  margin-left: 4%;
}
.sales-form .form-group.field-comply {
  margin: 0 auto -20px;
}
.sales-form .form-submit-border {
  text-align: center;
}
.sales-form input.btn[type='submit'] {
  margin: 58px auto 30px;
}
.two-col-asset-form {
  background-color: #f3f3f3;
}
.two-col-asset-form .middle {
  width: 100%;
  max-width: 810px;
  margin-left: auto;
  margin-right: auto;
}
.two-col-asset-form .page-header {
  text-align: center;
}
.two-col-asset-form .page-header h2 {
  margin: 0 0 48px;
}
.two-col-asset-form .left-col {
  padding-left: 0;
  padding-right: 20px;
}
.two-col-asset-form .left-col p {
  overflow: hidden;
}
.two-col-asset-form .left-col .asset-cover {
  float: right;
  max-width: 80px;
  margin-left: 30px;
}
.two-col-asset-form .form-group.field-employees,
.two-col-asset-form .form-group.field-zip {
  float: left;
  width: 48%;
}
.two-col-asset-form .form-group.field-employees {
  margin-right: 4%;
}
.two-col-asset-form .form-submit-border {
  padding-top: 18px;
}
.two-col-asset-form p.size14 {
  margin: 0;
  clear: both;
}
input[type='search'] {
  -webkit-appearance: none;
}
@media (min-width: 768px) {
  .form-inline .form-group {
    display: inline-block;
    margin-bottom: 0;
    vertical-align: middle;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-static {
    display: inline-block;
  }
  .form-inline .input-group {
    display: inline-table;
    vertical-align: middle;
  }
  .form-inline .input-group .form-control,
  .form-inline .input-group .input-group-addon,
  .form-inline .input-group .input-group-btn {
    width: auto;
  }
  .form-inline .input-group > .form-control {
    width: 100%;
  }
  .form-inline .control-label {
    margin-bottom: 0;
    vertical-align: middle;
  }
  .form-inline .checkbox,
  .form-inline .radio {
    display: inline-block;
    margin-top: 0;
    margin-bottom: 0;
    vertical-align: middle;
  }
  .form-inline .checkbox input[type='checkbox'],
  .form-inline .radio input[type='radio'] {
    position: relative;
    margin-left: 0;
  }
  .form-inline .has-feedback .form-control-feedback {
    top: 0;
  }
}
@media (max-width: 767px) {
  input:last-of-type,
  select:last-of-type {
    margin-bottom: 22px;
  }
  input[type='checkbox'] {
    margin-top: 4px !important;
  }
  .form-group,
  .validation-summary-errors .list-group {
    margin-bottom: 20px;
  }
  .quote-form .form-group.field-employees,
  .quote-form .form-group.field-zip {
    float: left;
    width: 48%;
  }
  .quote-form .form-group.field-zip {
    margin-right: 0;
  }
  .quote-form .form-group.field-country {
    overflow: hidden;
  }
  .quote-form input.btn[type='submit'] {
    margin-top: 38px;
    margin-bottom: 20px;
  }
  .sales-form .form-group.field-employees,
  .sales-form .form-group.field-zip {
    float: left;
    width: 48%;
  }
  .sales-form .form-group.field-country {
    overflow: hidden;
  }
  .sales-form input.btn[type='submit'] {
    margin-top: 38px;
    margin-bottom: 20px;
  }
  .two-col-asset-form .page-header h2 {
    margin: 0 0 38px;
  }
  .two-col-asset-form .form-group:not(.field-zip) {
    clear: both;
  }
  .two-col-asset-form .left-col {
    padding-right: 0;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .form-group,
  .validation-summary-errors .list-group,
  input:last-of-type,
  select:last-of-type {
    margin-bottom: 24px;
  }
  .quote-form input.btn[type='submit'],
  .sales-form input.btn[type='submit'] {
    margin-top: 48px;
    margin-bottom: 24px;
  }
}
@media (min-width: 768px) {
  .form-group.field-comply .checkbox,
  .form-group.field-comply span.help-block {
    max-width: 340px;
    margin-left: auto;
    margin-right: auto;
  }
  .quote-form .form-group {
    float: left;
    width: 48%;
  }
  .quote-form .form-group.odd {
    margin-right: 4%;
  }
  .quote-form .form-group.field-employees,
  .quote-form .form-group.field-zip {
    width: 22%;
  }
  .quote-form .form-group.field-client,
  .quote-form .form-group.field-select,
  .quote-form .form-group.field-zip {
    margin-right: 0;
  }
  .quote-form .form-group.field-client {
    padding-top: 2px;
  }
  .quote-form .form-group.field-country {
    margin-right: 4%;
  }
  .sales-form .form-group {
    float: left;
    width: 48%;
  }
  .sales-form .form-group.field-interest,
  .sales-form .form-group.odd {
    margin-right: 4%;
  }
  .sales-form .form-group.field-employees,
  .sales-form .form-group.field-zip {
    width: 22%;
  }
  .sales-form .form-group.field-client {
    padding-top: 2px;
  }
  .sales-form .form-group.field-country,
  .sales-form .form-group.field-zip {
    margin-right: 0;
  }
  .two-col-asset-form .left-col {
    padding-right: 30px;
  }
  .two-col-asset-form .left-col p:last-child {
    margin: 0;
  }
  .two-col-asset-form .field-col {
    float: right;
    width: 50%;
    padding-left: 2%;
  }
  .two-col-asset-form .form-group.field-client label.control-label:first-of-type {
    display: inline-block;
    margin-right: 15px;
  }
  .two-col-asset-form .form-group:not(.field-zip) {
    clear: left;
  }
}
@media (min-width: 992px) {
  input:last-of-type,
  select:last-of-type {
    margin-bottom: 28px;
  }
  .form-position-md {
    position: relative;
  }
  .form-position-md .hero-form-md {
    position: absolute;
    width: 100%;
    top: 30px;
  }
  .form-position-md .hero-form-md .middle {
    width: 100%;
    max-width: 1160px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    float: none !important;
    position: relative;
  }
  .form-position-md .hero-form-md .form-container {
    position: absolute;
    top: 0;
    right: 15px;
  }
  .form-position-md .hero-form-md.guidebook-form .form-container {
    width: 420px;
    padding: 30px 35px;
    border: 1px solid #c9c9c9;
  }
  .form-position-md .hero-form-md.guidebook-form .form-group.field-comply .checkbox,
  .form-position-md .hero-form-md.guidebook-form .form-group.field-comply span.help-block {
    max-width: none;
  }
}
.global-header {
  z-index: 1000;
}
.global-header .header-wrap {
  width: 100%;
  background-color: #fff;
  z-index: 1001;
  position: relative;
}
.global-header .header-wrap.sticky {
  transition: box-shadow 0.2s ease, background-color 0.2s ease;
  box-shadow: 10px 20px 30px 0 #0703301f;
  background-color: #fff;
  position: fixed;
  right: 0;
  left: 0;
  top: 0;
}
.global-header .header-wrap.sticky .btn.btn-primary.bg-purple-2F0B9E {
  background: #cd0058 !important;
}
.global-header .header-wrap.sticky .sticky-promo {
  color: #fff;
  background: #121c4e;
  display: block;
  padding: 30px 0;
  text-align: center;
  height: 80px;
}
.global-header .header-wrap #sticky-cta,
.global-header .header-wrap #sticky-cta.sticky,
.global-header .header-wrap .sticky-promo {
  display: none;
}
.global-header .header-wrap #nav-link-wrap {
  width: 600px;
}

/* Permanent Sticky*/
.global-header .header-wrap.perm-sticky .btn.btn-primary.bg-purple-2F0B9E {
  background: #cd0058 !important;
}
.global-header .header-wrap.perm-sticky .sticky-promo {
  color: #fff;
  background: #121c4e;
  display: block;
  padding: 30px 0;
  text-align: center;
  height: 80px;
}
@media (min-width: 768px) {
  main {
    margin-top: 50px;
  }
  .global-header .nav-bar {
    order: 1;
  }
}
/* end perm sticky */

.global-header .nav-bar {
  font-size: 14px;
  height: 100px;
  padding: 0 15px;
  margin: 0 auto;
  display: flex;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  align-items: center;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
}
.global-header .nav-bar .img-logo {
  width: 90px;
}
.global-header .nav-bar .nav-links {
  margin: 0;
}
.global-header .nav-bar .nav-links > li {
  display: inline-block;
  padding: 40px 0 40px 40px;
}
.global-header .nav-bar .nav-links > li a,
.global-header .nav-bar .nav-links > li span {
  font-family: Specter_bold, Arial, sans-serif;
  display: inline-block;
  vertical-align: middle;
  text-transform: uppercase;
  position: relative;
  text-decoration: none;
  cursor: pointer;
}

.global-header .nav-bar .nav-links > li a.current {
  color: #4300d5;
}
@media (min-width: 991px) {
  .global-header .nav-bar .nav-links > li a.current::before {
    content: '';
    position: absolute;
    top: -28px;
    display: none;
    width: 100%;
    height: 3px;
    background-color: #26328c;
  }
}
.global-header .nav-bar .nav-links > li a:active,
.global-header .nav-bar .nav-links > li a:hover,
.global-header .nav-bar .nav-links > li span:hover {
  color: #7b7c7e;
  text-decoration: none;
}
.global-header .nav-bar .nav-links > li > div.dropmenu-content {
  display: none;
  position: absolute;
  background-color: #fff;
  min-width: 125px;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
  z-index: 1;
  left: 28px;
  padding-top: 20px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  padding-bottom: 20px;
}
.global-header .nav-bar .nav-links > li > div.dropmenu-content.show {
  display: block;
}
.global-header .nav-bar .nav-links > li > div.dropmenu-content a {
  display: block;
  font-family: Specter_regular, Arial, sans-serif;
  font-size: 16px;
  text-transform: inherit;
  padding: 10px 0 10px 14px;
}
.global-header .nav-bar .nav-links > li > div.dropmenu-content a:hover {
  background-color: #ecfaff;
  color: #121c4e;
}
@media (max-width: 991px) {
  .guidebook-form .form-container {
    border-top: 1px solid #c9c9c9;
  }
  .global-header .nav-bar .nav-links > li a.has-sub-menu:after,
  .global-header .nav-bar .nav-links > li span.has-sub-menu:after {
    content: '';
    display: block;
    float: right;
    margin-top: 7px;
    height: 14px;
    width: 14px;
    background: url('./images/ui/icn-carat-down-ui2.svg') center center/contain no-repeat;
    -webkit-transition: all 0.2s linear;
    transition: all 0.2s linear;
  }
  .global-header .nav-bar .nav-links > li a.has-sub-menu.minus:after,
  .global-header .nav-bar .nav-links > li span.has-sub-menu.minus:after {
    background: url('./images/ui/icn-carat-up-ui2.svg') center center/contain no-repeat;
  }
  .global-header .nav-bar .nav-links > li > div.dropmenu-content {
    position: relative;
    box-shadow: none;
    padding-top: 10px;
    left: 0;
    padding-bottom: 0;
  }
  .nav-bar .nav-links a.current {
    font-weight: bolder;
    font-family: OpenSans_bold, Arial, sans-serif !important;
    color: #4300d5;
  }
  .global-header .header-wrap {
    background: #fff;
  }
  .global-header .header-wrap.sticky .sticky-promo {
    padding: 19px 0;
    text-align: center;
    height: 60px;
  }
  .global-header .header-wrap .nav-bar {
    max-height: 80px;
  }
  .global-header .header-wrap .nav-bar .img-logo {
    margin: 0 auto;
    width: 80px;
  }
  .global-header .header-wrap .nav-bar .header-phone a {
    font-size: 11px;
    line-height: 15px;
  }
  .global-header .header-wrap .nav-bar .hamburger-box {
    margin-top: auto;
  }
  .global-header .header-wrap .nav-bar .hamburger-box .hamburger-inner,
  .global-header .header-wrap .nav-bar .hamburger-box .hamburger-inner::after,
  .global-header .header-wrap .nav-bar .hamburger-box .hamburger-inner::before {
    background-color: #121c4e;
  }
  .global-header .header-wrap .nav-bar .nav-links {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    right: 70px;
    bottom: 0;
    margin: 0;
    padding-bottom: 40px;
    max-width: 100%;
    height: 100%;
    overflow-y: scroll;
    z-index: 1200;
    background-color: #fff;
  }
  .global-header .header-wrap .nav-bar .nav-links .close-icn {
    top: 50%;
    transform: translate(0, -50%);
  }
  .global-header .header-wrap .nav-bar .nav-link-wrap.active {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    position: fixed;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-tap-highlight-color: transparent;
    z-index: 2000;
  }
  .global-header .header-wrap #sticky-cta {
    display: none;
    border-radius: 0;
  }
  .global-header .header-wrap #sticky-cta.sticky {
    display: block;
    width: 100%;
    bottom: 0;
    position: fixed;
  }
  .global-header .nav-bar > .nav-link-wrap .nav-links > li {
    margin: 0;
    width: 100%;
    padding: 30px;
    border-bottom: 1px dashed #c2c2c2;
    font-size: 16px;
  }
  .global-header .nav-bar .nav-links > li > a,
  .global-header .nav-bar .nav-links > li > span {
    color: #26328c;
    padding: 0;
    display: inline-block;
    width: 100%;
  }
  .drop-container,
  .signin-panel {
    background: #fff;
    padding: 0;
    height: auto !important;
  }
  .drop-menu-trigger {
    position: relative;
  }
  .drop-menu-trigger::after {
    content: '';
    display: block;
    position: absolute;
    top: 21px;
    right: 20px;
    height: 16px;
    width: 16px;
    /* background: url(../assets/ui/icn-plus-blue.svg) center center/contain no-repeat; */
  }
  .drop-menu-trigger.open::after {
    /* background-image: url(../assets/ui/icn-minus-blue.svg); */
  }
  .drop-menu-trigger.open .drop-menu {
    height: auto !important;
    pointer-events: auto;
    display: inline-block;
  }
  .drop-menu-trigger > a:after {
    opacity: 0;
    display: none;
  }
  .drop-menu-trigger .drop-menu {
    height: 0 !important;
    display: none;
  }
  .drop-menu-trigger .drop-menu .drop-menu-links li a {
    padding: 0 0 16px;
  }
}
[data-whatintent='mouse'] :focus {
  outline: 0;
  box-shadow: none;
}
a {
  cursor: pointer;
  font-family: Specter_regular, Arial, sans-serif;
  color: #cd0058;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  text-decoration: none;
}
a.tweet-this {
  line-height: 17px;
  padding-left: 32px;
  display: inline-block;
  position: relative;
}
a.tweet-this::before {
  content: '';
  width: 23px;
  height: 21px;
  position: absolute;
  left: 0;
  top: -3px;
  /* background: url(../images/ui/social-media-sprite.png) -40px -76px; */
  display: block;
}
a:hover {
  text-decoration: underline;
}
a:active {
  color: #003451;
}
a.white:hover {
  color: #fff;
  background-color: transparent !important;
}
a.red-dc1e34:hover {
  color: #cf0a2c;
}
a:focus,
button:focus {
  outline: 0 !important;
}
.btn {
  display: inline-block;
  margin-bottom: 0;
  font-family: Specter_bold, Arial, sans-serif;
  text-transform: uppercase;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  width: min-content;
  cursor: pointer;
  background-image: none;
  border: none;
  padding: 12px 40px;
  font-size: 18px;
  line-height: 1.5;
  border-radius: 25px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
@media (max-width: 767px) {
  .global-header .header-wrap #nav-link-wrap {
    width: auto;
  }
  .btn {
    width: 100%;
    padding: 12px 0;
    font-size: 16px;
    line-height: 1.5;
    border-radius: 25px;
  }
}
.btn.active.focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn:active:focus,
.btn:focus {
  outline: -webkit-focus-ring-color auto 5px;
  outline-offset: -2px;
}
.btn span {
  vertical-align: middle;
}
.btn.focus,
.btn:focus,
.btn:hover {
  color: #cd0058;
  text-decoration: none;
}
.btn.active,
.btn:active {
  background-image: none;
  outline: 0;
}
.btn.disabled,
.btn[disabled],
fieldset[disabled] .btn {
  cursor: not-allowed;
  opacity: 0.65;
  -webkit-box-shadow: none;
  box-shadow: none;
}
a.btn.disabled,
fieldset[disabled] a.btn {
  pointer-events: none;
}
.btn-default,
.btn-default.focus,
.btn-default:focus {
  color: #cd0058;
  background-color: #fce3df;
  border-color: #fce3df;
}
.btn-default:hover {
  color: #cd0058;
  background-color: #fbd7d1;
  border-color: #fce3df;
}
.btn-default.active,
.btn-default:active,
.open > .dropdown-toggle.btn-default {
  color: #cd0058;
  background-color: #fbd7d1;
  background-image: none;
  border-color: #f7b2a7;
}
.btn-default.active.focus,
.btn-default.active:focus,
.btn-default.active:hover,
.btn-default:active.focus,
.btn-default:active:focus,
.btn-default:active:hover,
.open > .dropdown-toggle.btn-default.focus,
.open > .dropdown-toggle.btn-default:focus,
.open > .dropdown-toggle.btn-default:hover {
  color: #cd0058;
  background-color: #fce3df;
  border-color: #fce3df;
}
.btn-default.disabled.focus,
.btn-default.disabled:focus,
.btn-default.disabled:hover,
.btn-default[disabled].focus,
.btn-default[disabled]:focus,
.btn-default[disabled]:hover,
fieldset[disabled] .btn-default.focus,
fieldset[disabled] .btn-default:focus,
fieldset[disabled] .btn-default:hover {
  background-color: #fce3df;
  border-color: #fce3df;
}
.btn-default .badge {
  color: #fce3df;
  background-color: #cd0058;
}
.btn-primary,
.btn-primary.focus,
.btn-primary:focus {
  color: #fff;
  background-color: #cd0058;
  border-color: #c6095a;
}
.btn-primary:hover {
  color: #fff;
  background-color: #cf095e;
  border-color: #c6095a;
}
.btn-primary.active,
.btn-primary:active,
.open > .dropdown-toggle.btn-primary {
  color: #fff;
  background-color: #cf095e;
  background-image: none;
  border-color: #8b063f;
}
.btn-primary.active.focus,
.btn-primary.active:focus,
.btn-primary.active:hover,
.btn-primary:active.focus,
.btn-primary:active:focus,
.btn-primary:active:hover,
.open > .dropdown-toggle.btn-primary.focus,
.open > .dropdown-toggle.btn-primary:focus,
.open > .dropdown-toggle.btn-primary:hover {
  color: #fff;
  background-color: #cd0058;
  border-color: #c6095a;
}
.btn-primary.disabled.focus,
.btn-primary.disabled:focus,
.btn-primary.disabled:hover,
.btn-primary[disabled].focus,
.btn-primary[disabled]:focus,
.btn-primary[disabled]:hover,
fieldset[disabled] .btn-primary.focus,
fieldset[disabled] .btn-primary:focus,
fieldset[disabled] .btn-primary:hover {
  background-color: #cd0058;
  border-color: #c6095a;
}
.btn-primary .badge {
  color: #cd0058;
  background-color: #fff;
}
.btn-secondary,
.btn-secondary.focus,
.btn-secondary:focus {
  color: #fff;
  background-color: #121c4e;
  border-color: #0d1539;
}
.btn-secondary:hover {
  color: #fff;
  border-color: #0d1539;
}
.btn-secondary.active,
.btn-secondary:active,
.open > .dropdown-toggle.btn-secondary {
  color: #fff;
  background-color: #0f1842;
  background-image: none;
  border-color: #020308;
}
.btn-secondary.active.focus,
.btn-secondary.active:focus,
.btn-secondary.active:hover,
.btn-secondary:active.focus,
.btn-secondary:active:focus,
.btn-secondary:active:hover,
.open > .dropdown-toggle.btn-secondary.focus,
.open > .dropdown-toggle.btn-secondary:focus,
.open > .dropdown-toggle.btn-secondary:hover {
  color: #fff;
  background-color: #121c4e;
  border-color: #0d1539;
}
.btn-secondary.disabled.focus,
.btn-secondary.disabled:focus,
.btn-secondary.disabled:hover,
.btn-secondary[disabled].focus,
.btn-secondary[disabled]:focus,
.btn-secondary[disabled]:hover,
fieldset[disabled] .btn-secondary.focus,
fieldset[disabled] .btn-secondary:focus,
fieldset[disabled] .btn-secondary:hover {
  background-color: #121c4e;
  border-color: #0d1539;
}
.btn-secondary .badge {
  color: #121c4e;
  background-color: #fff;
}
.btn-secondary:active,
.btn-secondary:hover {
  background-color: #40486f;
}
.btn-success,
.btn-success.focus,
.btn-success:focus {
  color: #fff;
  background-color: #41d4ff;
  border-color: #27ceff;
}
.btn-success:hover {
  color: #fff;
  background-color: #32d1ff;
  border-color: #27ceff;
}
.btn-success.active,
.btn-success:active,
.open > .dropdown-toggle.btn-success {
  color: #fff;
  background-color: #32d1ff;
  background-image: none;
  border-color: #00b5e9;
}
.btn-success.active.focus,
.btn-success.active:focus,
.btn-success.active:hover,
.btn-success:active.focus,
.btn-success:active:focus,
.btn-success:active:hover,
.open > .dropdown-toggle.btn-success.focus,
.open > .dropdown-toggle.btn-success:focus,
.open > .dropdown-toggle.btn-success:hover {
  color: #fff;
  background-color: #41d4ff;
  border-color: #27ceff;
}
.btn-success.disabled.focus,
.btn-success.disabled:focus,
.btn-success.disabled:hover,
.btn-success[disabled].focus,
.btn-success[disabled]:focus,
.btn-success[disabled]:hover,
fieldset[disabled] .btn-success.focus,
fieldset[disabled] .btn-success:focus,
fieldset[disabled] .btn-success:hover {
  background-color: #41d4ff;
  border-color: #27ceff;
}
.btn-success .badge {
  color: #41d4ff;
  background-color: #fff;
}
.btn-info,
.btn-info.focus,
.btn-info:focus {
  color: #fff;
  background-color: #41d4ff;
  border-color: #27ceff;
}
.btn-info:hover {
  color: #fff;
  background-color: #32d1ff;
  border-color: #27ceff;
}
.btn-info.active,
.btn-info:active,
.open > .dropdown-toggle.btn-info {
  color: #fff;
  background-color: #32d1ff;
  background-image: none;
  border-color: #00b5e9;
}
.btn-info.active.focus,
.btn-info.active:focus,
.btn-info.active:hover,
.btn-info:active.focus,
.btn-info:active:focus,
.btn-info:active:hover,
.open > .dropdown-toggle.btn-info.focus,
.open > .dropdown-toggle.btn-info:focus,
.open > .dropdown-toggle.btn-info:hover {
  color: #fff;
  background-color: #41d4ff;
  border-color: #27ceff;
}
.btn-info.disabled.focus,
.btn-info.disabled:focus,
.btn-info.disabled:hover,
.btn-info[disabled].focus,
.btn-info[disabled]:focus,
.btn-info[disabled]:hover,
fieldset[disabled] .btn-info.focus,
fieldset[disabled] .btn-info:focus,
fieldset[disabled] .btn-info:hover {
  background-color: #41d4ff;
  border-color: #27ceff;
}
.btn-info .badge {
  color: #41d4ff;
  background-color: #fff;
}
.btn-warning,
.btn-warning.focus,
.btn-warning:focus {
  color: #fff;
  background-color: red;
  border-color: #e60000;
}
.btn-warning:hover {
  color: #fff;
  background-color: #f00000;
  border-color: #e60000;
}
.btn-warning.active,
.btn-warning:active,
.open > .dropdown-toggle.btn-warning {
  color: #fff;
  background-color: #f00000;
  background-image: none;
  border-color: #a80000;
}
.btn-warning.active.focus,
.btn-warning.active:focus,
.btn-warning.active:hover,
.btn-warning:active.focus,
.btn-warning:active:focus,
.btn-warning:active:hover,
.open > .dropdown-toggle.btn-warning.focus,
.open > .dropdown-toggle.btn-warning:focus,
.open > .dropdown-toggle.btn-warning:hover {
  color: #fff;
  background-color: red;
  border-color: #e60000;
}
.btn-warning.disabled.focus,
.btn-warning.disabled:focus,
.btn-warning.disabled:hover,
.btn-warning[disabled].focus,
.btn-warning[disabled]:focus,
.btn-warning[disabled]:hover,
fieldset[disabled] .btn-warning.focus,
fieldset[disabled] .btn-warning:focus,
fieldset[disabled] .btn-warning:hover {
  background-color: red;
  border-color: #e60000;
}
.btn-warning .badge {
  color: red;
  background-color: #fff;
}
.btn-danger {
  color: #fff;
  background-color: #fff1f1;
  border-color: #ffd8d8;
}
.btn-danger.focus,
.btn-danger:focus {
  color: #fff;
  background-color: #fff1f1;
  border-color: #ffd7d7;
}
.btn-danger:hover {
  color: #fff;
  background-color: #ffe2e2;
  border-color: #ffd7d7;
}
.btn-danger.active,
.btn-danger:active,
.open > .dropdown-toggle.btn-danger {
  color: #fff;
  background-color: #ffe2e2;
  background-image: none;
  border-color: #ff9a9a;
}
.btn-danger.active.focus,
.btn-danger.active:focus,
.btn-danger.active:hover,
.btn-danger:active.focus,
.btn-danger:active:focus,
.btn-danger:active:hover,
.open > .dropdown-toggle.btn-danger.focus,
.open > .dropdown-toggle.btn-danger:focus,
.open > .dropdown-toggle.btn-danger:hover {
  color: #fff;
  background-color: #fff1f1;
  border-color: #ffd7d7;
}
.btn-danger.disabled.focus,
.btn-danger.disabled:focus,
.btn-danger.disabled:hover,
.btn-danger[disabled].focus,
.btn-danger[disabled]:focus,
.btn-danger[disabled]:hover,
fieldset[disabled] .btn-danger.focus,
fieldset[disabled] .btn-danger:focus,
fieldset[disabled] .btn-danger:hover {
  background-color: #fff1f1;
  border-color: #ffd8d8;
}
.btn-danger .badge {
  color: #fff1f1;
  background-color: #fff;
}
.btn-link {
  font-weight: 400;
  color: #121c4e;
  border-radius: 0;
}
.btn-link,
.btn-link.active,
.btn-link:active,
.btn-link[disabled],
fieldset[disabled] .btn-link {
  background-color: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn-link,
.btn-link:active,
.btn-link:focus,
.btn-link:hover {
  border-color: transparent;
}
.btn-link:focus,
.btn-link:hover {
  color: inherit;
  text-decoration: none;
  background-color: transparent;
}
.btn-link[disabled]:focus,
.btn-link[disabled]:hover,
fieldset[disabled] .btn-link:focus,
fieldset[disabled] .btn-link:hover {
  color: #7b7c7e;
  text-decoration: none;
}
.btn-lg {
  padding: 25px 80px;
  font-size: 18px;
  line-height: 1.3;
  border-radius: 58px;
}
@media (max-width: 767px) {
  .btn-lg {
    padding: 12px 0;
    font-size: 16px;
    line-height: 1.5;
    border-radius: 25px;
  }
}
.btn-sm {
  padding: 14px 25px;
  font-size: 14px;
  line-height: 1.3;
  border-radius: 25px;
}
.btn-xs {
  padding: 1px 5px;
  font-size: 14px;
  line-height: 1.3;
  border-radius: 25px;
}
.btn-block {
  display: block;
  width: 100%;
}
.btn-block + .btn-block {
  margin-top: 5px;
}
input[type='button'].btn-block,
input[type='reset'].btn-block,
input[type='submit'].btn-block {
  width: 100%;
}
.hero-wrap {
  position: relative;
}
.hero-wrap .card-box {
  position: relative;
  height: auto;
  padding: 20px 40px;
}
.hero-wrap .card-box .limited-offer {
  background: #e6e6e6;
  font-size: 14px;
  padding: 6px 30px;
  position: relative;
  top: -17px;
  left: 0;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.hero-wrap .hero-bg {
  width: 879px;
  height: 620px;
  background: url('./images/home-global/0_home_hero_chatbubble.svg') top center no-repeat;
  position: absolute;
  top: -100px;
  right: -19%;
  display: inline-block;
}
@media (max-width: 767px) {
  /* .hero-wrap {
    position: relative;
    overflow: hidden;
  } */
  .hero-wrap .hero-bg {
    background: url('./images/home-global/0_home_hero_chatbubble_full.svg') center center/contain
      no-repeat;
    top: 33%;
    right: -18%;
    height: 621px;
    width: 574px;
  }
}
.lity {
  z-index: 9990;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  white-space: nowrap;
  background: rgba(0, 0, 0, 0.7);
  outline: 0 !important;
  opacity: 0;
  -webkit-transition: opacity 0.3s ease;
  -o-transition: opacity 0.3s ease;
  transition: opacity 0.3s ease;
}
.lity.lity-opened {
  opacity: 1;
}
.lity.lity-closed {
  opacity: 0;
}
.lity * {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.lity-wrap {
  z-index: 9990;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  text-align: center;
  outline: 0 !important;
}
.lity-wrap::before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  margin-right: -0.25em;
}
.lity-loader {
  z-index: 9991;
  color: #fff;
  position: absolute;
  top: 50%;
  margin-top: -0.8em;
  width: 100%;
  text-align: center;
  font-size: 14px;
  font-family: Arial, Helvetica, sans-serif;
  opacity: 0;
  -webkit-transition: opacity 0.3s ease;
  -o-transition: opacity 0.3s ease;
  transition: opacity 0.3s ease;
}
.lity-loading .lity-loader {
  opacity: 1;
}
.lity-container {
  z-index: 9992;
  position: relative;
  text-align: left;
  vertical-align: middle;
  display: inline-block;
  white-space: normal;
  max-width: 100%;
  max-height: 100%;
  outline: 0 !important;
}
.lity-content {
  z-index: 9993;
  width: 100%;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
  -webkit-transition: transform 0.3s ease;
  -ms-transition: transform 0.3s ease;
  -o-transition: transform 0.3s ease;
  transition: transform 0.3s ease;
}
.lity-content::after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  display: block;
  right: 0;
  width: auto;
  height: auto;
  z-index: -1;
  -webkit-box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
}
.lity-closed .lity-content,
.lity-loading .lity-content {
  -webkit-transform: scale(0.8);
  -ms-transform: scale(0.8);
  -o-transform: scale(0.8);
  transform: scale(0.8);
}
.lity-close {
  z-index: 9994;
  width: 35px;
  height: 35px;
  position: fixed;
  right: 0;
  top: 0;
  -webkit-appearance: none;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  padding: 0;
  color: #fff;
  font-style: normal;
  font-size: 35px;
  line-height: 35px;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
  border: 0;
  background: 0 0;
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.lity-close::-moz-focus-inner {
  border: 0;
  padding: 0;
}
.lity-close:active,
.lity-close:focus,
.lity-close:hover,
.lity-close:visited {
  text-decoration: none;
  text-align: center;
  padding: 0;
  color: #fff;
  font-style: normal;
  font-size: 35px;
  line-height: 35px;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
  border: 0;
  background: 0 0;
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.lity-close:active {
  top: 1px;
}
.lity-image img {
  max-width: 100%;
  display: block;
  line-height: 0;
  border: 0;
}
.lity-facebookvideo .lity-container,
.lity-googlemaps .lity-container,
.lity-iframe .lity-container,
.lity-vimeo .lity-container,
.lity-youtube .lity-container {
  width: 100%;
  max-width: 964px;
}
.lity-iframe-container {
  width: 100%;
  height: 0;
  padding-top: 56.25%;
  overflow: auto;
  pointer-events: auto;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-overflow-scrolling: touch;
}
.lity-iframe-container iframe {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  background: #000;
}
.lity-hide {
  display: none;
}
.dont-break-out {
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-all;
  word-break: break-word;
}
.close-icn {
  position: absolute;
  cursor: pointer;
  top: 30px;
  right: 30px;
  width: 30px;
  height: 30px;
  background: url('./images/ui/icn_modal_close_default.svg') center center/contain no-repeat;
}
.close-icn:hover {
  background: url('./images/ui/icn_modal_close_hover.svg') center center/contain no-repeat;
}
@media (max-width: 767px) {
  .close-icn {
    width: 20px;
    height: 20px;
  }
}
.terms {
  color: #7b7c7e;
  text-decoration: underline;
}
.terms:active,
.terms:hover {
  text-decoration: none;
}

.terms-no-underline {
  color: #7b7c7e;
  text-decoration: none;
}

.terms-no-underline:active,
.terms-no-underline:hover {
  text-decoration: none;
}

.lotty-player-wrap {
  width: 87%;
  margin: 0 auto;
}
@media (max-width: 767px) {
  .lotty-player-wrap {
    width: 100%;
  }
}
.pricing-splash .h1 {
  color: #4300d5;
  margin-right: -10px;
}
.app-links {
  list-style: none;
  margin-bottom: 0;
}

/* .app-links {
  position: relative;
  left: 25%;
} */

.app-links li {
  display: inline-block;
  vertical-align: middle;
  padding: 0 10px;
}
.app-links li img {
  margin-right: 5px;
  margin-top: -6px;
}
.app-links li:nth-of-type(1) {
  border-right: 1px solid #121c4e;
}
.play-btn-icon {
  width: 20px;
  height: 20px;
  line-height: 1.5;
  display: inline-block;
  position: relative;
  top: -3px;
  right: -20px;
  background: url('./images/ui/icn_button_play_video_mini_white.svg') center center/contain
    no-repeat;
}
.video-play-btn {
  height: 80px;
  width: 80px;
  line-height: 1.5;
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: url('./images/ui/button_video_play_default.svg') center center/contain no-repeat;
}
.video-play-btn:hover {
  background: url('./images/ui/button_video_play_hover.svg') center center/contain no-repeat;
}
.testimonials {
  position: relative;
  min-height: 37rem;
  padding: 111px 0 120px;
  background: url('./images/home-global/testimonial_bg_organic_shape_white.svg') center
    center/contain no-repeat;
}
@media (max-width: 767px) {
  .video-play-btn {
    height: 32px;
    width: 32px;
  }
  .testimonials {
    background-size: 300%;
    background-position-y: 0;
    width: auto;
  }
}
.quote-bubble,
.sem-quote-bubble {
  position: absolute;
  top: -9%;
  left: -3%;
  background: url('./images/home-global/testimonial_chat_bubble_purple.svg') center center/contain
    no-repeat;
  text-align: center;
  width: 270px;
  height: 290px;
  padding: 71px 0 0;
}
.sem-quote-bubble {
  padding-top: 40px;
}
/* @media (max-width: 1024px) {
  .quote-bubble,
  .sem-quote-bubble {
    left: auto;
    top: -19%;
  }
} */

@media (max-width: 767px) {
  .quote-bubble,
  .sem-quote-bubble {
    position: relative;
    padding: 61px 0 0;
    margin: 0 auto 30px;
    left: auto;
    width: 240px;
    height: 260px;
    font-size: 26px;
    line-height: 36px;
  }
}

@media (min-width: 1500px) {
  .quote-bubble,
  .sem-quote-bubble {
    position: absolute;
    top: -9%;
    left: -3%;
    text-align: center;
    width: 270px;
    height: 290px;
  }
}

.limited-offer {
  position: absolute;
  top: 0;
  left: 23%;
  background: url('./images/pricing/2_pricing_limited_time_offer.svg') center center/contain
    no-repeat;
  text-align: center;
  width: 180px;
  height: 101px;
}
@media (max-width: 767px) {
  .limited-offer {
    background: url('./images/pricing/limited_offer_mobile.png') center center/contain no-repeat;
    width: 180px;
    height: 80px;
  }
}
.white-bg-lg {
  background: url('./images/home-global/0_home_run_payroll_bg_organic_shape_white_space.svg') center
    no-repeat;
  background-position-y: top;
  position: absolute;
  z-index: -1;
  width: 100vw;
  height: 2958px;
  aspect-ratio: 1537/2958;
}

.white-bg-lg-flipped {
  background: url('./images/home-global/5_why_roll_bg_organic_shape_white.svg') center top 65px
    no-repeat;
  background-position-y: 4.5rem;
  position: absolute;
  z-index: -1;
  width: 100vw;
  height: 2958px;
  aspect-ratio: 1537/2958;
}

.grey-bg-lg {
  background: url('./imgs/roll_sem_lp_light-gray-bg-shape.svg') center top 65px no-repeat;
  background-position-y: top;
  position: absolute;
  z-index: -1;
  width: 100vw;
  height: 2958px;
  aspect-ratio: 1537/2958;
}

@media (max-width: 767px) {
  .white-bg-lg {
    background: url('./images/home-global/0_home_run_payroll_bg_organic_shape_white.svg')
      center/cover no-repeat;
    padding-bottom: 90px;
  }
}
.white-bg2-lg {
  background: url('./images/why-roll/5_why_roll_bg_organic_shape_white.svg') center top 280px
    no-repeat;
}
@media (max-width: 767px) {
  .white-bg2-lg {
    background-size: auto 87%;
    background-position-y: 280px;
  }
}
.purple-bg-features {
  background: url('./images/features/1_features_hero_bg_shape_purple.svg') center/contain no-repeat;
  height: 96px;
  width: 553px;
  top: -45px;
  right: -86px;
  position: absolute;
  display: block;
}
@media (max-width: 767px) {
  .purple-bg-features {
    width: 320px;
    height: 58px;
    top: -41px;
    right: -115px;
  }
}
.blue-bg-features {
  background: url('./images/features/1_features_hero_bg_shape_blue_full.svg') center/contain
    no-repeat;
  height: 383px;
  width: 434px;
  top: 164px;
  left: -148px;
  position: absolute;
  display: block;
  z-index: -1;
}
.white-bg-organic {
  background: url('./images/home/bg_organic_shape_white.svg') center top 0 no-repeat;
}
@media (max-width: 767px) {
  .blue-bg-features {
    height: 365px;
    width: 414px;
    top: 238px;
    left: -302px;
  }
  .white-bg-organic {
    background-size: cover;
  }
}
.purple-bg-bottom {
  background: url('./images/home-global/ready_to_roll_bg_organic_shape_purple.svg') right bottom
    no-repeat;
  padding-bottom: 175px;
}
@media (max-width: 767px) {
  .purple-bg-bottom {
    padding-bottom: 80px;
    background-position: left 60px bottom;
  }
}
.light-blue-bg {
  top: 62%;
  left: -34%;
  position: absolute;
  display: block;
  height: 289px;
  width: 398px;
  background: url('./images/home-global/0_home_hero_organic_shape_blue.svg') right bottom no-repeat;
  padding-bottom: 175px;
}
.pricing-bg-hero-blue {
  top: 32px;
  left: -140px;
  position: absolute;
  display: block;
  height: 395px;
  width: 348px;
  background: url('./images/pricing/2_pricing_hero_bg_blue_full.svg') right bottom/contain no-repeat;
}
@media (max-width: 767px) {
  .pricing-bg-hero-blue {
    top: 32px;
    left: -140px;
    position: absolute;
    display: block;
    height: 280px;
    width: 221px;
    background: url('./images/pricing/20pricingherobgblue.svg') right bottom/contain no-repeat;
  }
}
.pricing-bg-hero-purple {
  top: -125px;
  right: -262px;
  position: absolute;
  display: block;
  height: 360px;
  width: 600px;
  background: url('./images/pricing/2_pricing_hero_bg_purple.svg') right bottom/contain no-repeat;
}
@media (max-width: 767px) {
  .pricing-bg-hero-purple {
    background: url('./images/pricing/20pricingherobgpurple.svg') center center/contain no-repeat;
    height: 132px;
    width: 160px;
    right: -40px;
    top: -40px;
  }
}

.card-img-business {
  position: relative;
  margin-bottom: -63px;
  right: -1px;
}
.sem-bg-footer {
  background: url('./images/sem/roll_sem_lp_purple-bg-shape.svg') center bottom no-repeat;
  background-size: 100%;
}

.sem-bg-large {
  background: url('./images/sem/roll_sem_lp_light-gray-bg-shape.svg') bottom no-repeat;
}

@media (min-width: 991px) {
  .sem-hero .card-box {
    width: 350px;
    position: relative;
    top: 52%;
    margin-left: auto;
    margin-right: 0px;
  }
}
@media (max-width: 767px) {
  .card-img-business {
    margin-bottom: -42px;
    right: -24px;
  }
  .sem-hero {
    background: 0 0;
  }
  .sem-hero h1 {
    font-size: 35px;
  }
}
.sem-testimonials {
  position: relative;
  min-height: 760px;
  padding: 100px 40px;
  background: url('./images/home-global/testimonial_bg_organic_shape_white.svg') center
    center/contain no-repeat;
}
@media (max-width: 767px) {
  .sem-testimonials {
    padding: 0 0 30px;
    background-size: auto 81%;
    background-position: bottom;
    width: auto;
  }
  .sem-testimonials h3.h1 {
    font-size: 30px;
  }
  .sem-features .card-box {
    min-height: 472px;
  }
}

#roll-link-container {
  background-image: url('./images/modal-try-free/x_modal_tryforfree_bg_purplechat.svg');
  background-position: left bottom;
  background-repeat: no-repeat;
  background-size: 60%;
}

#roll-link-container.no-qr-code .left-panel {
  display:none;
}
#roll-link-container.no-qr-code .right-panel {
  float: none;
  margin: auto;
}

#roll-demo-container {
  background-image: url('./images/modal-demo/x_modal_demo_bg_purplechat.svg');
  background-position: right bottom;
  background-repeat: no-repeat;
  background-size: 60%;
}
#roll-demo-container .card-box {
  background-image: url('./images/modal-demo/x_modal_demo_bg_blue_shape.svg');
  background-position: left top;
  background-repeat: no-repeat;
}
#app-store-stars-a .bb-stars,
#app-store-stars-c .bb-stars {
  border-bottom: 1px solid #f7f6f4;
}
#app-store-stars-a img.stars,
#app-store-stars-c img.stars {
  width: 100%;
  max-width: 98px;
  vertical-align: middle;
  margin-top: -4px;
  margin-right: 8px;
}
@media (max-width: 767px) {
  .sem-quote-bubble {
    right: 0;
    top: 0;
    width: 150px;
    height: 150px;
    margin: 0 auto;
    padding: 0;
  }
  .sem-quote-bubble span {
    font-size: 16px;
    display: block;
    line-height: 1.4;
    padding: 34px 0;
    margin: 0;
  }
  #roll-demo-container {
    background-image: url('./images/modal-try-free/x_modal_tryforfree_bg_purplechat.svg');
    background-position: bottom center;
    background-size: auto;
  }
  #roll-demo-container .card-box {
    background-image: url('./images/modal-demo/x_modal_demo_bg_blue_shape_full.svg');
    background-position: bottom center;
  }
  #app-store-stars-a img.stars,
  #app-store-stars-c img.stars {
    max-width: 100%;
  }
  .modal-form.segmenter.evo .form-container {
    top: calc(50% - 242px);
  }
}
.overflow-freeze {
  overflow: hidden;
}
.modal-form {
  pointer-events: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  z-index: 11000;
  overflow: auto;
  background: rgba(0, 0, 0, 0.7);
  opacity: 0;
  -webkit-transition: opacity 0.3s ease;
  -o-transition: opacity 0.3s ease;
  transition: opacity 0.3s ease;
}
.modal-form.open {
  opacity: 1;
  width: 100%;
  height: 100%;
  pointer-events: inherit;
}
.modal-form.open .form-container {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}
.modal-form.segmenter.evo .form-container {
  max-width: 480px;
}
@media (min-width: 511px) {
  .modal-form.segmenter.evo .form-container {
    top: calc(50% - 238px);
    left: calc(50% - 240px);
  }
}
.modal-form.segmenter.evo .form-container .btn {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
}
.modal-form.segmenter .form-container {
  top: calc(50% - 157px);
}
.modal-form.segmenter .form-container .img-logo {
  width: 90px;
  height: auto;
  margin-bottom: 24px;
}
.modal-form.segmenter .form-container p:first-of-type {
  margin: 0 0 12px;
}
.modal-form.segmenter .form-container p:last-of-type {
  margin: 0 0 30px;
}
.modal-form.segmenter .form-container .btn {
  width: 100%;
  max-width: 210px;
}
.modal-form .middle {
  position: relative;
  width: 100%;
  height: 100%;
}
.modal-form .form-container {
  position: absolute;
  width: 100%;
  height: 100%;
  max-height: 850px;
  background-color: #f7f6f4;
  max-width: 1160px;
  padding: 80px;
  border-radius: 30px;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  overflow-y: scroll;
  -webkit-transform: scale(0.8);
  -ms-transform: scale(0.8);
  -o-transform: scale(0.8);
  transform: scale(0.8);
  -webkit-transition: transform 0.3s ease;
  -ms-transition: transform 0.3s ease;
  -o-transition: transform 0.3s ease;
  transition: transform 0.3s ease;
}
@media (max-width: 767px) {
  .modal-form.segmenter .form-container {
    top: calc(50% - 203px);
    bottom: auto;
  }
  .modal-form .form-container {
    max-width: none;
    width: auto;
    padding: 40px 15px;
    border-radius: 0;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow-y: scroll;
  }
  .modal-info.segmenter.evo .form-container {
    top: calc(50% - 242px);
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .modal-form.segmenter .form-container {
    top: calc(50% - 155px);
  }
  .modal-form .form-container {
    max-width: 600px;
    padding: 48px 25px;
    top: 80px;
    left: calc(50% - 300px);
  }
}
.modal-form .page-header p {
  font-family: Specter_bold, Arial, sans-serif;
  font-size: 22px;
  margin: 0 0 38px !important;
}
.modal-form .row {
  margin: 0;
}
.modal-form .row .col-md-12 {
  width: 100% !important;
  padding: 0;
}
.modal-form legend {
  display: none;
}
.modal-form fieldset .form-group:last-child {
  margin-bottom: 0;
}
.modal-form label {
  text-align: left;
}
.modal-form .form-group,
.modal-form .form-submit-border {
  width: 100%;
  display: inline-block;
  margin-right: auto;
  margin-left: auto;
}
.modal-form .form-submit-border {
  float: none;
  display: inline-block;
}
.modal-form .form-group {
  margin-bottom: 7px;
}
.modal-form .form-group.field-contact {
  text-align: left;
}
.modal-form .form-group.field-contact label {
  position: relative;
  display: block;
  max-width: 340px;
  font-size: 16px;
  padding-left: 24px;
}
.modal-form .form-group.field-contact label input[type='checkbox'] {
  position: absolute;
  left: 0;
  top: 5px;
}
.modal-form .form-group.field-client {
  margin-bottom: 0;
  text-align: left;
}
.modal-form .form-group.field-client label {
  font-size: 16px;
}
.modal-form .form-group.field-client label.control-label:first-of-type {
  display: inline-block;
  margin-right: 25px;
}
.modal-form .form-group.field-client label.control-label:last-of-type {
  margin-left: 15px;
}
.modal-form .form-group.field-comply {
  margin-top: 15px;
  margin-bottom: 0;
}
.modal-form .selectric-form-control {
  text-align: left;
}
.modal-form p:last-child {
  margin: 0;
}
.modal-info {
  pointer-events: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  z-index: 11000;
  overflow: auto;
  background: rgba(0, 0, 0, 0.7);
  opacity: 0;
  -webkit-transition: opacity 0.3s ease;
  -o-transition: opacity 0.3s ease;
  transition: opacity 0.3s ease;
}
.modal-info.segmenter.evo .form-container {
  max-width: 480px;
}
@media (min-width: 511px) {
  .modal-info.segmenter.evo .form-container {
    top: calc(50% - 238px);
    left: calc(50% - 240px);
  }
}
.modal-info.segmenter.evo .form-container .btn {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
}
.modal-info.segmenter .form-container {
  top: calc(50% - 157px);
}
.modal-info.segmenter .form-container .img-logo {
  width: 90px;
  height: auto;
  margin-bottom: 24px;
}
.modal-info.segmenter .form-container p:first-of-type {
  margin: 0 0 12px;
}
.modal-info.segmenter .form-container p:last-of-type {
  margin: 0 0 30px;
}
.modal-info.segmenter .form-container .btn {
  width: 100%;
  max-width: 210px;
}
.modal-info .middle {
  position: relative;
  width: 100%;
  height: 100%;
}
.modal-info .form-container {
  position: absolute;
  width: 100%;
  height: 100%;
  max-height: 850px;
  background-color: #f7f6f4;
  max-width: 1160px;
  padding: 80px;
  border-radius: 30px;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  overflow-y: scroll;
  -webkit-transform: scale(0.8);
  -ms-transform: scale(0.8);
  -o-transform: scale(0.8);
  transform: scale(0.8);
  -webkit-transition: transform 0.3s ease;
  -ms-transition: transform 0.3s ease;
  -o-transition: transform 0.3s ease;
  transition: transform 0.3s ease;
}
@media (max-width: 767px) {
  .modal-info.segmenter .form-container {
    top: calc(50% - 203px);
    bottom: auto;
  }
  .modal-info .form-container {
    max-width: none;
    width: auto;
    padding: 40px 15px;
    border-radius: 0;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow-y: scroll;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .modal-info.segmenter .form-container {
    top: calc(50% - 155px);
  }
  .modal-info .form-container {
    max-width: 600px;
    padding: 48px 25px;
    top: 80px;
    left: calc(50% - 300px);
  }
}
.modal-info .page-header p {
  font-family: Specter_bold, Arial, sans-serif;
  font-size: 22px;
  margin: 0 0 38px !important;
}
.modal-info .row {
  margin: 0;
}
.modal-info .row .col-md-12 {
  width: 100% !important;
  padding: 0;
}
.modal-info legend {
  display: none;
}
.modal-info fieldset .form-group:last-child {
  margin-bottom: 0;
}
.modal-info label {
  text-align: left;
}
.modal-info .form-group,
.modal-info .form-submit-border {
  width: 100%;
  display: inline-block;
  margin-right: auto;
  margin-left: auto;
}
.modal-info .form-submit-border {
  float: none;
  display: inline-block;
}
.modal-info .form-group {
  margin-bottom: 7px;
}
.modal-info .form-group.field-contact {
  text-align: left;
}
.modal-info .form-group.field-contact label {
  position: relative;
  display: block;
  max-width: 340px;
  font-size: 16px;
  padding-left: 24px;
}
.modal-info .form-group.field-contact label input[type='checkbox'] {
  position: absolute;
  left: 0;
  top: 5px;
}
.modal-info .form-group.field-client {
  margin-bottom: 0;
  text-align: left;
}
.modal-info .form-group.field-client label {
  font-size: 16px;
}
.modal-info .form-group.field-client label.control-label:first-of-type {
  display: inline-block;
  margin-right: 25px;
}
.modal-info .form-group.field-client label.control-label:last-of-type {
  margin-left: 15px;
}
.modal-info .form-group.field-comply {
  margin-top: 15px;
  margin-bottom: 0;
}
.modal-info .selectric-form-control {
  text-align: left;
}
.modal-info p:last-child {
  margin: 0;
}
.modal-info .info-container {
  position: absolute;
  width: 100%;
  height: 100%;
  max-height: 850px;
  background-color: #f7f6f4;
  max-width: 1160px;
  padding: 80px;
  border-radius: 30px;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  overflow-y: scroll;
  -webkit-transform: scale(0.8);
  -ms-transform: scale(0.8);
  -o-transform: scale(0.8);
  transform: scale(0.8);
  -webkit-transition: transform 0.3s ease;
  -ms-transition: transform 0.3s ease;
  -o-transition: transform 0.3s ease;
  transition: transform 0.3s ease;
  text-align: left;
  max-width: none;
}
@media (max-width: 767px) {
  .modal-info .info-container {
    max-width: none;
    width: auto;
    padding: 40px 15px;
    border-radius: 0;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow-y: scroll;
  }
  .modal-form .form-group {
    margin-bottom: 9px;
  }
  .modal-form .form-group.field-client label.control-label:first-of-type,
  .modal-form .form-group:not(.field-client) label {
    display: block;
  }
  .modal-form .form-group.field-contact label input[type='checkbox'] {
    top: 2px;
  }
  .modal-form .form-submit-border {
    margin-top: 38px;
    margin-bottom: 20px;
  }
  .modal-form .state {
    margin-bottom: 38px;
  }
  .modal-form .left-cta {
    margin-bottom: 12px;
  }
}
.modal-info .info-container .close-icn {
  position: absolute;
  cursor: pointer;
  top: 15px;
  right: 15px;
  width: 19px;
  height: 19px;
  /* background: url('./images/ui/icn-close-black.svg') center center/contain no-repeat; */
}
.modal-info.open {
  opacity: 1;
  width: 100%;
  height: 100%;
  pointer-events: inherit;
}
.modal-info.open .form-container,
.modal-info.open .info-container {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}
.modal-info.instructions .info-container table td {
  width: 33%;
}
@media (min-width: 768px) and (max-width: 991px) {
  .modal-info .info-container {
    max-width: 600px;
    padding: 48px 25px;
    top: 80px;
    left: calc(50% - 300px);
  }
  .modal-info.instructions .info-container {
    padding: 30px;
    max-width: 700px;
    left: calc(50% - 350px);
  }
}
@media (min-width: 992px) {
  .modal-info.instructions .info-container {
    padding: 40px;
    max-width: 800px;
    left: calc(50% - 400px);
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .modal-form .form-submit-border {
    margin-top: 48px;
    margin-bottom: 24px;
  }
  .modal-form .state {
    margin-bottom: 48px;
  }
}
@media (min-width: 768px) {
  .modal-form .form-group:not(.field-client):not(.field-comply):not(.field-contact) label {
    float: left;
    line-height: 40px;
    font-size: 16px;
  }
  .modal-form .form-group:not(.field-comply).has-error .help-block,
  .modal-form .selectric-form-control,
  .modal-form input:not([type='submit']):not([type='radio']):not([type='checkbox']) {
    float: right;
    width: 65% !important;
  }
  .modal-form.segmenter .left-cta {
    padding-left: 9%;
    padding-right: 0;
  }
  .modal-form.segmenter .right-cta {
    padding-right: 9%;
    padding-left: 0;
  }
}
@media (min-width: 992px) {
  .modal-form .form-submit-border {
    margin-top: 38px;
  }
  .modal-form .state {
    margin-bottom: 58px;
  }
}
#pre-header {
  color: #fff;
  background: #121c4e;
  display: block;
  padding: 30px 0;
  text-align: center;
  height: 80px;
}
#pre-header .js-preheader {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 999;
  opacity: 0;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
#pre-header .js-preheader.fadeIn {
  opacity: 1;
}
#pre-header .js-preheader section {
  position: relative;
  min-height: 68px;
}
#pre-header .js-preheader section .preheader-close {
  position: absolute;
  cursor: pointer;
  top: 15px;
  right: 15px;
  width: 17px;
  height: 17px;
  /* background: url(../images/ui/icn-close-black.svg) center center/contain no-repeat; */
}
#pre-header .js-preheader[class*='bg-blue'] section .preheader-close {
  /* background-image: url(../images/ui/icn-close-white.svg); */
}
#pre-header .js-preheader.blue-close section .preheader-close {
  /* background-image: url(../images/ui/icn-close.svg); */
}
.rangeslider,
.rangeslider__fill {
  display: block;
  -moz-box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.3);
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  border-radius: 10px;
}
.rangeslider {
  background: #e6e6e6;
  position: relative;
}
.rangeslider--horizontal {
  height: 12px;
  width: 100%;
}
.rangeslider--vertical {
  width: 12px;
  min-height: 150px;
  max-height: 100%;
}
.rangeslider--disabled {
  opacity: 0.4;
}
.rangeslider__fill {
  background: #5378d6;
  position: absolute;
}
.rangeslider--horizontal .rangeslider__fill {
  top: 0;
  height: 100%;
}
.rangeslider--vertical .rangeslider__fill {
  bottom: 0;
  width: 100%;
}
.rangeslider__handle {
  background: #fff;
  border: 1px solid #ccc;
  cursor: pointer;
  display: inline-block;
  width: 30px;
  height: 30px;
  position: absolute;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}
.rangeslider--active .rangeslider__handle,
.rangeslider__handle:active {
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIgc3RvcC1vcGFjaXR5PSIwLjEiLz48c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiMwMDAwMDAiIHN0b3Atb3BhY2l0eT0iMC4xMiIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==);
  background-size: 100%;
  background-image: -webkit-gradient(
    linear,
    50% 0,
    50% 100%,
    color-stop(0, rgba(0, 0, 0, 0.1)),
    color-stop(100%, rgba(0, 0, 0, 0.12))
  );
  background-image: -moz-linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.12));
  background-image: -webkit-linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.12));
  background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.12));
}
.rangeslider--horizontal .rangeslider__handle {
  top: -10px;
  touch-action: pan-y;
  -ms-touch-action: pan-y;
}
.rangeslider--vertical .rangeslider__handle {
  left: -10px;
  touch-action: pan-x;
  -ms-touch-action: pan-x;
}
input[type='range']:focus + .rangeslider .rangeslider__handle {
  -moz-box-shadow: 0 0 8px rgba(255, 0, 255, 0.9);
  -webkit-box-shadow: 0 0 8px rgba(255, 0, 255, 0.9);
  box-shadow: 0 0 8px rgba(255, 0, 255, 0.9);
}
.rangeslider__value-bubble {
  position: absolute;
  top: auto;
  left: 50%;
  bottom: 2.5rem;
  transform: translate(-50%, 0);
  -webkit-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  text-align: center;
  background-color: #26328c;
  padding: 0.8rem;
  color: #fff;
  width: auto;
  margin: 0;
  white-space: nowrap;
}
.rangeslider__value-bubble::before {
  content: '';
  width: 0;
  height: 0;
  border-left: 1rem solid transparent;
  border-right: 1rem solid transparent;
  border-top: 1rem solid #26328c;
  position: absolute;
  top: auto;
  left: 50%;
  bottom: -1rem;
  transform: translate(-50%, -45%);
  -webkit-transform: translate(-50%, -45%);
  -ms-transform: translate(-50%, -45%);
}
.rangeslider__value-bubble hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #fff;
  margin: 10px 0;
  padding: 0;
}
.rangeslider__value-bubble .rangeslider__numemployees {
  letter-spacing: 1px;
  display: block;
  vertical-align: middle;
}
.selectric-wrapper {
  position: relative;
  cursor: pointer;
  width: 100%;
}
.selectric-responsive {
  width: 100%;
}
.selectric {
  font-family: Specter_bold, Arial, sans-serif;
  border: 0 solid #c9c9c9;
  border-radius: 10px;
  background: #efeee9;
  padding: 14px 20px;
  position: relative;
  overflow: hidden;
}
.has-error .selectric {
  border-color: red;
}
.selectric .label {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 18px;
  user-select: none;
}
.selectric .button {
  box-sizing: border-box;
  color: transparent;
  position: absolute;
  right: 20px;
  top: 19px;
  display: block;
  content: '';
  width: 8px;
  height: 8px;
  border-style: solid;
  border-color: #121c4e;
  border-width: 0 1px 1px 0;
  transform: rotate(46deg);
}
.selectric-focus .selectric {
  border-color: #c9c9c9;
}
.selectric-open {
  z-index: 9999;
  box-shadow: none;
}
.selectric-open .selectric {
  border: 1px solid #121c4e;
  border-bottom: none;
  background: #ebfaff;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.selectric-open .selectric .label {
  color: #121c4e;
}
.selectric-open .selectric-items {
  display: block;
  box-shadow: none;
}
.selectric-disabled {
  opacity: 0.5;
  cursor: default;
  user-select: none;
}
.selectric-hide-select {
  position: relative;
  overflow: hidden;
  width: 0;
  height: 0;
}
.selectric-hide-select select {
  position: absolute;
  left: -100%;
}
.selectric-hide-select.selectric-is-native {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 100;
}
.selectric-hide-select.selectric-is-native select {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  border: none;
  z-index: 50;
  box-sizing: border-box;
  opacity: 0;
}
.selectric-input {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  margin: 0 !important;
  padding: 0 !important;
  width: 1px !important;
  height: 1px !important;
  outline: 0 !important;
  border: none !important;
  background: 0 0 !important;
}
.selectric-temp-show {
  position: absolute !important;
  visibility: hidden !important;
  display: block !important;
}
.selectric-items {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background: #fff;
  border: 1px solid #121c4e;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  z-index: -1;
  overflow: hidden;
}
.selectric-items .selectric-scroll {
  height: 100%;
  overflow: auto;
}
.selectric-above .selectric-items {
  top: auto;
  bottom: 100%;
}
.selectric-items li,
.selectric-items ul {
  list-style: none;
  padding: 0;
  margin: 0;
  line-height: 20px;
  min-height: 20px;
}
.selectric-items li {
  display: block;
  padding: 14px 20px;
  cursor: pointer;
}
.selectric-items li.selected {
  background: #ebfaff;
}
.selectric-items li.highlighted {
  position: relative;
  padding-right: 40px;
  background-color: #ebfaff;
}
.selectric-items li:hover {
  background-color: #ebfaff;
}
.selectric-items .disabled {
  opacity: 0.5;
  cursor: default !important;
  background: 0 0 !important;
  color: #666 !important;
  user-select: none;
}
.selectric-items .selectric-group .selectric-group-label {
  font-weight: 700;
  padding-left: 10px;
  cursor: default;
  user-select: none;
  background: 0 0;
  color: #444;
}
.selectric-items .selectric-group.disabled li {
  opacity: 1;
}
.selectric-items .selectric-group li {
  padding-left: 25px;
}
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}
.slick-list {
  position: relative;
  height: auto;
  display: block;
  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: 0;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}
.slick-list .draggable {
  overflow: hidden;
}
.slick-slider .slick-list,
.slick-slider .slick-track {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
}
.slick-track::after,
.slick-track::before {
  content: '';
  display: table;
}
.slick-track::after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}
.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}
[dir='rtl'] .slick-slide {
  float: right;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
  display: none;
}
.slick-arrow {
  position: absolute;
  display: block;
  width: 20px;
  height: 20px;
  border-radius: 100px;
  cursor: pointer;
  bottom: 3px;
  padding: 0;
  border: none;
  outline: 0;
  z-index: 100;
  color: transparent;
  background-color: #121c4e;
  overflow: hidden;
  left: 26%;
}
.slick-arrow:focus,
.slick-arrow:hover {
  outline: 0;
  box-shadow: none;
}
.slick-prev::after {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
  left: 7px;
}
.slick-prev:hover {
  background: #90afff;
}
.slick-prev[dir='rtl'] {
  left: auto;
  right: -25px;
}
.slick-next {
  right: 26%;
  left: auto;
}
.slick-next::after {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}
.slick-next:hover {
  background: #90afff;
}
.slick-next[dir='rtl'] {
  left: -25px;
  right: auto;
}
.slick-dotted .slick-slider {
  margin-bottom: 30px;
}
.slick-dots {
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  width: 100%;
  height: 19px;
  position: relative;
  bottom: auto;
}
.slick-dots li {
  position: relative;
  display: inline-block;
  height: 12px;
  width: 12px;
  border: 2px solid #121c4e;
  border-radius: 50%;
  background-color: transparent;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}
.slick-dots li button {
  border: 0;
  background: 0 0;
  display: block;
  height: 14px;
  width: 14px;
  outline: 0;
  line-height: 0;
  font-size: 0;
  color: transparent;
  padding: 5px;
  cursor: pointer;
}
.slick-dots li button:focus,
.slick-dots li button:hover {
  outline: 0;
  box-shadow: none;
}
.slick-dots li button::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active {
  background-color: #121c4e;
}
.js-carousel-testimonials .slick-dots {
  margin: 40px 0 5px;
}
.js-carousel-testimonials .slick-dots li {
  background-color: #7b7c7e;
  border: none;
  width: 14px;
  height: 14px;
}
.js-carousel-testimonials .slick-dots li.slick-active {
  background-color: #121c4e;
}
@media (max-width: 767px) {
  .slick-prev {
    left: 16%;
  }
  .slick-next {
    right: 16%;
  }
  .slick-dots {
    margin: 38px 0 0;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .slick-dots {
    margin: 48px 0 0;
  }
}
@media (min-width: 992px) {
  .slick-dots {
    margin: 30px 0 0;
  }
}
#testimonial-slider {
  margin: 0 auto;
  padding: 0 60px;
}
#testimonial-slider .slick-track {
  display: flex;
  /*align-items: center;*/
}
#testimonial-slider img {
  margin-bottom: 36px;
}
#testimonials #testimonial-button {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 100%;
  height: auto;
  line-height: 46px;
  border-radius: 4px;
  padding: 0 40px;
  z-index: 999;
  background: 0 0;
}
#testimonial-slider .slick-arrow,
.demo-slider .slick-arrow {
  width: 17px;
  height: 34px;
  top: 50%;
}
.demo-slider .slick-arrow {
  top: 34%;
}
#testimonial-slider .slick-prev {
  left: -17px;
  background: url('./images/ui/button_carousel_arrow_left_default.svg') center center/contain
    no-repeat;
}
#testimonial-slider .slick-prev:hover {
  left: -17px;
  background: url('./images/ui/button_carousel_arrow_left_hover.svg') center center/contain
    no-repeat;
}
#testimonial-slider .slick-next {
  right: -17px;
  background: url('./images/ui/button_carousel_arrow_right_default.svg') center center/contain
    no-repeat;
}
#testimonial-slider .slick-next:hover {
  right: -17px;
  background: url('./images/ui/button_carousel_arrow_right_hover.svg') center center/contain
    no-repeat;
}
.feature-accordion.accordion dt {
  padding-left: 0;
}
.feature-accordion.accordion dt.open span.arrow {
  transform: rotate(-135deg);
  top: 3px;
}
.feature-accordion.accordion span.arrow {
  box-sizing: border-box;
  color: transparent;
  display: inline-block;
  position: relative;
  top: -3px;
  content: '';
  width: 12px;
  height: 12px;
  border-style: solid;
  border-color: #cd0058;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
.js-tab-accordian {
  opacity: 0;
  transition: opacity 0.2s linear;
}
.js-tab-accordian.tab-loaded {
  opacity: 1;
}
.r-tabs {
  text-align: center;
  position: relative;
}
.r-tabs .r-tabs-nav {
  display: inline-block;
  margin: 0;
  padding: 0;
  border-bottom: 1px solid #ccc;
}
.r-tabs .r-tabs-nav .r-tabs-tab {
  display: inline-block;
  height: 46px;
  margin: 0 14px 1px;
}
.r-tabs .r-tabs-nav .r-tabs-tab:first-of-type {
  margin-left: 0;
}
.r-tabs .r-tabs-nav .r-tabs-tab:last-of-type {
  margin-right: 0;
}
.r-tabs .r-tabs-nav .r-tabs-anchor {
  display: block;
  padding: 10px 0;
  text-decoration: none;
  color: #121c4e;
}
.r-tabs .r-tabs-nav .r-tabs-anchor:focus {
  box-shadow: none;
}
.r-tabs .r-tabs-nav .r-tabs-state-disabled {
  opacity: 0.5;
}
.r-tabs .r-tabs-nav .r-tabs-state-active {
  position: relative;
}
.r-tabs .r-tabs-nav .r-tabs-state-active .r-tabs-anchor {
  margin-bottom: -4px;
  color: #41d4ff;
  border-bottom: 5px solid #41d4ff;
  -webkit-transition: width 0.3s ease;
  -moz-transition: width 0.3s ease;
  -o-transition: width 0.3s ease;
  transition: width 0.3s ease;
}
.r-tabs .r-tabs-tab {
  display: inline-block;
  margin: 0;
  list-style: none;
}
.r-tabs .r-tabs-panel {
  display: none;
  text-align: left;
  max-width: 100%;
}
.r-tabs .r-tabs-panel.r-tabs-state-active {
  margin-left: 0;
  margin-right: 0;
  float: left;
  width: 100%;
}
.r-tabs .r-tabs-accordion-title {
  display: none;
  text-align: left;
  margin-bottom: 5px;
  padding-left: 15px;
  padding-right: 15px;
  clear: both;
}
.r-tabs .r-tabs-accordion-title .r-tabs-anchor {
  display: block;
  padding: 12px 15px;
  background-color: #121c4e;
  color: #fff;
  text-decoration: none;
}
.r-tabs .r-tabs-accordion-title .r-tabs-anchor::after {
  content: '';
  display: block;
  float: right;
  margin-top: 4px;
  height: 16px;
  width: 16px;
  /* background: url(../images/ui/icn-plus-white.svg) center center/contain no-repeat; */
}
.r-tabs .r-tabs-accordion-title.r-tabs-state-active .r-tabs-anchor {
  background-color: #41d4ff;
}
.r-tabs .r-tabs-accordion-title.r-tabs-state-active .r-tabs-anchor::after {
  /* background-image: url('./images/ui/icn-minus-white.svg'); */
}
.r-tabs .r-tabs-accordion-title.r-tabs-state-disabled {
  opacity: 0.5;
}
.r-tabs .slick-arrow {
  top: 43%;
}
.info {
  display: inline-block;
  margin-top: 10px;
  margin-right: 10px;
  padding: 10px 20px;
  width: 300px;
  line-height: 100%;
  font-family: Consolas, 'Liberation Mono', Menlo, Courier, monospace;
  font-size: 14px;
  color: #121c4e;
  border: 2px solid #00ab94;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  background-color: #fff;
  cursor: pointer;
}
@media (max-width: 767px) {
  #testimonial-slider {
    padding: 0 30px;
  }
  .r-tabs .slick-slider + .slick-slider {
    margin-top: 38px;
  }
}
@media (max-width: 991px) {
  .r-tabs .r-tabs-nav {
    display: none;
  }
  .r-tabs .r-tabs-accordion-title {
    display: block;
  }
  .r-tabs .r-tabs-panel {
    padding-top: 43px;
    padding-bottom: 48px;
  }
  .r-tabs .r-tabs-panel:last-of-type {
    padding-bottom: 0;
  }
  .r-tabs .slick-slider {
    padding-left: 15px;
    padding-right: 15px;
  }
  .r-tabs .slick-prev {
    left: 3px;
  }
  .r-tabs .slick-next {
    right: 3px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .r-tabs .slick-slider + .slick-slider {
    margin-top: 48px;
  }
}
#clickable-demo .r-tabs-nav {
  display: block;
  border-bottom: none;
}
#clickable-demo .r-tabs-nav > .r-tabs-tab {
  display: block;
  height: auto;
  text-align: left;
  padding: 12px 24px;
  margin: 0;
}
#clickable-demo .r-tabs-nav > .r-tabs-tab > .r-tabs-anchor {
  color: #000;
  font-size: 16px;
  line-height: 22px;
}
#clickable-demo .r-tabs-nav > .r-tabs-tab > .r-tabs-anchor h4 {
  font-size: 18px;
  font-weight: 700;
  margin: 0;
  color: #25328c;
}
#clickable-demo .r-tabs-nav > .r-tabs-tab.r-tabs-state-active {
  border-radius: 5px;
  box-shadow: 1px 1px 7px 3px rgba(220, 220, 220, 0.3);
  background: #fff;
}
#clickable-demo .r-tabs-nav > .r-tabs-tab.r-tabs-state-active > .r-tabs-anchor {
  border-bottom: none;
  color: #000;
}
#clickable-demo .r-tabs-nav > .r-tabs-tab.r-tabs-state-default {
  opacity: 0.5;
}
#clickable-demo .r-tabs-panel {
  padding-top: 0;
  text-align: center;
}
@media (min-width: 992px) {
  .r-tabs .r-tabs-panel {
    padding-top: 64px;
  }
  .r-tabs .slick-slider + .slick-slider {
    margin-top: 56px;
  }
  .r-tabs .slick-prev {
    left: -30px;
  }
  .r-tabs .slick-next {
    right: -30px;
  }
  .blue-gradient {
    background: #f0f4ff;
    background: -moz-linear-gradient(125deg, #f0f4ff 0, #e0e9ff 37.64%, #fff 100%);
    background: -webkit-linear-gradient(125deg, #f0f4ff 0, #e0e9ff 37.64%, #fff 100%);
    background: linear-gradient(125deg, #f0f4ff 0, #e0e9ff 37.64%, #fff 100%);
  }
  #clickable-demo .r-tabs-panel {
    padding: 3% 6%;
    text-align: center;
    background: #fff;
  }
}
@media (max-width: 991px) {
  #clickable-demo .r-tabs-accordion-title {
    display: none;
    padding-left: 0;
    padding-right: 0;
    position: relative;
    margin-bottom: 0;
    border-top: 1px solid #c8cede;
  }
  #clickable-demo .r-tabs-accordion-title:last-of-type {
    border-bottom: 1px solid #c8cede;
  }
  #clickable-demo .r-tabs-accordion-title .r-tabs-anchor::after {
    display: none;
    position: absolute;
    top: 12px;
    right: 15px;
    float: none;
    /* background: url(../assets/ui/icn-plus-blue.svg) center center/contain no-repeat; */
  }
  #clickable-demo .r-tabs-accordion-title.r-tabs-state-active {
    margin-bottom: 0;
  }
  #clickable-demo .r-tabs-accordion-title.r-tabs-state-active .r-tabs-anchor::after {
    /* background: url(../assets/ui/icn-minus-blue.svg) center center/contain no-repeat; */
  }
  #clickable-demo .r-tabs-accordion-title .r-tabs-anchor {
    display: none;
  }
  #clickable-demo .r-tabs-panel {
    background-color: #fff;
    padding: 20px;
    display: block !important;
    color: #000;
    box-shadow: 1px 1px 7px 3px rgba(220, 220, 220, 0.3);
    border-radius: 5px;
    font-size: 18px;
    line-height: 22px;
    float: none;
    margin-bottom: 20px;
  }
  #clickable-demo .r-tabs-panel h4 {
    font-size: 16px;
    line-height: 20px;
    color: #25328c;
    margin-bottom: 0;
  }
}
.accordion div.plus {
  height: 28px;
  width: 28px;
  background: center center/contain no-repeat;
  position: absolute;
  left: 0;
  top: -3px;
}
.accordion dt {
  cursor: pointer;
  position: relative;
  padding-left: 30px;
  margin-top: 24px;
}
.accordion dt:first-child {
  margin-top: 26px;
}
.accordion dt::before {
  display: block;
  content: '';
  position: absolute;
  top: 2px;
  left: 2px;
  width: 21px;
  height: 21px;
}
.accordion dd {
  padding-left: 30px;
  margin-top: 12px;
  display: none;
  padding-bottom: 1px;
}
.accordion.faq {
  border-bottom: 1px dashed #c2c2c2;
}
.accordion.faq dt:first-child {
  margin-top: 0;
}
.accordion.faq dt {
  margin-top: 0;
  padding: 20px 40px 20px 20px;
  position: relative;
  border-top: 1px dashed #c2c2c2;
}
.accordion.faq dt:hover {
  color: #4300d5;
  background: #f5fdff;
}
.accordion.faq dt.open {
  border-bottom: none;
  font-family: Specter_bold, Arial, sans-serif;
  color: #4300d5;
  background: 0 0;
}
.accordion.faq dt.open::after {
  transform: rotate(45deg);
}
.accordion.faq dt::after {
  box-sizing: border-box;
  color: transparent;
  display: inline-block;
  position: absolute;
  top: 27px;
  right: 20px;
  content: '';
  width: 12px;
  height: 12px;
  border-style: solid;
  border-color: #4300d5;
  border-width: 0 2px 2px 0;
  transform: rotate(-45deg);
}
.accordion.faq dd {
  padding: 0 20px 20px;
  margin-top: 0;
}
.logo-v2 {
  padding: 0 6px 46px;
}
.target-anchor {
  display: block;
  position: relative;
  top: -100px;
  visibility: hidden;
}
@media (max-width: 767px) {
  .logo-v2 {
    border-top: 5px solid #4e00dc;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
  .btn-redone {
    width: 50%;
    padding: 12px 0;
    font-size: 16px;
    line-height: 1.5;
    border-radius: 25px;
  }
}
.bg-red-FF0000 {
  background-color: red !important;
}
.red-FF0000 {
  color: red;
}
.bg-red-fff1f1 {
  background-color: #fff1f1 !important;
}
.red-fff1f1 {
  color: #fff1f1;
}
.bg-pink-DE0A65 {
  background-color: #cd0058 !important;
}
.pink-DE0A65 {
  color: #cd0058;
}
.bg-pink-FCE3DF {
  background-color: #fce3df !important;
}
.pink-FCE3DF {
  color: #fce3df;
}
.bg-purple-4300D5 {
  background-color: #4300d5 !important;
}
.purple-4300D5 {
  color: #4300d5;
}
.bg-purple-2F0B9E {
  background-color: #2f0b9e !important;
}
.purple-2F0B9E {
  color: #2f0b9e;
}
.bg-blue-121C4E {
  background-color: #121c4e !important;
}
.blue-121C4E {
  color: #121c4e;
}
.bg-blue-41D4FF {
  background-color: #41d4ff !important;
}
.blue-41D4FF {
  color: #41d4ff;
}
.bg-grey-7B7C7E {
  background-color: #7b7c7e !important;
}
.grey-7B7C7E {
  color: #7b7c7e;
}
.bg-grey-C2C2C2 {
  background-color: #c2c2c2 !important;
}
.grey-C2C2C2 {
  color: #c2c2c2;
}
.bt-grey-C2C2C2 {
  border-top: 2px solid #c2c2c2;
}
.bb-grey-C2C2C2 {
  border-bottom: 2px solid #c2c2c2;
}
.bg-grey-F7F6F4 {
  background-color: #f7f6f4 !important;
}
.bt-grey-F7F6F4 {
  border-top: 2px solid #f7f6f4;
}
.bb-grey-F7F6F4 {
  border-bottom: 2px solid #f7f6f4;
}
.black {
  color: #121c4e !important;
}
.bg-white {
  background-color: #fff !important;
}
.white {
  color: #fff !important;
}
.white a,
.white a:hover {
  color: #fff;
}
.d-none {
  display: none !important;
}
.d-inline {
  display: inline !important;
}
.d-inline-block {
  display: inline-block !important;
}
.d-block {
  display: block !important;
}
.d-table {
  display: table !important;
}
.d-table-row {
  display: table-row !important;
}
.d-table-cell {
  display: table-cell !important;
}
.d-flex {
  display: flex !important;
}
.d-inline-flex {
  display: inline-flex !important;
}
@media (max-width: 767px) {
  .d-xs-none {
    display: none !important;
  }
  .d-xs-inline {
    display: inline !important;
  }
  .d-xs-inline-block {
    display: inline-block !important;
  }
  .d-xs-block {
    display: block !important;
  }
  .d-xs-table {
    display: table !important;
  }
  .d-xs-table-row {
    display: table-row !important;
  }
  .d-xs-table-cell {
    display: table-cell !important;
  }
  .d-xs-flex {
    display: flex !important;
  }
  .d-xs-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 768px) {
  .white-sm {
    color: #fff !important;
  }
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 992px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
}
@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
}
.flex-row {
  flex-direction: row !important;
}
.flex-column {
  flex-direction: column !important;
}
.flex-row-reverse {
  flex-direction: row-reverse !important;
}
.flex-column-reverse {
  flex-direction: column-reverse !important;
}
.flex-wrap {
  flex-wrap: wrap !important;
}
.flex-nowrap {
  flex-wrap: nowrap !important;
}
.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}
.justify-content-start {
  justify-content: flex-start !important;
}
.justify-content-end {
  justify-content: flex-end !important;
}
.justify-content-center {
  justify-content: center !important;
}
.justify-content-between {
  justify-content: space-between !important;
}
.justify-content-around {
  justify-content: space-around !important;
}
.align-items-start {
  align-items: flex-start !important;
}
.align-items-end {
  align-items: flex-end !important;
}
.align-items-center {
  align-items: center !important;
}
.align-items-baseline {
  align-items: baseline !important;
}
.align-items-stretch {
  align-items: stretch !important;
}
.align-content-start {
  align-content: flex-start !important;
}
.align-content-end {
  align-content: flex-end !important;
}
.align-content-center {
  align-content: center !important;
}
.align-content-between {
  align-content: space-between !important;
}
.align-content-around {
  align-content: space-around !important;
}
.align-content-stretch {
  align-content: stretch !important;
}
.align-self-auto {
  align-self: auto !important;
}
.align-self-start {
  align-self: flex-start !important;
}
.align-self-end {
  align-self: flex-end !important;
}
.align-self-center {
  align-self: center !important;
}
.align-self-baseline {
  align-self: baseline !important;
}
.align-self-stretch {
  align-self: stretch !important;
}
@media (max-width: 767px) {
  .flex-xs-row {
    flex-direction: row !important;
  }
  .flex-xs-column {
    flex-direction: column !important;
  }
  .flex-xs-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xs-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xs-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xs-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xs-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xs-start {
    justify-content: flex-start !important;
  }
  .justify-content-xs-end {
    justify-content: flex-end !important;
  }
  .justify-content-xs-center {
    justify-content: center !important;
  }
  .justify-content-xs-between {
    justify-content: space-between !important;
  }
  .justify-content-xs-around {
    justify-content: space-around !important;
  }
  .align-items-xs-start {
    align-items: flex-start !important;
  }
  .align-items-xs-end {
    align-items: flex-end !important;
  }
  .align-items-xs-center {
    align-items: center !important;
  }
  .align-items-xs-baseline {
    align-items: baseline !important;
  }
  .align-items-xs-stretch {
    align-items: stretch !important;
  }
  .align-content-xs-start {
    align-content: flex-start !important;
  }
  .align-content-xs-end {
    align-content: flex-end !important;
  }
  .align-content-xs-center {
    align-content: center !important;
  }
  .align-content-xs-between {
    align-content: space-between !important;
  }
  .align-content-xs-around {
    align-content: space-around !important;
  }
  .align-content-xs-stretch {
    align-content: stretch !important;
  }
  .align-self-xs-auto {
    align-self: auto !important;
  }
  .align-self-xs-start {
    align-self: flex-start !important;
  }
  .align-self-xs-end {
    align-self: flex-end !important;
  }
  .align-self-xs-center {
    align-self: center !important;
  }
  .align-self-xs-baseline {
    align-self: baseline !important;
  }
  .align-self-xs-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}
.d-flex.container::after,
.d-flex.container::before,
.d-flex.container:after,
.d-flex.container:before,
.d-flex.row::after,
.d-flex.row::before,
.d-flex.row:after,
.d-flex.row:before {
  content: normal;
}
.embed-responsive {
  position: relative;
  display: block;
  height: 0;
  padding: 0;
  overflow: hidden;
}
.embed-responsive .embed-responsive-item,
.embed-responsive embed,
.embed-responsive iframe,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  border: 0;
}
.embed-responsive-16by9 {
  padding-bottom: 56.25%;
}
.embed-responsive-4by3 {
  padding-bottom: 75%;
}
@-ms-viewport {
  width: device-width;
}
.visible-lg,
.visible-md,
.visible-md-block,
.visible-md-flex,
.visible-md-inline,
.visible-md-inline-block,
.visible-sm,
.visible-sm-block,
.visible-sm-flex,
.visible-sm-inline,
.visible-sm-inline-block,
.visible-xs,
.visible-xs-block,
.visible-xs-flex,
.visible-xs-inline,
.visible-xs-inline-block {
  display: none !important;
}
@media (max-width: 767px) {
  .visible-xs {
    display: block !important;
  }
  table.visible-xs {
    display: table !important;
  }
  tr.visible-xs {
    display: table-row !important;
  }
  td.visible-xs,
  th.visible-xs {
    display: table-cell !important;
  }
  .visible-xs-block {
    display: block !important;
  }
  .visible-xs-inline {
    display: inline !important;
  }
  .visible-xs-inline-block {
    display: inline-block !important;
  }
  .visible-xs-flex {
    display: flex !important;
    display: -webkit-box !important;
    display: -moz-box !important;
    display: -ms-flexbox !important;
    display: -webkit-flex !important;
  }
  .hidden-xs {
    display: none !important;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm {
    display: block !important;
  }
  table.visible-sm {
    display: table !important;
  }
  tr.visible-sm {
    display: table-row !important;
  }
  td.visible-sm,
  th.visible-sm {
    display: table-cell !important;
  }
  .visible-sm-block {
    display: block !important;
  }
  .visible-sm-inline {
    display: inline !important;
  }
  .visible-sm-inline-block {
    display: inline-block !important;
  }
  .visible-sm-flex {
    display: flex !important;
    display: -webkit-box !important;
    display: -moz-box !important;
    display: -ms-flexbox !important;
    display: -webkit-flex !important;
  }
  .hidden-sm {
    display: none !important;
  }
}
@media (min-width: 992px) {
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
  .visible-md {
    display: block !important;
  }
  table.visible-md {
    display: table !important;
  }
  tr.visible-md {
    display: table-row !important;
  }
  td.visible-md,
  th.visible-md {
    display: table-cell !important;
  }
  .visible-md-block {
    display: block !important;
  }
  .visible-md-inline {
    display: inline !important;
  }
  .visible-md-inline-block {
    display: inline-block !important;
  }
  .visible-md-flex {
    display: flex !important;
    display: -webkit-box !important;
    display: -moz-box !important;
    display: -ms-flexbox !important;
    display: -webkit-flex !important;
  }
  .hidden-md {
    display: none !important;
  }
}
.visible-print {
  display: none !important;
}
@media print {
  .visible-print {
    display: block !important;
  }
  table.visible-print {
    display: table !important;
  }
  tr.visible-print {
    display: table-row !important;
  }
  td.visible-print,
  th.visible-print {
    display: table-cell !important;
  }
}
.visible-print-block {
  display: none !important;
}
@media print {
  .visible-print-block {
    display: block !important;
  }
}
.visible-print-inline {
  display: none !important;
}
@media print {
  .visible-print-inline {
    display: inline !important;
  }
}
.visible-print-inline-block {
  display: none !important;
}
@media print {
  .visible-print-inline-block {
    display: inline-block !important;
  }
  .hidden-print {
    display: none !important;
  }
}
.width32 {
  width: 100%;
  max-width: 32px;
  height: auto;
}
.width24 {
  width: 100%;
  max-width: 24px;
  height: auto;
}
.width64 {
  width: 100%;
  max-width: 64px;
  height: auto;
}
.width80 {
  width: 100%;
  max-width: 80px;
  height: auto;
}
.width100 {
  width: 100%;
  max-width: 100px;
  height: auto;
}
.width120 {
  width: 100%;
  max-width: 120px;
  height: auto;
}
.width170 {
  width: 100%;
  max-width: 170px;
  height: auto;
}
.width210 {
  width: 100%;
  max-width: 210px;
  height: auto;
}
.width250 {
  width: 100%;
  max-width: 250px;
  height: auto;
}
.width330 {
  width: 100%;
  max-width: 330px;
  height: auto;
}
.width410 {
  width: 100%;
  max-width: 410px;
  height: auto;
}
.width490 {
  width: 100%;
  max-width: 490px;
  height: auto;
}
.width510 {
  width: 100%;
  max-width: 510px;
  height: auto;
}
.width570 {
  width: 100%;
  max-width: 570px;
  height: auto;
}
.width650 {
  width: 100%;
  max-width: 650px;
  height: auto;
}
.width730 {
  width: 100%;
  max-width: 730px;
  height: auto;
}
.width810 {
  width: 100%;
  max-width: 810px;
  height: auto;
}
.width860 {
  width: 100%;
  max-width: 860px;
  height: auto;
}

.width890 {
  width: 100%;
  max-width: 890px;
  height: auto;
}
.width1050 {
  width: 100%;
  max-width: 1050px;
  height: auto;
}
.width1130 {
  width: 100%;
  max-width: 1130px;
  height: auto;
}
.width1210 {
  width: 100%;
  max-width: 1210px;
  height: auto;
}
.width1290 {
  width: 100%;
  max-width: 1290px;
  height: auto;
}
.height20 {
  min-height: 20px;
}
.height25 {
  min-height: 25px;
}
.height30 {
  min-height: 30px;
}
.height35 {
  min-height: 35px;
}
.height40 {
  min-height: 40px;
}
.height45 {
  min-height: 45px;
}
.height50 {
  min-height: 50px;
}
.height55 {
  min-height: 55px;
}
.height60 {
  min-height: 60px;
}
.height65 {
  min-height: 65px;
}
.height70 {
  min-height: 70px;
}
.height80 {
  min-height: 80px;
}
.height90 {
  min-height: 90px;
}
.height100 {
  min-height: 100px;
}
.height110 {
  min-height: 110px;
}
.height120 {
  min-height: 120px;
}
.height130 {
  min-height: 130px;
}
.height140 {
  min-height: 140px;
}
.height150 {
  min-height: 150px;
}
.height160 {
  min-height: 160px;
}
.height170 {
  min-height: 170px;
}
.height180 {
  min-height: 180px;
}
.height190 {
  min-height: 190px;
}
.height200 {
  min-height: 200px;
}
.pt-64 {
  padding-top: 64px !important;
}
.pb-64 {
  padding-bottom: 64px !important;
}
.py-64 {
  padding-top: 64px !important;
  padding-bottom: 64px !important;
}
.mt-64 {
  margin-top: 64px !important;
}
.mb-64 {
  margin-bottom: 64px !important;
}
.my-64 {
  margin-top: 64px !important;
  margin-bottom: 64px !important;
}
.pt-56 {
  padding-top: 56px !important;
}
.pb-56 {
  padding-bottom: 56px !important;
}
.py-56 {
  padding-top: 56px !important;
  padding-bottom: 56px !important;
}
.mt-56 {
  margin-top: 56px !important;
}
.mb-56 {
  margin-bottom: 56px !important;
}
.my-56 {
  margin-top: 56px !important;
  margin-bottom: 56px !important;
}
.pt-48 {
  padding-top: 48px !important;
}
.pb-48 {
  padding-bottom: 48px !important;
}
.py-48 {
  padding-top: 48px !important;
  padding-bottom: 48px !important;
}

.mt-48 {
  margin-top: 48px !important;
}
.mb-48 {
  margin-bottom: 48px !important;
}
.my-48 {
  margin-top: 48px !important;
  margin-bottom: 48px !important;
}
.pt-44 {
  padding-top: 44px !important;
}
.pb-44 {
  padding-bottom: 44px !important;
}
.py-44 {
  padding-top: 44px !important;
  padding-bottom: 44px !important;
}
.mt-44 {
  margin-top: 44px !important;
}
.mb-44 {
  margin-bottom: 44px !important;
}
.my-44 {
  margin-top: 44px !important;
  margin-bottom: 44px !important;
}
.pt-36 {
  padding-top: 36px !important;
}
.pb-36 {
  padding-bottom: 36px !important;
}
.py-36 {
  padding-top: 36px !important;
  padding-bottom: 36px !important;
}
.mt-36 {
  margin-top: 36px !important;
}
.mb-36 {
  margin-bottom: 36px !important;
}
.my-36 {
  margin-top: 36px !important;
  margin-bottom: 36px !important;
}
.pt-24 {
  padding-top: 24px !important;
}
.pb-24 {
  padding-bottom: 24px !important;
}
.py-24 {
  padding-top: 24px !important;
  padding-bottom: 24px !important;
}
.mt-24 {
  margin-top: 24px !important;
}
.mb-24 {
  margin-bottom: 24px !important;
}
.my-24 {
  margin-top: 24px !important;
  margin-bottom: 24px !important;
}
.pt-18 {
  padding-top: 18px !important;
}
.pb-18 {
  padding-bottom: 18px !important;
}
.py-18 {
  padding-top: 18px !important;
  padding-bottom: 18px !important;
}
.mt-18 {
  margin-top: 18px !important;
}
.mb-18 {
  margin-bottom: 18px !important;
}
.my-18 {
  margin-top: 18px !important;
  margin-bottom: 18px !important;
}
.pt-12 {
  padding-top: 12px !important;
}
.pb-12 {
  padding-bottom: 12px !important;
}
.py-12 {
  padding-top: 12px !important;
  padding-bottom: 12px !important;
}
.mt-12 {
  margin-top: 12px !important;
}
.mb-12 {
  margin-bottom: 12px !important;
}
.my-12 {
  margin-top: 12px !important;
  margin-bottom: 12px !important;
}
.p-200 {
  padding: 200px !important;
}
.pt-200 {
  padding-top: 200px !important;
}
.pl-200 {
  padding-left: 200px !important;
}
.pr-200 {
  padding-right: 200px !important;
}
.pb-200 {
  padding-bottom: 200px !important;
}
.px-200 {
  padding-left: 200px !important;
  padding-right: 200px !important;
}
.py-200 {
  padding-top: 200px !important;
  padding-bottom: 200px !important;
}
.m-200 {
  margin: 200px !important;
}
.mt-200 {
  margin-top: 200px !important;
}
.ml-200 {
  margin-left: 200px !important;
}
.mr-200 {
  margin-right: 200px !important;
}
.mb-200 {
  margin-bottom: 200px !important;
}
.mx-200 {
  margin-left: 200px !important;
  margin-right: 200px !important;
}
.my-200 {
  margin-top: 200px !important;
  margin-bottom: 200px !important;
}
.p-180 {
  padding: 180px !important;
}
.pt-180 {
  padding-top: 180px !important;
}
.pl-180 {
  padding-left: 180px !important;
}
.pr-180 {
  padding-right: 180px !important;
}
.pb-180 {
  padding-bottom: 180px !important;
}
.px-180 {
  padding-left: 180px !important;
  padding-right: 180px !important;
}
.py-180 {
  padding-top: 180px !important;
  padding-bottom: 180px !important;
}
.m-180 {
  margin: 180px !important;
}
.mt-180 {
  margin-top: 180px !important;
}
.ml-180 {
  margin-left: 180px !important;
}
.mr-180 {
  margin-right: 180px !important;
}
.mb-180 {
  margin-bottom: 180px !important;
}
.mx-180 {
  margin-left: 180px !important;
  margin-right: 180px !important;
}
.my-180 {
  margin-top: 180px !important;
  margin-bottom: 180px !important;
}
.p-160 {
  padding: 160px !important;
}
.pt-160 {
  padding-top: 160px !important;
}
.pl-160 {
  padding-left: 160px !important;
}
.pr-160 {
  padding-right: 160px !important;
}
.pb-160 {
  padding-bottom: 160px !important;
}
.px-160 {
  padding-left: 160px !important;
  padding-right: 160px !important;
}
.py-160 {
  padding-top: 160px !important;
  padding-bottom: 160px !important;
}
.m-160 {
  margin: 160px !important;
}
.mt-160 {
  margin-top: 160px !important;
}
.ml-160 {
  margin-left: 160px !important;
}
.mr-160 {
  margin-right: 160px !important;
}
.mb-160 {
  margin-bottom: 160px !important;
}
.mx-160 {
  margin-left: 160px !important;
  margin-right: 160px !important;
}
.my-160 {
  margin-top: 160px !important;
  margin-bottom: 160px !important;
}
.p-140 {
  padding: 140px !important;
}
.pt-140 {
  padding-top: 140px !important;
}
.pl-140 {
  padding-left: 140px !important;
}
.pr-140 {
  padding-right: 140px !important;
}
.pb-140 {
  padding-bottom: 140px !important;
}
.px-140 {
  padding-left: 140px !important;
  padding-right: 140px !important;
}
.py-140 {
  padding-top: 140px !important;
  padding-bottom: 140px !important;
}
.m-140 {
  margin: 140px !important;
}
.mt-140 {
  margin-top: 140px !important;
}
.ml-140 {
  margin-left: 140px !important;
}
.mr-140 {
  margin-right: 140px !important;
}
.mb-140 {
  margin-bottom: 140px !important;
}
.mx-140 {
  margin-left: 140px !important;
  margin-right: 140px !important;
}
.my-140 {
  margin-top: 140px !important;
  margin-bottom: 140px !important;
}
.p-120 {
  padding: 120px !important;
}
.pt-120 {
  padding-top: 120px !important;
}
.pl-120 {
  padding-left: 120px !important;
}
.pr-120 {
  padding-right: 120px !important;
}
.pb-120 {
  padding-bottom: 120px !important;
}
.px-120 {
  padding-left: 120px !important;
  padding-right: 120px !important;
}
.py-120 {
  padding-top: 120px !important;
  padding-bottom: 120px !important;
}
.m-120 {
  margin: 120px !important;
}
.mt-120 {
  margin-top: 120px !important;
}
.ml-120 {
  margin-left: 120px !important;
}
.mr-120 {
  margin-right: 120px !important;
}
.mb-120 {
  margin-bottom: 120px !important;
}
.mx-120 {
  margin-left: 120px !important;
  margin-right: 120px !important;
}
.my-120 {
  margin-top: 120px !important;
  margin-bottom: 120px !important;
}
.p-100 {
  padding: 100px !important;
}
.pt-100 {
  padding-top: 100px !important;
}
.pl-100 {
  padding-left: 100px !important;
}
.pr-100 {
  padding-right: 100px !important;
}
.pb-100 {
  padding-bottom: 100px !important;
}
.px-100 {
  padding-left: 100px !important;
  padding-right: 100px !important;
}
.py-100 {
  padding-top: 100px !important;
  padding-bottom: 100px !important;
}
.m-100 {
  margin: 100px !important;
}
.mt-100 {
  margin-top: 100px !important;
}
.ml-100 {
  margin-left: 100px !important;
}
.mr-100 {
  margin-right: 100px !important;
}
.mb-100 {
  margin-bottom: 100px !important;
}
.mx-100 {
  margin-left: 100px !important;
  margin-right: 100px !important;
}
.my-100 {
  margin-top: 100px !important;
  margin-bottom: 100px !important;
}
.p-90 {
  padding: 90px !important;
}
.pt-90 {
  padding-top: 90px !important;
}
.pl-90 {
  padding-left: 90px !important;
}
.pr-90 {
  padding-right: 90px !important;
}
.pb-90 {
  padding-bottom: 90px !important;
}
.px-90 {
  padding-left: 90px !important;
  padding-right: 90px !important;
}
.py-90 {
  padding-top: 90px !important;
  padding-bottom: 90px !important;
}
.m-90 {
  margin: 90px !important;
}
.mt-90 {
  margin-top: 90px !important;
}
.ml-90 {
  margin-left: 90px !important;
}
.mr-90 {
  margin-right: 90px !important;
}
.mb-90 {
  margin-bottom: 90px !important;
}
.mx-90 {
  margin-left: 90px !important;
  margin-right: 90px !important;
}
.my-90 {
  margin-top: 90px !important;
  margin-bottom: 90px !important;
}
.p-80 {
  padding: 80px !important;
}
.pt-80 {
  padding-top: 80px !important;
}
.pl-80 {
  padding-left: 80px !important;
}
.pr-80 {
  padding-right: 80px !important;
}
.pb-80 {
  padding-bottom: 80px !important;
}
.px-80 {
  padding-left: 80px !important;
  padding-right: 80px !important;
}
.py-80 {
  padding-top: 80px !important;
  padding-bottom: 80px !important;
}
.m-80 {
  margin: 80px !important;
}
.mt-80 {
  margin-top: 80px !important;
}
.ml-80 {
  margin-left: 80px !important;
}
.mr-80 {
  margin-right: 80px !important;
}
.mb-80 {
  margin-bottom: 80px !important;
}
.mx-80 {
  margin-left: 80px !important;
  margin-right: 80px !important;
}
.my-80 {
  margin-top: 80px !important;
  margin-bottom: 80px !important;
}
.p-70 {
  padding: 70px !important;
}
.pt-70 {
  padding-top: 70px !important;
}
.pl-70 {
  padding-left: 70px !important;
}
.pr-70 {
  padding-right: 70px !important;
}
.pb-70 {
  padding-bottom: 70px !important;
}
.px-70 {
  padding-left: 70px !important;
  padding-right: 70px !important;
}
.py-70 {
  padding-top: 70px !important;
  padding-bottom: 70px !important;
}
.m-70 {
  margin: 70px !important;
}
.mt-70 {
  margin-top: 70px !important;
}
.ml-70 {
  margin-left: 70px !important;
}
.mr-70 {
  margin-right: 70px !important;
}
.mb-70 {
  margin-bottom: 70px !important;
}
.mx-70 {
  margin-left: 70px !important;
  margin-right: 70px !important;
}
.my-70 {
  margin-top: 70px !important;
  margin-bottom: 70px !important;
}
.p-60 {
  padding: 60px !important;
}
.pt-60 {
  padding-top: 60px !important;
}
.pl-60 {
  padding-left: 60px !important;
}
.pr-60 {
  padding-right: 60px !important;
}
.pb-60 {
  padding-bottom: 60px !important;
}
.px-60 {
  padding-left: 60px !important;
  padding-right: 60px !important;
}
.py-60 {
  padding-top: 60px !important;
  padding-bottom: 60px !important;
}
.m-60 {
  margin: 60px !important;
}
.mt-60 {
  margin-top: 60px !important;
}
.ml-60 {
  margin-left: 60px !important;
}
.mr-60 {
  margin-right: 60px !important;
}
.mb-60 {
  margin-bottom: 60px !important;
}
.mx-60 {
  margin-left: 60px !important;
  margin-right: 60px !important;
}
.my-60 {
  margin-top: 60px !important;
  margin-bottom: 60px !important;
}
.p-50 {
  padding: 50px !important;
}
.pt-50 {
  padding-top: 50px !important;
}
.pl-50 {
  padding-left: 50px !important;
}
.pr-50 {
  padding-right: 50px !important;
}
.pb-50 {
  padding-bottom: 50px !important;
}
.px-50 {
  padding-left: 50px !important;
  padding-right: 50px !important;
}
.py-50 {
  padding-top: 50px !important;
  padding-bottom: 50px !important;
}
.m-50 {
  margin: 50px !important;
}
.mt-50 {
  margin-top: 50px !important;
}
.ml-50 {
  margin-left: 50px !important;
}
.mr-50 {
  margin-right: 50px !important;
}
.mb-50 {
  margin-bottom: 50px !important;
}
.mx-50 {
  margin-left: 50px !important;
  margin-right: 50px !important;
}
.my-50 {
  margin-top: 50px !important;
  margin-bottom: 50px !important;
}

.p-45 {
  padding: 45px !important;
}
.px-40 {
  padding-left: 45px !important;
  padding-right: 45px !important;
}

.pt-45 {
  padding-top: 45px !important;
}
.pb-45 {
  padding-bottom: 45px !important;
}
.py-45 {
  padding-top: 45px !important;
  padding-bottom: 45px !important;
}

.p-40 {
  padding: 40px !important;
}
.pt-40 {
  padding-top: 40px !important;
}
.pl-40 {
  padding-left: 40px !important;
}
.pr-40 {
  padding-right: 40px !important;
}
.pb-40 {
  padding-bottom: 40px !important;
}
.px-40 {
  padding-left: 40px !important;
  padding-right: 40px !important;
}
.py-40 {
  padding-top: 40px !important;
  padding-bottom: 40px !important;
}
.m-40 {
  margin: 40px !important;
}
.mt-40 {
  margin-top: 40px !important;
}
.ml-40 {
  margin-left: 40px !important;
}
.mr-40 {
  margin-right: 40px !important;
}
.mb-40 {
  margin-bottom: 40px !important;
}
.mx-40 {
  margin-left: 40px !important;
  margin-right: 40px !important;
}
.my-40 {
  margin-top: 40px !important;
  margin-bottom: 40px !important;
}
.p-30 {
  padding: 30px !important;
}
.pt-30 {
  padding-top: 30px !important;
}
.pl-30 {
  padding-left: 30px !important;
}
.pr-30 {
  padding-right: 30px !important;
}
.pb-30 {
  padding-bottom: 30px !important;
}
.px-30 {
  padding-left: 30px !important;
  padding-right: 30px !important;
}
.py-30 {
  padding-top: 30px !important;
  padding-bottom: 30px !important;
}
.m-30 {
  margin: 30px !important;
}
.mt-30 {
  margin-top: 30px !important;
}
.ml-30 {
  margin-left: 30px !important;
}
.mr-30 {
  margin-right: 30px !important;
}
.mb-30 {
  margin-bottom: 30px !important;
}
.mx-30 {
  margin-left: 30px !important;
  margin-right: 30px !important;
}
.my-30 {
  margin-top: 30px !important;
  margin-bottom: 30px !important;
}
.p-25 {
  padding: 25px !important;
}
.pt-25 {
  padding-top: 25px !important;
}
.pl-25 {
  padding-left: 25px !important;
}
.pr-25 {
  padding-right: 25px !important;
}
.pb-25 {
  padding-bottom: 25px !important;
}
.px-25 {
  padding-left: 25px !important;
  padding-right: 25px !important;
}
.py-25 {
  padding-top: 25px !important;
  padding-bottom: 25px !important;
}
.m-25 {
  margin: 25px !important;
}
.mt-25 {
  margin-top: 25px !important;
}
.ml-25 {
  margin-left: 25px !important;
}
.mr-25 {
  margin-right: 25px !important;
}
.mb-25 {
  margin-bottom: 25px !important;
}
.mx-25 {
  margin-left: 25px !important;
  margin-right: 25px !important;
}
.my-25 {
  margin-top: 25px !important;
  margin-bottom: 25px !important;
}
.p-20 {
  padding: 20px !important;
}
.pt-20 {
  padding-top: 20px !important;
}
.pl-20 {
  padding-left: 20px !important;
}
.pr-20 {
  padding-right: 20px !important;
}
.pb-20 {
  padding-bottom: 20px !important;
}
.px-20 {
  padding-left: 20px !important;
  padding-right: 20px !important;
}
.py-20 {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}
.m-20 {
  margin: 20px !important;
}
.mt-20 {
  margin-top: 20px !important;
}
.ml-20 {
  margin-left: 20px !important;
}
.mr-20 {
  margin-right: 20px !important;
}
.mb-20 {
  margin-bottom: 20px !important;
}
.mx-20 {
  margin-left: 20px !important;
  margin-right: 20px !important;
}
.my-20 {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}
.p-15 {
  padding: 15px !important;
}
.pt-15 {
  padding-top: 15px !important;
}
.pl-15 {
  padding-left: 15px !important;
}
.pr-15 {
  padding-right: 15px !important;
}
.pb-15 {
  padding-bottom: 15px !important;
}
.px-15 {
  padding-left: 15px !important;
  padding-right: 15px !important;
}
.py-15 {
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}
.m-15 {
  margin: 15px !important;
}
.mt-15 {
  margin-top: 15px !important;
}
.ml-15 {
  margin-left: 15px !important;
}
.mr-15 {
  margin-right: 15px !important;
}
.mb-15 {
  margin-bottom: 15px !important;
}
.mx-15 {
  margin-left: 15px !important;
  margin-right: 15px !important;
}
.my-15 {
  margin-top: 15px !important;
  margin-bottom: 15px !important;
}
.p-10 {
  padding: 10px !important;
}
.pt-10 {
  padding-top: 10px !important;
}
.pl-10 {
  padding-left: 10px !important;
}
.pr-10 {
  padding-right: 10px !important;
}
.pb-10 {
  padding-bottom: 10px !important;
}
.px-10 {
  padding-left: 10px !important;
  padding-right: 10px !important;
}
.py-10 {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}
.m-10 {
  margin: 10px !important;
}
.mt-10 {
  margin-top: 10px !important;
}
.ml-10 {
  margin-left: 10px !important;
}
.mr-10 {
  margin-right: 10px !important;
}
.mb-10 {
  margin-bottom: 10px !important;
}
.mx-10 {
  margin-left: 10px !important;
  margin-right: 10px !important;
}
.my-10 {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}
.p-5 {
  padding: 5px !important;
}
.pt-5 {
  padding-top: 5px !important;
}
.pl-5 {
  padding-left: 5px !important;
}
.pr-5 {
  padding-right: 5px !important;
}
.pb-5 {
  padding-bottom: 5px !important;
}
.px-5 {
  padding-left: 5px !important;
  padding-right: 5px !important;
}
.py-5 {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}
.m-5 {
  margin: 5px !important;
}
.mt-5 {
  margin-top: 5px !important;
}
.ml-5 {
  margin-left: 5px !important;
}
.mr-5 {
  margin-right: 5px !important;
}
.mb-5 {
  margin-bottom: 5px !important;
}
.mx-5 {
  margin-left: 5px !important;
  margin-right: 5px !important;
}
.my-5 {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}
.p-0 {
  padding: 0 !important;
}
.pt-0 {
  padding-top: 0 !important;
}
.pl-0 {
  padding-left: 0 !important;
}
.pr-0 {
  padding-right: 0 !important;
}
.pb-0 {
  padding-bottom: 0 !important;
}
.px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.m-0 {
  margin: 0 !important;
}
.mt-0 {
  margin-top: 0 !important;
}
.ml-0 {
  margin-left: 0 !important;
}
.mr-0 {
  margin-right: 0 !important;
}
.mb-0 {
  margin-bottom: 0 !important;
}
.mx-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
  display: block;
}
.ml-auto {
  margin-left: auto !important;
}
.mr-auto {
  margin-right: auto !important;
}
.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
@media (max-width: 767px) {
  .pt-xs-64 {
    padding-top: 64px !important;
  }
  .pb-xs-64 {
    padding-bottom: 64px !important;
  }
  .py-xs-64 {
    padding-top: 64px !important;
    padding-bottom: 64px !important;
  }
  .mt-xs-64 {
    margin-top: 64px !important;
  }
  .mb-xs-64 {
    margin-bottom: 64px !important;
  }
  .my-xs-64 {
    margin-top: 64px !important;
    margin-bottom: 64px !important;
  }
  .px-xs-64 {
    padding-left: 64px !important;
    padding-right: 64px !important;
  }
  .pt-xs-56 {
    padding-top: 56px !important;
  }
  .pb-xs-56 {
    padding-bottom: 56px !important;
  }
  .py-xs-56 {
    padding-top: 56px !important;
    padding-bottom: 56px !important;
  }
  .mt-xs-56 {
    margin-top: 56px !important;
  }
  .mb-xs-56 {
    margin-bottom: 56px !important;
  }
  .my-xs-56 {
    margin-top: 56px !important;
    margin-bottom: 56px !important;
  }
  .px-xs-56 {
    padding-left: 56px !important;
    padding-right: 56px !important;
  }
  .pt-xs-48 {
    padding-top: 48px !important;
  }
  .pb-xs-48 {
    padding-bottom: 48px !important;
  }
  .py-xs-48 {
    padding-top: 48px !important;
    padding-bottom: 48px !important;
  }
  .mt-xs-48 {
    margin-top: 48px !important;
  }
  .mb-xs-48 {
    margin-bottom: 48px !important;
  }
  .my-xs-48 {
    margin-top: 48px !important;
    margin-bottom: 48px !important;
  }
  .px-xs-48 {
    padding-left: 48px !important;
    padding-right: 48px !important;
  }
  .pt-xs-44 {
    padding-top: 44px !important;
  }
  .pb-xs-44 {
    padding-bottom: 44px !important;
  }
  .py-xs-44 {
    padding-top: 44px !important;
    padding-bottom: 44px !important;
  }
  .mt-xs-44 {
    margin-top: 44px !important;
  }
  .mb-xs-44 {
    margin-bottom: 44px !important;
  }
  .my-xs-44 {
    margin-top: 44px !important;
    margin-bottom: 44px !important;
  }
  .px-xs-44 {
    padding-left: 44px !important;
    padding-right: 44px !important;
  }
  .pt-xs-36 {
    padding-top: 36px !important;
  }
  .pb-xs-36 {
    padding-bottom: 36px !important;
  }
  .py-xs-36 {
    padding-top: 36px !important;
    padding-bottom: 36px !important;
  }
  .mt-xs-36 {
    margin-top: 36px !important;
  }
  .mb-xs-36 {
    margin-bottom: 36px !important;
  }

  .mb-xs-33 {
    margin-bottom: 36px !important;
  }
  .my-xs-36 {
    margin-top: 36px !important;
    margin-bottom: 36px !important;
  }
  .px-xs-36 {
    padding-left: 36px !important;
    padding-right: 36px !important;
  }
  .pt-xs-24 {
    padding-top: 24px !important;
  }
  .pb-xs-24 {
    padding-bottom: 24px !important;
  }
  .py-xs-24 {
    padding-top: 24px !important;
    padding-bottom: 24px !important;
  }
  .mt-xs-24 {
    margin-top: 24px !important;
  }
  .mb-xs-24 {
    margin-bottom: 24px !important;
  }
  .my-xs-24 {
    margin-top: 24px !important;
    margin-bottom: 24px !important;
  }
  .px-xs-24 {
    padding-left: 24px !important;
    padding-right: 24px !important;
  }
  .pt-xs-18 {
    padding-top: 18px !important;
  }
  .pb-xs-18 {
    padding-bottom: 18px !important;
  }
  .py-xs-18 {
    padding-top: 18px !important;
    padding-bottom: 18px !important;
  }
  .mt-xs-18 {
    margin-top: 18px !important;
  }
  .mb-xs-18 {
    margin-bottom: 18px !important;
  }
  .my-xs-18 {
    margin-top: 18px !important;
    margin-bottom: 18px !important;
  }
  .px-xs-18 {
    padding-left: 18px !important;
    padding-right: 18px !important;
  }
  .pt-xs-12 {
    padding-top: 12px !important;
  }
  .pb-xs-12 {
    padding-bottom: 12px !important;
  }
  .py-xs-12 {
    padding-top: 12px !important;
    padding-bottom: 12px !important;
  }
  .mt-xs-12 {
    margin-top: 12px !important;
  }
  .mb-xs-12 {
    margin-bottom: 12px !important;
  }
  .my-xs-12 {
    margin-top: 12px !important;
    margin-bottom: 12px !important;
  }
  .px-xs-12 {
    padding-left: 12px !important;
    padding-right: 12px !important;
  }
  .p-xs-140 {
    padding: 140px !important;
  }
  .pt-xs-140 {
    padding-top: 140px !important;
  }
  .pl-xs-140 {
    padding-left: 140px !important;
  }
  .pr-xs-140 {
    padding-right: 140px !important;
  }
  .pb-xs-140 {
    padding-bottom: 140px !important;
  }
  .px-xs-140 {
    padding-left: 140px !important;
    padding-right: 140px !important;
  }
  .py-xs-140 {
    padding-top: 140px !important;
    padding-bottom: 140px !important;
  }
  .m-xs-140 {
    margin: 140px !important;
  }
  .mt-xs-140 {
    margin-top: 140px !important;
  }
  .ml-xs-140 {
    margin-left: 140px !important;
  }
  .mr-xs-140 {
    margin-right: 140px !important;
  }
  .mb-xs-140 {
    margin-bottom: 140px !important;
  }
  .mx-xs-140 {
    margin-left: 140px !important;
    margin-right: 140px !important;
  }
  .my-xs-140 {
    margin-top: 140px !important;
    margin-bottom: 140px !important;
  }
  .p-xs-120 {
    padding: 120px !important;
  }
  .pt-xs-120 {
    padding-top: 120px !important;
  }
  .pl-xs-120 {
    padding-left: 120px !important;
  }
  .pr-xs-120 {
    padding-right: 120px !important;
  }
  .pb-xs-120 {
    padding-bottom: 120px !important;
  }
  .px-xs-120 {
    padding-left: 120px !important;
    padding-right: 120px !important;
  }
  .py-xs-120 {
    padding-top: 120px !important;
    padding-bottom: 120px !important;
  }
  .m-xs-120 {
    margin: 120px !important;
  }
  .mt-xs-120 {
    margin-top: 120px !important;
  }
  .ml-xs-120 {
    margin-left: 120px !important;
  }
  .mr-xs-120 {
    margin-right: 120px !important;
  }
  .mb-xs-120 {
    margin-bottom: 120px !important;
  }
  .mx-xs-120 {
    margin-left: 120px !important;
    margin-right: 120px !important;
  }
  .my-xs-120 {
    margin-top: 120px !important;
    margin-bottom: 120px !important;
  }
  .p-xs-100 {
    padding: 100px !important;
  }
  .pt-xs-100 {
    padding-top: 100px !important;
  }
  .pl-xs-100 {
    padding-left: 100px !important;
  }
  .pr-xs-100 {
    padding-right: 100px !important;
  }
  .pb-xs-100 {
    padding-bottom: 100px !important;
  }
  .px-xs-100 {
    padding-left: 100px !important;
    padding-right: 100px !important;
  }
  .py-xs-100 {
    padding-top: 100px !important;
    padding-bottom: 100px !important;
  }
  .m-xs-100 {
    margin: 100px !important;
  }
  .mt-xs-100 {
    margin-top: 100px !important;
  }
  .ml-xs-100 {
    margin-left: 100px !important;
  }
  .mr-xs-100 {
    margin-right: 100px !important;
  }
  .mb-xs-100 {
    margin-bottom: 100px !important;
  }
  .mx-xs-100 {
    margin-left: 100px !important;
    margin-right: 100px !important;
  }
  .my-xs-100 {
    margin-top: 100px !important;
    margin-bottom: 100px !important;
  }
  .p-xs-90 {
    padding: 90px !important;
  }
  .pt-xs-90 {
    padding-top: 90px !important;
  }
  .pl-xs-90 {
    padding-left: 90px !important;
  }
  .pr-xs-90 {
    padding-right: 90px !important;
  }
  .pb-xs-90 {
    padding-bottom: 90px !important;
  }
  .px-xs-90 {
    padding-left: 90px !important;
    padding-right: 90px !important;
  }
  .py-xs-90 {
    padding-top: 90px !important;
    padding-bottom: 90px !important;
  }
  .m-xs-90 {
    margin: 90px !important;
  }
  .mt-xs-90 {
    margin-top: 90px !important;
  }
  .ml-xs-90 {
    margin-left: 90px !important;
  }
  .mr-xs-90 {
    margin-right: 90px !important;
  }
  .mb-xs-90 {
    margin-bottom: 90px !important;
  }
  .mx-xs-90 {
    margin-left: 90px !important;
    margin-right: 90px !important;
  }
  .my-xs-90 {
    margin-top: 90px !important;
    margin-bottom: 90px !important;
  }
  .p-xs-80 {
    padding: 80px !important;
  }
  .pt-xs-80 {
    padding-top: 80px !important;
  }
  .pl-xs-80 {
    padding-left: 80px !important;
  }
  .pr-xs-80 {
    padding-right: 80px !important;
  }
  .pb-xs-80 {
    padding-bottom: 80px !important;
  }
  .px-xs-80 {
    padding-left: 80px !important;
    padding-right: 80px !important;
  }
  .py-xs-80 {
    padding-top: 80px !important;
    padding-bottom: 80px !important;
  }
  .m-xs-80 {
    margin: 80px !important;
  }
  .mt-xs-80 {
    margin-top: 80px !important;
  }
  .ml-xs-80 {
    margin-left: 80px !important;
  }
  .mr-xs-80 {
    margin-right: 80px !important;
  }
  .mb-xs-80 {
    margin-bottom: 80px !important;
  }
  .mx-xs-80 {
    margin-left: 80px !important;
    margin-right: 80px !important;
  }
  .my-xs-80 {
    margin-top: 80px !important;
    margin-bottom: 80px !important;
  }
  .p-xs-70 {
    padding: 70px !important;
  }
  .pt-xs-70 {
    padding-top: 70px !important;
  }
  .pl-xs-70 {
    padding-left: 70px !important;
  }
  .pr-xs-70 {
    padding-right: 70px !important;
  }
  .pb-xs-70 {
    padding-bottom: 70px !important;
  }
  .px-xs-70 {
    padding-left: 70px !important;
    padding-right: 70px !important;
  }
  .py-xs-70 {
    padding-top: 70px !important;
    padding-bottom: 70px !important;
  }
  .m-xs-70 {
    margin: 70px !important;
  }
  .mt-xs-70 {
    margin-top: 70px !important;
  }
  .ml-xs-70 {
    margin-left: 70px !important;
  }
  .mr-xs-70 {
    margin-right: 70px !important;
  }
  .mb-xs-70 {
    margin-bottom: 70px !important;
  }
  .mx-xs-70 {
    margin-left: 70px !important;
    margin-right: 70px !important;
  }
  .my-xs-70 {
    margin-top: 70px !important;
    margin-bottom: 70px !important;
  }
  .p-xs-60 {
    padding: 60px !important;
  }
  .pt-xs-60 {
    padding-top: 60px !important;
  }
  .pl-xs-60 {
    padding-left: 60px !important;
  }
  .pr-xs-60 {
    padding-right: 60px !important;
  }
  .pb-xs-60 {
    padding-bottom: 60px !important;
  }
  .px-xs-60 {
    padding-left: 60px !important;
    padding-right: 60px !important;
  }
  .py-xs-60 {
    padding-top: 60px !important;
    padding-bottom: 60px !important;
  }
  .m-xs-60 {
    margin: 60px !important;
  }
  .mt-xs-60 {
    margin-top: 60px !important;
  }
  .ml-xs-60 {
    margin-left: 60px !important;
  }
  .mr-xs-60 {
    margin-right: 60px !important;
  }
  .mb-xs-60 {
    margin-bottom: 60px !important;
  }
  .mx-xs-60 {
    margin-left: 60px !important;
    margin-right: 60px !important;
  }
  .my-xs-60 {
    margin-top: 60px !important;
    margin-bottom: 60px !important;
  }
  .p-xs-50 {
    padding: 50px !important;
  }
  .pt-xs-50 {
    padding-top: 50px !important;
  }
  .pl-xs-50 {
    padding-left: 50px !important;
  }
  .pr-xs-50 {
    padding-right: 50px !important;
  }
  .pb-xs-50 {
    padding-bottom: 50px !important;
  }
  .px-xs-50 {
    padding-left: 50px !important;
    padding-right: 50px !important;
  }
  .py-xs-50 {
    padding-top: 50px !important;
    padding-bottom: 50px !important;
  }
  .m-xs-50 {
    margin: 50px !important;
  }
  .mt-xs-50 {
    margin-top: 50px !important;
  }
  .ml-xs-50 {
    margin-left: 50px !important;
  }
  .mr-xs-50 {
    margin-right: 50px !important;
  }
  .mb-xs-50 {
    margin-bottom: 50px !important;
  }
  .mx-xs-50 {
    margin-left: 50px !important;
    margin-right: 50px !important;
  }
  .my-xs-50 {
    margin-top: 50px !important;
    margin-bottom: 50px !important;
  }

  .p-xs-45 {
    padding: 45px !important;
  }
  .pt-xs-45 {
    padding-top: 45px !important;
  }
  .pl-xs-45 {
    padding-left: 45px !important;
  }
  .pr-xs-45 {
    padding-right: 45px !important;
  }
  .pb-xs-45 {
    padding-bottom: 45px !important;
  }
  .px-xs-45 {
    padding-left: 45px !important;
    padding-right: 45px !important;
  }
  .py-xs-45 {
    padding-top: 45px !important;
    padding-bottom: 45px !important;
  }

  .p-xs-40 {
    padding: 40px !important;
  }
  .pt-xs-40 {
    padding-top: 40px !important;
  }
  .pl-xs-40 {
    padding-left: 40px !important;
  }
  .pr-xs-40 {
    padding-right: 40px !important;
  }
  .pb-xs-40 {
    padding-bottom: 40px !important;
  }
  .px-xs-40 {
    padding-left: 40px !important;
    padding-right: 40px !important;
  }
  .py-xs-40 {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }
  .m-xs-40 {
    margin: 40px !important;
  }
  .mt-xs-40 {
    margin-top: 40px !important;
  }
  .ml-xs-40 {
    margin-left: 40px !important;
  }
  .mr-xs-40 {
    margin-right: 40px !important;
  }
  .mb-xs-40 {
    margin-bottom: 40px !important;
  }
  .mx-xs-40 {
    margin-left: 40px !important;
    margin-right: 40px !important;
  }
  .my-xs-40 {
    margin-top: 40px !important;
    margin-bottom: 40px !important;
  }
  .p-xs-30 {
    padding: 30px !important;
  }
  .pt-xs-30 {
    padding-top: 30px !important;
  }
  .pl-xs-30 {
    padding-left: 30px !important;
  }
  .pr-xs-30 {
    padding-right: 30px !important;
  }
  .pb-xs-30 {
    padding-bottom: 30px !important;
  }
  .px-xs-30 {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
  .py-xs-30 {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }
  .m-xs-30 {
    margin: 30px !important;
  }
  .mt-xs-30 {
    margin-top: 30px !important;
  }
  .ml-xs-30 {
    margin-left: 30px !important;
  }
  .mr-xs-30 {
    margin-right: 30px !important;
  }
  .mb-xs-30 {
    margin-bottom: 30px !important;
  }
  .mx-xs-30 {
    margin-left: 30px !important;
    margin-right: 30px !important;
  }
  .my-xs-30 {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }
  .p-xs-25 {
    padding: 25px !important;
  }
  .pt-xs-25 {
    padding-top: 25px !important;
  }
  .pl-xs-25 {
    padding-left: 25px !important;
  }
  .pr-xs-25 {
    padding-right: 25px !important;
  }
  .pb-xs-25 {
    padding-bottom: 25px !important;
  }
  .px-xs-25 {
    padding-left: 25px !important;
    padding-right: 25px !important;
  }
  .py-xs-25 {
    padding-top: 25px !important;
    padding-bottom: 25px !important;
  }
  .m-xs-25 {
    margin: 25px !important;
  }
  .mt-xs-25 {
    margin-top: 25px !important;
  }
  .ml-xs-25 {
    margin-left: 25px !important;
  }
  .mr-xs-25 {
    margin-right: 25px !important;
  }
  .mb-xs-25 {
    margin-bottom: 25px !important;
  }
  .mx-xs-25 {
    margin-left: 25px !important;
    margin-right: 25px !important;
  }
  .my-xs-25 {
    margin-top: 25px !important;
    margin-bottom: 25px !important;
  }
  .p-xs-20 {
    padding: 20px !important;
  }
  .pt-xs-20 {
    padding-top: 20px !important;
  }
  .pl-xs-20 {
    padding-left: 20px !important;
  }
  .pr-xs-20 {
    padding-right: 20px !important;
  }
  .pb-xs-20 {
    padding-bottom: 20px !important;
  }
  .px-xs-20 {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  .py-xs-20 {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }
  .m-xs-20 {
    margin: 20px !important;
  }
  .mt-xs-20 {
    margin-top: 20px !important;
  }
  .ml-xs-20 {
    margin-left: 20px !important;
  }
  .mr-xs-20 {
    margin-right: 20px !important;
  }
  .mb-xs-20 {
    margin-bottom: 20px !important;
  }
  .mx-xs-20 {
    margin-left: 20px !important;
    margin-right: 20px !important;
  }
  .my-xs-20 {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }
  .p-xs-15 {
    padding: 15px !important;
  }
  .pt-xs-15 {
    padding-top: 15px !important;
  }
  .pl-xs-15 {
    padding-left: 15px !important;
  }
  .pr-xs-15 {
    padding-right: 15px !important;
  }
  .pb-xs-15 {
    padding-bottom: 15px !important;
  }
  .px-xs-15 {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  .py-xs-15 {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }
  .m-xs-15 {
    margin: 15px !important;
  }
  .mt-xs-15 {
    margin-top: 15px !important;
  }
  .ml-xs-15 {
    margin-left: 15px !important;
  }
  .mr-xs-15 {
    margin-right: 15px !important;
  }
  .mb-xs-15 {
    margin-bottom: 15px !important;
  }
  .mx-xs-15 {
    margin-left: 15px !important;
    margin-right: 15px !important;
  }
  .my-xs-15 {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
  }
  .p-xs-10 {
    padding: 10px !important;
  }
  .pt-xs-10 {
    padding-top: 10px !important;
  }
  .pl-xs-10 {
    padding-left: 10px !important;
  }
  .pr-xs-10 {
    padding-right: 10px !important;
  }
  .pb-xs-10 {
    padding-bottom: 10px !important;
  }
  .px-xs-10 {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  .py-xs-10 {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }
  .m-xs-10 {
    margin: 10px !important;
  }
  .mt-xs-10 {
    margin-top: 10px !important;
  }
  .ml-xs-10 {
    margin-left: 10px !important;
  }
  .mr-xs-10 {
    margin-right: 10px !important;
  }
  .mb-xs-10 {
    margin-bottom: 10px !important;
  }
  .mx-xs-10 {
    margin-left: 10px !important;
    margin-right: 10px !important;
  }
  .my-xs-10 {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }
  .p-xs-5 {
    padding: 5px !important;
  }
  .pt-xs-5 {
    padding-top: 5px !important;
  }
  .pl-xs-5 {
    padding-left: 5px !important;
  }
  .pr-xs-5 {
    padding-right: 5px !important;
  }
  .pb-xs-5 {
    padding-bottom: 5px !important;
  }
  .px-xs-5 {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
  .py-xs-5 {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }
  .m-xs-5 {
    margin: 5px !important;
  }
  .mt-xs-5 {
    margin-top: 5px !important;
  }
  .ml-xs-5 {
    margin-left: 5px !important;
  }
  .mr-xs-5 {
    margin-right: 5px !important;
  }
  .mb-xs-5 {
    margin-bottom: 5px !important;
  }
  .mx-xs-5 {
    margin-left: 5px !important;
    margin-right: 5px !important;
  }
  .my-xs-5 {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
  }
  .p-xs-0 {
    padding: 0 !important;
  }
  .pt-xs-0 {
    padding-top: 0 !important;
  }
  .pl-xs-0 {
    padding-left: 0 !important;
  }
  .pr-xs-0 {
    padding-right: 0 !important;
  }
  .pb-xs-0 {
    padding-bottom: 0 !important;
  }
  .px-xs-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .py-xs-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .m-xs-0 {
    margin: 0 !important;
  }
  .mt-xs-0 {
    margin-top: 0 !important;
  }
  .ml-xs-0 {
    margin-left: 0 !important;
  }
  .mr-xs-0 {
    margin-right: 0 !important;
  }
  .mb-xs-0 {
    margin-bottom: 0 !important;
  }
  .mx-xs-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .mx-xs-auto {
    margin-left: auto !important;
    margin-right: auto !important;
    display: block;
  }
  .ml-xs-auto {
    margin-left: auto !important;
  }
  .mr-xs-auto {
    margin-right: auto !important;
  }
  .my-xs-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .height-xs-20 {
    min-height: 20px;
  }
  .height-xs-25 {
    min-height: 25px;
  }
  .height-xs-30 {
    min-height: 30px;
  }
  .height-xs-35 {
    min-height: 35px;
  }
  .height-xs-40 {
    min-height: 40px;
  }
  .height-xs-45 {
    min-height: 45px;
  }
  .height-xs-50 {
    min-height: 50px;
  }
  .height-xs-55 {
    min-height: 55px;
  }
  .height-xs-60 {
    min-height: 60px;
  }
  .height-xs-65 {
    min-height: 65px;
  }
  .height-xs-70 {
    min-height: 70px;
  }
  .height-xs-80 {
    min-height: 80px;
  }
  .height-xs-90 {
    min-height: 90px;
  }
  .height-xs-100 {
    min-height: 100px;
  }
  .height-xs-110 {
    min-height: 110px;
  }
  .height-xs-120 {
    min-height: 120px;
  }
  .height-xs-130 {
    min-height: 130px;
  }
  .height-xs-140 {
    min-height: 140px;
  }
  .height-xs-150 {
    min-height: 150px;
  }
  .height-xs-160 {
    min-height: 160px;
  }
  .height-xs-170 {
    min-height: 170px;
  }
  .height-xs-180 {
    min-height: 180px;
  }
  .height-xs-190 {
    min-height: 190px;
  }
  .height-xs-200 {
    min-height: 200px;
  }
}
@media (min-width: 768px) {
  .pt-sm-64 {
    padding-top: 64px !important;
  }
  .pb-sm-64 {
    padding-bottom: 64px !important;
  }
  .py-sm-64 {
    padding-top: 64px !important;
    padding-bottom: 64px !important;
  }
  .mt-sm-64 {
    margin-top: 64px !important;
  }
  .mb-sm-64 {
    margin-bottom: 64px !important;
  }
  .my-sm-64 {
    margin-top: 64px !important;
    margin-bottom: 64px !important;
  }
  .px-sm-64 {
    padding-left: 64px !important;
    padding-right: 64px !important;
  }
  .pt-sm-56 {
    padding-top: 56px !important;
  }
  .pb-sm-56 {
    padding-bottom: 56px !important;
  }
  .py-sm-56 {
    padding-top: 56px !important;
    padding-bottom: 56px !important;
  }
  .mt-sm-56 {
    margin-top: 56px !important;
  }
  .mb-sm-56 {
    margin-bottom: 56px !important;
  }
  .my-sm-56 {
    margin-top: 56px !important;
    margin-bottom: 56px !important;
  }
  .px-sm-56 {
    padding-left: 56px !important;
    padding-right: 56px !important;
  }
  .pt-sm-48 {
    padding-top: 48px !important;
  }
  .pb-sm-48 {
    padding-bottom: 48px !important;
  }
  .py-sm-48 {
    padding-top: 48px !important;
    padding-bottom: 48px !important;
  }
  .mt-sm-48 {
    margin-top: 48px !important;
  }
  .mb-sm-48 {
    margin-bottom: 48px !important;
  }
  .my-sm-48 {
    margin-top: 48px !important;
    margin-bottom: 48px !important;
  }
  .px-sm-48 {
    padding-left: 48px !important;
    padding-right: 48px !important;
  }
  .pt-sm-44 {
    padding-top: 44px !important;
  }
  .pb-sm-44 {
    padding-bottom: 44px !important;
  }
  .py-sm-44 {
    padding-top: 44px !important;
    padding-bottom: 44px !important;
  }
  .mt-sm-44 {
    margin-top: 44px !important;
  }
  .mb-sm-44 {
    margin-bottom: 44px !important;
  }
  .my-sm-44 {
    margin-top: 44px !important;
    margin-bottom: 44px !important;
  }
  .px-sm-44 {
    padding-left: 44px !important;
    padding-right: 44px !important;
  }
  .pt-sm-36 {
    padding-top: 36px !important;
  }
  .pb-sm-36 {
    padding-bottom: 36px !important;
  }
  .py-sm-36 {
    padding-top: 36px !important;
    padding-bottom: 36px !important;
  }
  .mt-sm-36 {
    margin-top: 36px !important;
  }
  .mb-sm-36 {
    margin-bottom: 36px !important;
  }
  .my-sm-36 {
    margin-top: 36px !important;
    margin-bottom: 36px !important;
  }
  .px-sm-36 {
    padding-left: 36px !important;
    padding-right: 36px !important;
  }
  .pt-sm-24 {
    padding-top: 24px !important;
  }
  .pb-sm-24 {
    padding-bottom: 24px !important;
  }
  .py-sm-24 {
    padding-top: 24px !important;
    padding-bottom: 24px !important;
  }
  .mt-sm-24 {
    margin-top: 24px !important;
  }
  .mb-sm-24 {
    margin-bottom: 24px !important;
  }
  .my-sm-24 {
    margin-top: 24px !important;
    margin-bottom: 24px !important;
  }
  .px-sm-24 {
    padding-left: 24px !important;
    padding-right: 24px !important;
  }
  .pt-sm-18 {
    padding-top: 18px !important;
  }
  .pb-sm-18 {
    padding-bottom: 18px !important;
  }
  .py-sm-18 {
    padding-top: 18px !important;
    padding-bottom: 18px !important;
  }
  .mt-sm-18 {
    margin-top: 18px !important;
  }
  .mb-sm-18 {
    margin-bottom: 18px !important;
  }
  .my-sm-18 {
    margin-top: 18px !important;
    margin-bottom: 18px !important;
  }
  .px-sm-18 {
    padding-left: 18px !important;
    padding-right: 18px !important;
  }
  .pt-sm-12 {
    padding-top: 12px !important;
  }
  .pb-sm-12 {
    padding-bottom: 12px !important;
  }
  .py-sm-12 {
    padding-top: 12px !important;
    padding-bottom: 12px !important;
  }
  .mt-sm-12 {
    margin-top: 12px !important;
  }
  .mb-sm-12 {
    margin-bottom: 12px !important;
  }
  .my-sm-12 {
    margin-top: 12px !important;
    margin-bottom: 12px !important;
  }
  .px-sm-12 {
    padding-left: 12px !important;
    padding-right: 12px !important;
  }
  .p-sm-200 {
    padding: 200px !important;
  }
  .pt-sm-200 {
    padding-top: 200px !important;
  }
  .pl-sm-200 {
    padding-left: 200px !important;
  }
  .pr-sm-200 {
    padding-right: 200px !important;
  }
  .pb-sm-200 {
    padding-bottom: 200px !important;
  }
  .px-sm-200 {
    padding-left: 200px !important;
    padding-right: 200px !important;
  }
  .py-sm-200 {
    padding-top: 200px !important;
    padding-bottom: 200px !important;
  }
  .m-sm-200 {
    margin: 200px !important;
  }
  .mt-sm-200 {
    margin-top: 200px !important;
  }
  .ml-sm-200 {
    margin-left: 200px !important;
  }
  .mr-sm-200 {
    margin-right: 200px !important;
  }
  .mb-sm-200 {
    margin-bottom: 200px !important;
  }
  .mx-sm-200 {
    margin-left: 200px !important;
    margin-right: 200px !important;
  }
  .my-sm-200 {
    margin-top: 200px !important;
    margin-bottom: 200px !important;
  }
  .p-sm-180 {
    padding: 180px !important;
  }
  .pt-sm-180 {
    padding-top: 180px !important;
  }
  .pl-sm-180 {
    padding-left: 180px !important;
  }
  .pr-sm-180 {
    padding-right: 180px !important;
  }
  .pb-sm-180 {
    padding-bottom: 180px !important;
  }
  .px-sm-180 {
    padding-left: 180px !important;
    padding-right: 180px !important;
  }
  .py-sm-180 {
    padding-top: 180px !important;
    padding-bottom: 180px !important;
  }
  .m-sm-180 {
    margin: 180px !important;
  }
  .mt-sm-180 {
    margin-top: 180px !important;
  }
  .ml-sm-180 {
    margin-left: 180px !important;
  }
  .mr-sm-180 {
    margin-right: 180px !important;
  }
  .mb-sm-180 {
    margin-bottom: 180px !important;
  }
  .mx-sm-180 {
    margin-left: 180px !important;
    margin-right: 180px !important;
  }
  .my-sm-180 {
    margin-top: 180px !important;
    margin-bottom: 180px !important;
  }
  .p-sm-160 {
    padding: 160px !important;
  }
  .pt-sm-160 {
    padding-top: 160px !important;
  }
  .pl-sm-160 {
    padding-left: 160px !important;
  }
  .pr-sm-160 {
    padding-right: 160px !important;
  }
  .pb-sm-160 {
    padding-bottom: 160px !important;
  }
  .px-sm-160 {
    padding-left: 160px !important;
    padding-right: 160px !important;
  }
  .py-sm-160 {
    padding-top: 160px !important;
    padding-bottom: 160px !important;
  }
  .m-sm-160 {
    margin: 160px !important;
  }
  .mt-sm-160 {
    margin-top: 160px !important;
  }
  .ml-sm-160 {
    margin-left: 160px !important;
  }
  .mr-sm-160 {
    margin-right: 160px !important;
  }
  .mb-sm-160 {
    margin-bottom: 160px !important;
  }
  .mx-sm-160 {
    margin-left: 160px !important;
    margin-right: 160px !important;
  }
  .my-sm-160 {
    margin-top: 160px !important;
    margin-bottom: 160px !important;
  }
  .p-sm-140 {
    padding: 140px !important;
  }
  .pt-sm-140 {
    padding-top: 140px !important;
  }
  .pl-sm-140 {
    padding-left: 140px !important;
  }
  .pr-sm-140 {
    padding-right: 140px !important;
  }
  .pb-sm-140 {
    padding-bottom: 140px !important;
  }
  .px-sm-140 {
    padding-left: 140px !important;
    padding-right: 140px !important;
  }
  .py-sm-140 {
    padding-top: 140px !important;
    padding-bottom: 140px !important;
  }
  .m-sm-140 {
    margin: 140px !important;
  }
  .mt-sm-140 {
    margin-top: 140px !important;
  }
  .ml-sm-140 {
    margin-left: 140px !important;
  }
  .mr-sm-140 {
    margin-right: 140px !important;
  }
  .mb-sm-140 {
    margin-bottom: 140px !important;
  }
  .mx-sm-140 {
    margin-left: 140px !important;
    margin-right: 140px !important;
  }
  .my-sm-140 {
    margin-top: 140px !important;
    margin-bottom: 140px !important;
  }
  .p-sm-120 {
    padding: 120px !important;
  }
  .pt-sm-120 {
    padding-top: 120px !important;
  }
  .pl-sm-120 {
    padding-left: 120px !important;
  }
  .pr-sm-120 {
    padding-right: 120px !important;
  }
  .pb-sm-120 {
    padding-bottom: 120px !important;
  }
  .px-sm-120 {
    padding-left: 120px !important;
    padding-right: 120px !important;
  }
  .py-sm-120 {
    padding-top: 120px !important;
    padding-bottom: 120px !important;
  }
  .m-sm-120 {
    margin: 120px !important;
  }
  .mt-sm-120 {
    margin-top: 120px !important;
  }
  .ml-sm-120 {
    margin-left: 120px !important;
  }
  .mr-sm-120 {
    margin-right: 120px !important;
  }
  .mb-sm-120 {
    margin-bottom: 120px !important;
  }
  .mx-sm-120 {
    margin-left: 120px !important;
    margin-right: 120px !important;
  }
  .my-sm-120 {
    margin-top: 120px !important;
    margin-bottom: 120px !important;
  }
  .p-sm-100 {
    padding: 100px !important;
  }
  .pt-sm-100 {
    padding-top: 100px !important;
  }
  .pl-sm-100 {
    padding-left: 100px !important;
  }
  .pr-sm-100 {
    padding-right: 100px !important;
  }
  .pb-sm-100 {
    padding-bottom: 100px !important;
  }
  .px-sm-100 {
    padding-left: 100px !important;
    padding-right: 100px !important;
  }
  .py-sm-100 {
    padding-top: 100px !important;
    padding-bottom: 100px !important;
  }
  .m-sm-100 {
    margin: 100px !important;
  }
  .mt-sm-100 {
    margin-top: 100px !important;
  }
  .ml-sm-100 {
    margin-left: 100px !important;
  }
  .mr-sm-100 {
    margin-right: 100px !important;
  }
  .mb-sm-100 {
    margin-bottom: 100px !important;
  }
  .mx-sm-100 {
    margin-left: 100px !important;
    margin-right: 100px !important;
  }
  .my-sm-100 {
    margin-top: 100px !important;
    margin-bottom: 100px !important;
  }
  .p-sm-90 {
    padding: 90px !important;
  }
  .pt-sm-90 {
    padding-top: 90px !important;
  }
  .pl-sm-90 {
    padding-left: 90px !important;
  }
  .pr-sm-90 {
    padding-right: 90px !important;
  }
  .pb-sm-90 {
    padding-bottom: 90px !important;
  }
  .px-sm-90 {
    padding-left: 90px !important;
    padding-right: 90px !important;
  }
  .py-sm-90 {
    padding-top: 90px !important;
    padding-bottom: 90px !important;
  }
  .m-sm-90 {
    margin: 90px !important;
  }
  .mt-sm-90 {
    margin-top: 90px !important;
  }
  .ml-sm-90 {
    margin-left: 90px !important;
  }
  .mr-sm-90 {
    margin-right: 90px !important;
  }
  .mb-sm-90 {
    margin-bottom: 90px !important;
  }
  .mx-sm-90 {
    margin-left: 90px !important;
    margin-right: 90px !important;
  }
  .my-sm-90 {
    margin-top: 90px !important;
    margin-bottom: 90px !important;
  }
  .p-sm-80 {
    padding: 80px !important;
  }
  .pt-sm-80 {
    padding-top: 80px !important;
  }
  .pl-sm-80 {
    padding-left: 80px !important;
  }
  .pr-sm-80 {
    padding-right: 80px !important;
  }
  .pb-sm-80 {
    padding-bottom: 80px !important;
  }
  .px-sm-80 {
    padding-left: 80px !important;
    padding-right: 80px !important;
  }
  .py-sm-80 {
    padding-top: 80px !important;
    padding-bottom: 80px !important;
  }
  .m-sm-80 {
    margin: 80px !important;
  }
  .mt-sm-80 {
    margin-top: 80px !important;
  }
  .ml-sm-80 {
    margin-left: 80px !important;
  }
  .mr-sm-80 {
    margin-right: 80px !important;
  }
  .mb-sm-80 {
    margin-bottom: 80px !important;
  }
  .mx-sm-80 {
    margin-left: 80px !important;
    margin-right: 80px !important;
  }
  .my-sm-80 {
    margin-top: 80px !important;
    margin-bottom: 80px !important;
  }
  .p-sm-70 {
    padding: 70px !important;
  }
  .pt-sm-70 {
    padding-top: 70px !important;
  }
  .pl-sm-70 {
    padding-left: 70px !important;
  }
  .pr-sm-70 {
    padding-right: 70px !important;
  }
  .pb-sm-70 {
    padding-bottom: 70px !important;
  }
  .px-sm-70 {
    padding-left: 70px !important;
    padding-right: 70px !important;
  }
  .py-sm-70 {
    padding-top: 70px !important;
    padding-bottom: 70px !important;
  }
  .m-sm-70 {
    margin: 70px !important;
  }
  .mt-sm-70 {
    margin-top: 70px !important;
  }
  .ml-sm-70 {
    margin-left: 70px !important;
  }
  .mr-sm-70 {
    margin-right: 70px !important;
  }
  .mb-sm-70 {
    margin-bottom: 70px !important;
  }
  .mx-sm-70 {
    margin-left: 70px !important;
    margin-right: 70px !important;
  }
  .my-sm-70 {
    margin-top: 70px !important;
    margin-bottom: 70px !important;
  }
  .p-sm-60 {
    padding: 60px !important;
  }
  .pt-sm-60 {
    padding-top: 60px !important;
  }
  .pl-sm-60 {
    padding-left: 60px !important;
  }
  .pr-sm-60 {
    padding-right: 60px !important;
  }
  .pb-sm-60 {
    padding-bottom: 60px !important;
  }
  .px-sm-60 {
    padding-left: 60px !important;
    padding-right: 60px !important;
  }
  .py-sm-60 {
    padding-top: 60px !important;
    padding-bottom: 60px !important;
  }
  .m-sm-60 {
    margin: 60px !important;
  }
  .mt-sm-60 {
    margin-top: 60px !important;
  }
  .ml-sm-60 {
    margin-left: 60px !important;
  }
  .mr-sm-60 {
    margin-right: 60px !important;
  }
  .mb-sm-60 {
    margin-bottom: 60px !important;
  }
  .mx-sm-60 {
    margin-left: 60px !important;
    margin-right: 60px !important;
  }
  .my-sm-60 {
    margin-top: 60px !important;
    margin-bottom: 60px !important;
  }
  .p-sm-50 {
    padding: 50px !important;
  }
  .pt-sm-50 {
    padding-top: 50px !important;
  }
  .pl-sm-50 {
    padding-left: 50px !important;
  }
  .pr-sm-50 {
    padding-right: 50px !important;
  }
  .pb-sm-50 {
    padding-bottom: 50px !important;
  }
  .px-sm-50 {
    padding-left: 50px !important;
    padding-right: 50px !important;
  }
  .py-sm-50 {
    padding-top: 50px !important;
    padding-bottom: 50px !important;
  }
  .m-sm-50 {
    margin: 50px !important;
  }
  .mt-sm-50 {
    margin-top: 50px !important;
  }
  .ml-sm-50 {
    margin-left: 50px !important;
  }
  .mr-sm-50 {
    margin-right: 50px !important;
  }
  .mb-sm-50 {
    margin-bottom: 50px !important;
  }
  .mx-sm-50 {
    margin-left: 50px !important;
    margin-right: 50px !important;
  }
  .my-sm-50 {
    margin-top: 50px !important;
    margin-bottom: 50px !important;
  }
  .p-sm-40 {
    padding: 40px !important;
  }
  .pt-sm-40 {
    padding-top: 40px !important;
  }
  .pl-sm-40 {
    padding-left: 40px !important;
  }
  .pr-sm-40 {
    padding-right: 40px !important;
  }
  .pb-sm-40 {
    padding-bottom: 40px !important;
  }
  .px-sm-40 {
    padding-left: 40px !important;
    padding-right: 40px !important;
  }
  .py-sm-40 {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }

  .p-sm-45 {
    padding: 45px !important;
  }
  .pt-sm-45 {
    padding-top: 45px !important;
  }
  .pl-sm-45 {
    padding-left: 45px !important;
  }
  .pr-sm-45 {
    padding-right: 45px !important;
  }
  .pb-sm-45 {
    padding-bottom: 45px !important;
  }
  .px-sm-45 {
    padding-left: 45px !important;
    padding-right: 45px !important;
  }
  .py-sm-45 {
    padding-top: 45px !important;
    padding-bottom: 45px !important;
  }

  .m-sm-40 {
    margin: 40px !important;
  }
  .mt-sm-40 {
    margin-top: 40px !important;
  }
  .ml-sm-40 {
    margin-left: 40px !important;
  }
  .mr-sm-40 {
    margin-right: 40px !important;
  }
  .mb-sm-40 {
    margin-bottom: 40px !important;
  }
  .mx-sm-40 {
    margin-left: 40px !important;
    margin-right: 40px !important;
  }
  .my-sm-40 {
    margin-top: 40px !important;
    margin-bottom: 40px !important;
  }
  .p-sm-30 {
    padding: 30px !important;
  }
  .pt-sm-30 {
    padding-top: 30px !important;
  }
  .pl-sm-30 {
    padding-left: 30px !important;
  }
  .pr-sm-30 {
    padding-right: 30px !important;
  }
  .pb-sm-30 {
    padding-bottom: 30px !important;
  }
  .px-sm-30 {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
  .py-sm-30 {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }
  .m-sm-30 {
    margin: 30px !important;
  }
  .mt-sm-30 {
    margin-top: 30px !important;
  }
  .ml-sm-30 {
    margin-left: 30px !important;
  }
  .mr-sm-30 {
    margin-right: 30px !important;
  }
  .mb-sm-30 {
    margin-bottom: 30px !important;
  }
  .mx-sm-30 {
    margin-left: 30px !important;
    margin-right: 30px !important;
  }
  .my-sm-30 {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }
  .p-sm-25 {
    padding: 25px !important;
  }
  .pt-sm-25 {
    padding-top: 25px !important;
  }
  .pl-sm-25 {
    padding-left: 25px !important;
  }
  .pr-sm-25 {
    padding-right: 25px !important;
  }
  .pb-sm-25 {
    padding-bottom: 25px !important;
  }
  .px-sm-25 {
    padding-left: 25px !important;
    padding-right: 25px !important;
  }
  .py-sm-25 {
    padding-top: 25px !important;
    padding-bottom: 25px !important;
  }
  .m-sm-25 {
    margin: 25px !important;
  }
  .mt-sm-25 {
    margin-top: 25px !important;
  }
  .ml-sm-25 {
    margin-left: 25px !important;
  }
  .mr-sm-25 {
    margin-right: 25px !important;
  }
  .mb-sm-25 {
    margin-bottom: 25px !important;
  }
  .mx-sm-25 {
    margin-left: 25px !important;
    margin-right: 25px !important;
  }
  .my-sm-25 {
    margin-top: 25px !important;
    margin-bottom: 25px !important;
  }
  .p-sm-20 {
    padding: 20px !important;
  }
  .pt-sm-20 {
    padding-top: 20px !important;
  }
  .pl-sm-20 {
    padding-left: 20px !important;
  }
  .pr-sm-20 {
    padding-right: 20px !important;
  }
  .pb-sm-20 {
    padding-bottom: 20px !important;
  }
  .px-sm-20 {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  .py-sm-20 {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }
  .m-sm-20 {
    margin: 20px !important;
  }
  .mt-sm-20 {
    margin-top: 20px !important;
  }
  .ml-sm-20 {
    margin-left: 20px !important;
  }
  .mr-sm-20 {
    margin-right: 20px !important;
  }
  .mb-sm-20 {
    margin-bottom: 20px !important;
  }
  .mx-sm-20 {
    margin-left: 20px !important;
    margin-right: 20px !important;
  }
  .my-sm-20 {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }
  .p-sm-15 {
    padding: 15px !important;
  }
  .pt-sm-15 {
    padding-top: 15px !important;
  }
  .pl-sm-15 {
    padding-left: 15px !important;
  }
  .pr-sm-15 {
    padding-right: 15px !important;
  }
  .pb-sm-15 {
    padding-bottom: 15px !important;
  }
  .px-sm-15 {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  .py-sm-15 {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }
  .m-sm-15 {
    margin: 15px !important;
  }
  .mt-sm-15 {
    margin-top: 15px !important;
  }
  .ml-sm-15 {
    margin-left: 15px !important;
  }
  .mr-sm-15 {
    margin-right: 15px !important;
  }
  .mb-sm-15 {
    margin-bottom: 15px !important;
  }
  .mx-sm-15 {
    margin-left: 15px !important;
    margin-right: 15px !important;
  }
  .my-sm-15 {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
  }
  .p-sm-10 {
    padding: 10px !important;
  }
  .pt-sm-10 {
    padding-top: 10px !important;
  }
  .pl-sm-10 {
    padding-left: 10px !important;
  }
  .pr-sm-10 {
    padding-right: 10px !important;
  }
  .pb-sm-10 {
    padding-bottom: 10px !important;
  }
  .px-sm-10 {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  .py-sm-10 {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }
  .m-sm-10 {
    margin: 10px !important;
  }
  .mt-sm-10 {
    margin-top: 10px !important;
  }
  .ml-sm-10 {
    margin-left: 10px !important;
  }
  .mr-sm-10 {
    margin-right: 10px !important;
  }
  .mb-sm-10 {
    margin-bottom: 10px !important;
  }
  .mx-sm-10 {
    margin-left: 10px !important;
    margin-right: 10px !important;
  }
  .my-sm-10 {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }
  .p-sm-5 {
    padding: 5px !important;
  }
  .pt-sm-5 {
    padding-top: 5px !important;
  }
  .pl-sm-5 {
    padding-left: 5px !important;
  }
  .pr-sm-5 {
    padding-right: 5px !important;
  }
  .pb-sm-5 {
    padding-bottom: 5px !important;
  }
  .px-sm-5 {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
  .py-sm-5 {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }
  .m-sm-5 {
    margin: 5px !important;
  }
  .mt-sm-5 {
    margin-top: 5px !important;
  }
  .ml-sm-5 {
    margin-left: 5px !important;
  }
  .mr-sm-5 {
    margin-right: 5px !important;
  }
  .mb-sm-5 {
    margin-bottom: 5px !important;
  }
  .mx-sm-5 {
    margin-left: 5px !important;
    margin-right: 5px !important;
  }
  .my-sm-5 {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0 {
    padding-top: 0 !important;
  }
  .pl-sm-0 {
    padding-left: 0 !important;
  }
  .pr-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0 {
    padding-bottom: 0 !important;
  }
  .px-sm-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0 {
    margin-top: 0 !important;
  }
  .ml-sm-0 {
    margin-left: 0 !important;
  }
  .mr-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0 {
    margin-bottom: 0 !important;
  }
  .mx-sm-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .mx-sm-auto {
    margin-left: auto !important;
    margin-right: auto !important;
    display: block;
  }
  .ml-sm-auto {
    margin-left: auto !important;
  }
  .mr-sm-auto {
    margin-right: auto !important;
  }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .height-sm-20 {
    min-height: 20px;
  }
  .height-sm-25 {
    min-height: 25px;
  }
  .height-sm-30 {
    min-height: 30px;
  }
  .height-sm-35 {
    min-height: 35px;
  }
  .height-sm-40 {
    min-height: 40px;
  }
  .height-sm-45 {
    min-height: 45px;
  }
  .height-sm-50 {
    min-height: 50px;
  }
  .height-sm-55 {
    min-height: 55px;
  }
  .height-sm-60 {
    min-height: 60px;
  }
  .height-sm-65 {
    min-height: 65px;
  }
  .height-sm-70 {
    min-height: 70px;
  }
  .height-sm-80 {
    min-height: 80px;
  }
  .height-sm-90 {
    min-height: 90px;
  }
  .height-sm-100 {
    min-height: 100px;
  }
  .height-sm-110 {
    min-height: 110px;
  }
  .height-sm-120 {
    min-height: 120px;
  }
  .height-sm-130 {
    min-height: 130px;
  }
  .height-sm-140 {
    min-height: 140px;
  }
  .height-sm-150 {
    min-height: 150px;
  }
  .height-sm-160 {
    min-height: 160px;
  }
  .height-sm-170 {
    min-height: 170px;
  }
  .height-sm-180 {
    min-height: 180px;
  }
  .height-sm-190 {
    min-height: 190px;
  }
  .height-sm-200 {
    min-height: 200px;
  }
}
@media (min-width: 992px) {
  .pt-md-64 {
    padding-top: 64px !important;
  }
  .pb-md-64 {
    padding-bottom: 64px !important;
  }
  .py-md-64 {
    padding-top: 64px !important;
    padding-bottom: 64px !important;
  }
  .mt-md-64 {
    margin-top: 64px !important;
  }
  .mb-md-64 {
    margin-bottom: 64px !important;
  }
  .my-md-64 {
    margin-top: 64px !important;
    margin-bottom: 64px !important;
  }
  .px-md-64 {
    padding-left: 64px !important;
    padding-right: 64px !important;
  }
  .pt-md-56 {
    padding-top: 56px !important;
  }
  .pb-md-56 {
    padding-bottom: 56px !important;
  }
  .py-md-56 {
    padding-top: 56px !important;
    padding-bottom: 56px !important;
  }
  .mt-md-56 {
    margin-top: 56px !important;
  }
  .mb-md-56 {
    margin-bottom: 56px !important;
  }
  .my-md-56 {
    margin-top: 56px !important;
    margin-bottom: 56px !important;
  }
  .px-md-56 {
    padding-left: 56px !important;
    padding-right: 56px !important;
  }
  .pt-md-48 {
    padding-top: 48px !important;
  }
  .pb-md-48 {
    padding-bottom: 48px !important;
  }
  .py-md-48 {
    padding-top: 48px !important;
    padding-bottom: 48px !important;
  }
  .mt-md-48 {
    margin-top: 48px !important;
  }
  .mb-md-48 {
    margin-bottom: 48px !important;
  }
  .my-md-48 {
    margin-top: 48px !important;
    margin-bottom: 48px !important;
  }
  .px-md-48 {
    padding-left: 48px !important;
    padding-right: 48px !important;
  }
  .pt-md-44 {
    padding-top: 44px !important;
  }
  .pb-md-44 {
    padding-bottom: 44px !important;
  }
  .py-md-44 {
    padding-top: 44px !important;
    padding-bottom: 44px !important;
  }
  .mt-md-44 {
    margin-top: 44px !important;
  }
  .mb-md-44 {
    margin-bottom: 44px !important;
  }
  .my-md-44 {
    margin-top: 44px !important;
    margin-bottom: 44px !important;
  }
  .px-md-44 {
    padding-left: 44px !important;
    padding-right: 44px !important;
  }
  .pt-md-36 {
    padding-top: 36px !important;
  }
  .pb-md-36 {
    padding-bottom: 36px !important;
  }
  .py-md-36 {
    padding-top: 36px !important;
    padding-bottom: 36px !important;
  }
  .mt-md-36 {
    margin-top: 36px !important;
  }
  .mb-md-36 {
    margin-bottom: 36px !important;
  }
  .my-md-36 {
    margin-top: 36px !important;
    margin-bottom: 36px !important;
  }
  .px-md-36 {
    padding-left: 36px !important;
    padding-right: 36px !important;
  }
  .pt-md-24 {
    padding-top: 24px !important;
  }
  .pb-md-24 {
    padding-bottom: 24px !important;
  }
  .py-md-24 {
    padding-top: 24px !important;
    padding-bottom: 24px !important;
  }
  .mt-md-24 {
    margin-top: 24px !important;
  }
  .mb-md-24 {
    margin-bottom: 24px !important;
  }
  .my-md-24 {
    margin-top: 24px !important;
    margin-bottom: 24px !important;
  }
  .px-md-24 {
    padding-left: 24px !important;
    padding-right: 24px !important;
  }
  .pt-md-18 {
    padding-top: 18px !important;
  }
  .pb-md-18 {
    padding-bottom: 18px !important;
  }
  .py-md-18 {
    padding-top: 18px !important;
    padding-bottom: 18px !important;
  }
  .mt-md-18 {
    margin-top: 18px !important;
  }
  .mb-md-18 {
    margin-bottom: 18px !important;
  }
  .my-md-18 {
    margin-top: 18px !important;
    margin-bottom: 18px !important;
  }
  .px-md-18 {
    padding-left: 18px !important;
    padding-right: 18px !important;
  }
  .pt-md-12 {
    padding-top: 12px !important;
  }
  .pb-md-12 {
    padding-bottom: 12px !important;
  }
  .py-md-12 {
    padding-top: 12px !important;
    padding-bottom: 12px !important;
  }
  .mt-md-12 {
    margin-top: 12px !important;
  }
  .mb-md-12 {
    margin-bottom: 12px !important;
  }
  .my-md-12 {
    margin-top: 12px !important;
    margin-bottom: 12px !important;
  }
  .px-md-12 {
    padding-left: 12px !important;
    padding-right: 12px !important;
  }
  .p-md-200 {
    padding: 200px !important;
  }
  .pt-md-200 {
    padding-top: 200px !important;
  }
  .pl-md-200 {
    padding-left: 200px !important;
  }
  .pr-md-200 {
    padding-right: 200px !important;
  }
  .pb-md-200 {
    padding-bottom: 200px !important;
  }
  .px-md-200 {
    padding-left: 200px !important;
    padding-right: 200px !important;
  }
  .py-md-200 {
    padding-top: 200px !important;
    padding-bottom: 200px !important;
  }
  .m-md-200 {
    margin: 200px !important;
  }
  .mt-md-200 {
    margin-top: 200px !important;
  }
  .ml-md-200 {
    margin-left: 200px !important;
  }
  .mr-md-200 {
    margin-right: 200px !important;
  }
  .mb-md-200 {
    margin-bottom: 200px !important;
  }
  .mx-md-200 {
    margin-left: 200px !important;
    margin-right: 200px !important;
  }
  .my-md-200 {
    margin-top: 200px !important;
    margin-bottom: 200px !important;
  }
  .p-md-180 {
    padding: 180px !important;
  }
  .pt-md-180 {
    padding-top: 180px !important;
  }
  .pl-md-180 {
    padding-left: 180px !important;
  }
  .pr-md-180 {
    padding-right: 180px !important;
  }
  .pb-md-180 {
    padding-bottom: 180px !important;
  }
  .px-md-180 {
    padding-left: 180px !important;
    padding-right: 180px !important;
  }
  .py-md-180 {
    padding-top: 180px !important;
    padding-bottom: 180px !important;
  }
  .m-md-180 {
    margin: 180px !important;
  }
  .mt-md-180 {
    margin-top: 180px !important;
  }
  .ml-md-180 {
    margin-left: 180px !important;
  }
  .mr-md-180 {
    margin-right: 180px !important;
  }
  .mb-md-180 {
    margin-bottom: 180px !important;
  }
  .mx-md-180 {
    margin-left: 180px !important;
    margin-right: 180px !important;
  }
  .my-md-180 {
    margin-top: 180px !important;
    margin-bottom: 180px !important;
  }
  .p-md-160 {
    padding: 160px !important;
  }
  .pt-md-160 {
    padding-top: 160px !important;
  }
  .pl-md-160 {
    padding-left: 160px !important;
  }
  .pr-md-160 {
    padding-right: 160px !important;
  }
  .pb-md-160 {
    padding-bottom: 160px !important;
  }
  .px-md-160 {
    padding-left: 160px !important;
    padding-right: 160px !important;
  }
  .py-md-160 {
    padding-top: 160px !important;
    padding-bottom: 160px !important;
  }
  .m-md-160 {
    margin: 160px !important;
  }
  .mt-md-160 {
    margin-top: 160px !important;
  }
  .ml-md-160 {
    margin-left: 160px !important;
  }
  .mr-md-160 {
    margin-right: 160px !important;
  }
  .mb-md-160 {
    margin-bottom: 160px !important;
  }
  .mx-md-160 {
    margin-left: 160px !important;
    margin-right: 160px !important;
  }
  .my-md-160 {
    margin-top: 160px !important;
    margin-bottom: 160px !important;
  }
  .p-md-140 {
    padding: 140px !important;
  }
  .pt-md-140 {
    padding-top: 140px !important;
  }
  .pl-md-140 {
    padding-left: 140px !important;
  }
  .pr-md-140 {
    padding-right: 140px !important;
  }
  .pb-md-140 {
    padding-bottom: 140px !important;
  }
  .px-md-140 {
    padding-left: 140px !important;
    padding-right: 140px !important;
  }
  .py-md-140 {
    padding-top: 140px !important;
    padding-bottom: 140px !important;
  }
  .m-md-140 {
    margin: 140px !important;
  }
  .mt-md-140 {
    margin-top: 140px !important;
  }
  .ml-md-140 {
    margin-left: 140px !important;
  }
  .mr-md-140 {
    margin-right: 140px !important;
  }
  .mb-md-140 {
    margin-bottom: 140px !important;
  }
  .mx-md-140 {
    margin-left: 140px !important;
    margin-right: 140px !important;
  }
  .my-md-140 {
    margin-top: 140px !important;
    margin-bottom: 140px !important;
  }
  .p-md-120 {
    padding: 120px !important;
  }
  .pt-md-120 {
    padding-top: 120px !important;
  }
  .pl-md-120 {
    padding-left: 120px !important;
  }
  .pr-md-120 {
    padding-right: 120px !important;
  }
  .pb-md-120 {
    padding-bottom: 120px !important;
  }
  .px-md-120 {
    padding-left: 120px !important;
    padding-right: 120px !important;
  }
  .py-md-120 {
    padding-top: 120px !important;
    padding-bottom: 120px !important;
  }
  .m-md-120 {
    margin: 120px !important;
  }
  .mt-md-120 {
    margin-top: 120px !important;
  }
  .ml-md-120 {
    margin-left: 120px !important;
  }
  .mr-md-120 {
    margin-right: 120px !important;
  }
  .mb-md-120 {
    margin-bottom: 120px !important;
  }
  .mx-md-120 {
    margin-left: 120px !important;
    margin-right: 120px !important;
  }
  .my-md-120 {
    margin-top: 120px !important;
    margin-bottom: 120px !important;
  }
  .p-md-100 {
    padding: 100px !important;
  }
  .pt-md-100 {
    padding-top: 100px !important;
  }
  .pl-md-100 {
    padding-left: 100px !important;
  }
  .pr-md-100 {
    padding-right: 100px !important;
  }
  .pb-md-100 {
    padding-bottom: 100px !important;
  }
  .px-md-100 {
    padding-left: 100px !important;
    padding-right: 100px !important;
  }
  .py-md-100 {
    padding-top: 100px !important;
    padding-bottom: 100px !important;
  }
  .m-md-100 {
    margin: 100px !important;
  }
  .mt-md-100 {
    margin-top: 100px !important;
  }
  .ml-md-100 {
    margin-left: 100px !important;
  }
  .mr-md-100 {
    margin-right: 100px !important;
  }
  .mb-md-100 {
    margin-bottom: 100px !important;
  }
  .mx-md-100 {
    margin-left: 100px !important;
    margin-right: 100px !important;
  }
  .my-md-100 {
    margin-top: 100px !important;
    margin-bottom: 100px !important;
  }
  .p-md-90 {
    padding: 90px !important;
  }
  .pt-md-90 {
    padding-top: 90px !important;
  }
  .pl-md-90 {
    padding-left: 90px !important;
  }
  .pr-md-90 {
    padding-right: 90px !important;
  }
  .pb-md-90 {
    padding-bottom: 90px !important;
  }
  .px-md-90 {
    padding-left: 90px !important;
    padding-right: 90px !important;
  }
  .py-md-90 {
    padding-top: 90px !important;
    padding-bottom: 90px !important;
  }
  .m-md-90 {
    margin: 90px !important;
  }
  .mt-md-90 {
    margin-top: 90px !important;
  }
  .ml-md-90 {
    margin-left: 90px !important;
  }
  .mr-md-90 {
    margin-right: 90px !important;
  }
  .mb-md-90 {
    margin-bottom: 90px !important;
  }
  .mx-md-90 {
    margin-left: 90px !important;
    margin-right: 90px !important;
  }
  .my-md-90 {
    margin-top: 90px !important;
    margin-bottom: 90px !important;
  }
  .p-md-80 {
    padding: 80px !important;
  }
  .pt-md-80 {
    padding-top: 80px !important;
  }
  .pl-md-80 {
    padding-left: 80px !important;
  }
  .pr-md-80 {
    padding-right: 80px !important;
  }
  .pb-md-80 {
    padding-bottom: 80px !important;
  }
  .px-md-80 {
    padding-left: 80px !important;
    padding-right: 80px !important;
  }
  .py-md-80 {
    padding-top: 80px !important;
    padding-bottom: 80px !important;
  }
  .m-md-80 {
    margin: 80px !important;
  }
  .mt-md-80 {
    margin-top: 80px !important;
  }
  .ml-md-80 {
    margin-left: 80px !important;
  }
  .mr-md-80 {
    margin-right: 80px !important;
  }
  .mb-md-80 {
    margin-bottom: 80px !important;
  }
  .mx-md-80 {
    margin-left: 80px !important;
    margin-right: 80px !important;
  }
  .my-md-80 {
    margin-top: 80px !important;
    margin-bottom: 80px !important;
  }
  .p-md-70 {
    padding: 70px !important;
  }
  .pt-md-70 {
    padding-top: 70px !important;
  }
  .pl-md-70 {
    padding-left: 70px !important;
  }
  .pr-md-70 {
    padding-right: 70px !important;
  }
  .pb-md-70 {
    padding-bottom: 70px !important;
  }
  .px-md-70 {
    padding-left: 70px !important;
    padding-right: 70px !important;
  }
  .py-md-70 {
    padding-top: 70px !important;
    padding-bottom: 70px !important;
  }
  .m-md-70 {
    margin: 70px !important;
  }
  .mt-md-70 {
    margin-top: 70px !important;
  }
  .ml-md-70 {
    margin-left: 70px !important;
  }
  .mr-md-70 {
    margin-right: 70px !important;
  }
  .mb-md-70 {
    margin-bottom: 70px !important;
  }
  .mx-md-70 {
    margin-left: 70px !important;
    margin-right: 70px !important;
  }
  .my-md-70 {
    margin-top: 70px !important;
    margin-bottom: 70px !important;
  }
  .p-md-60 {
    padding: 60px !important;
  }
  .pt-md-60 {
    padding-top: 60px !important;
  }
  .pl-md-60 {
    padding-left: 60px !important;
  }
  .pr-md-60 {
    padding-right: 60px !important;
  }
  .pb-md-60 {
    padding-bottom: 60px !important;
  }
  .px-md-60 {
    padding-left: 60px !important;
    padding-right: 60px !important;
  }
  .py-md-60 {
    padding-top: 60px !important;
    padding-bottom: 60px !important;
  }
  .m-md-60 {
    margin: 60px !important;
  }
  .mt-md-60 {
    margin-top: 60px !important;
  }
  .ml-md-60 {
    margin-left: 60px !important;
  }
  .mr-md-60 {
    margin-right: 60px !important;
  }
  .mb-md-60 {
    margin-bottom: 60px !important;
  }
  .mx-md-60 {
    margin-left: 60px !important;
    margin-right: 60px !important;
  }
  .my-md-60 {
    margin-top: 60px !important;
    margin-bottom: 60px !important;
  }
  .p-md-50 {
    padding: 50px !important;
  }
  .pt-md-50 {
    padding-top: 50px !important;
  }
  .pl-md-50 {
    padding-left: 50px !important;
  }
  .pr-md-50 {
    padding-right: 50px !important;
  }
  .pb-md-50 {
    padding-bottom: 50px !important;
  }
  .px-md-50 {
    padding-left: 50px !important;
    padding-right: 50px !important;
  }
  .py-md-50 {
    padding-top: 50px !important;
    padding-bottom: 50px !important;
  }
  .m-md-50 {
    margin: 50px !important;
  }
  .mt-md-50 {
    margin-top: 50px !important;
  }
  .ml-md-50 {
    margin-left: 50px !important;
  }
  .mr-md-50 {
    margin-right: 50px !important;
  }
  .mb-md-50 {
    margin-bottom: 50px !important;
  }
  .mx-md-50 {
    margin-left: 50px !important;
    margin-right: 50px !important;
  }
  .my-md-50 {
    margin-top: 50px !important;
    margin-bottom: 50px !important;
  }
  .p-md-40 {
    padding: 40px !important;
  }
  .pt-md-40 {
    padding-top: 40px !important;
  }
  .pl-md-40 {
    padding-left: 40px !important;
  }
  .pr-md-40 {
    padding-right: 40px !important;
  }
  .pb-md-40 {
    padding-bottom: 40px !important;
  }
  .px-md-40 {
    padding-left: 40px !important;
    padding-right: 40px !important;
  }
  .py-md-40 {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }
  .m-md-40 {
    margin: 40px !important;
  }
  .mt-md-40 {
    margin-top: 40px !important;
  }
  .ml-md-40 {
    margin-left: 40px !important;
  }
  .mr-md-40 {
    margin-right: 40px !important;
  }
  .mb-md-40 {
    margin-bottom: 40px !important;
  }
  .mx-md-40 {
    margin-left: 40px !important;
    margin-right: 40px !important;
  }
  .my-md-40 {
    margin-top: 40px !important;
    margin-bottom: 40px !important;
  }
  .p-md-30 {
    padding: 30px !important;
  }
  .pt-md-30 {
    padding-top: 30px !important;
  }
  .pl-md-30 {
    padding-left: 30px !important;
  }
  .pr-md-30 {
    padding-right: 30px !important;
  }
  .pb-md-30 {
    padding-bottom: 30px !important;
  }
  .px-md-30 {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
  .py-md-30 {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }
  .m-md-30 {
    margin: 30px !important;
  }
  .mt-md-30 {
    margin-top: 30px !important;
  }
  .ml-md-30 {
    margin-left: 30px !important;
  }
  .mr-md-30 {
    margin-right: 30px !important;
  }
  .mb-md-30 {
    margin-bottom: 30px !important;
  }
  .mx-md-30 {
    margin-left: 30px !important;
    margin-right: 30px !important;
  }
  .my-md-30 {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }
  .p-md-25 {
    padding: 25px !important;
  }
  .pt-md-25 {
    padding-top: 25px !important;
  }
  .pl-md-25 {
    padding-left: 25px !important;
  }
  .pr-md-25 {
    padding-right: 25px !important;
  }
  .pb-md-25 {
    padding-bottom: 25px !important;
  }
  .px-md-25 {
    padding-left: 25px !important;
    padding-right: 25px !important;
  }
  .py-md-25 {
    padding-top: 25px !important;
    padding-bottom: 25px !important;
  }
  .m-md-25 {
    margin: 25px !important;
  }
  .mt-md-25 {
    margin-top: 25px !important;
  }
  .ml-md-25 {
    margin-left: 25px !important;
  }
  .mr-md-25 {
    margin-right: 25px !important;
  }
  .mb-md-25 {
    margin-bottom: 25px !important;
  }
  .mx-md-25 {
    margin-left: 25px !important;
    margin-right: 25px !important;
  }
  .my-md-25 {
    margin-top: 25px !important;
    margin-bottom: 25px !important;
  }
  .p-md-20 {
    padding: 20px !important;
  }
  .pt-md-20 {
    padding-top: 20px !important;
  }
  .pl-md-20 {
    padding-left: 20px !important;
  }
  .pr-md-20 {
    padding-right: 20px !important;
  }
  .pb-md-20 {
    padding-bottom: 20px !important;
  }
  .px-md-20 {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  .py-md-20 {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }
  .m-md-20 {
    margin: 20px !important;
  }
  .mt-md-20 {
    margin-top: 20px !important;
  }
  .ml-md-20 {
    margin-left: 20px !important;
  }
  .mr-md-20 {
    margin-right: 20px !important;
  }
  .mb-md-20 {
    margin-bottom: 20px !important;
  }
  .mx-md-20 {
    margin-left: 20px !important;
    margin-right: 20px !important;
  }
  .my-md-20 {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }
  .p-md-15 {
    padding: 15px !important;
  }
  .pt-md-15 {
    padding-top: 15px !important;
  }
  .pl-md-15 {
    padding-left: 15px !important;
  }
  .pr-md-15 {
    padding-right: 15px !important;
  }
  .pb-md-15 {
    padding-bottom: 15px !important;
  }
  .px-md-15 {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  .py-md-15 {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }
  .m-md-15 {
    margin: 15px !important;
  }
  .mt-md-15 {
    margin-top: 15px !important;
  }
  .ml-md-15 {
    margin-left: 15px !important;
  }
  .mr-md-15 {
    margin-right: 15px !important;
  }
  .mb-md-15 {
    margin-bottom: 15px !important;
  }
  .mx-md-15 {
    margin-left: 15px !important;
    margin-right: 15px !important;
  }
  .my-md-15 {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
  }
  .p-md-10 {
    padding: 10px !important;
  }
  .pt-md-10 {
    padding-top: 10px !important;
  }
  .pl-md-10 {
    padding-left: 10px !important;
  }
  .pr-md-10 {
    padding-right: 10px !important;
  }
  .pb-md-10 {
    padding-bottom: 10px !important;
  }
  .px-md-10 {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  .py-md-10 {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }
  .m-md-10 {
    margin: 10px !important;
  }
  .mt-md-10 {
    margin-top: 10px !important;
  }
  .ml-md-10 {
    margin-left: 10px !important;
  }
  .mr-md-10 {
    margin-right: 10px !important;
  }
  .mb-md-10 {
    margin-bottom: 10px !important;
  }
  .mx-md-10 {
    margin-left: 10px !important;
    margin-right: 10px !important;
  }
  .my-md-10 {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }
  .p-md-5 {
    padding: 5px !important;
  }
  .pt-md-5 {
    padding-top: 5px !important;
  }
  .pl-md-5 {
    padding-left: 5px !important;
  }
  .pr-md-5 {
    padding-right: 5px !important;
  }
  .pb-md-5 {
    padding-bottom: 5px !important;
  }
  .px-md-5 {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
  .py-md-5 {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }
  .m-md-5 {
    margin: 5px !important;
  }
  .mt-md-5 {
    margin-top: 5px !important;
  }
  .ml-md-5 {
    margin-left: 5px !important;
  }
  .mr-md-5 {
    margin-right: 5px !important;
  }
  .mb-md-5 {
    margin-bottom: 5px !important;
  }
  .mx-md-5 {
    margin-left: 5px !important;
    margin-right: 5px !important;
  }
  .my-md-5 {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0 {
    padding-top: 0 !important;
  }
  .pl-md-0 {
    padding-left: 0 !important;
  }
  .pr-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0 {
    padding-bottom: 0 !important;
  }
  .px-md-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0 {
    margin-top: 0 !important;
  }
  .ml-md-0 {
    margin-left: 0 !important;
  }
  .mr-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0 {
    margin-bottom: 0 !important;
  }
  .mx-md-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .mx-md-auto {
    margin-left: auto !important;
    margin-right: auto !important;
    display: block;
  }
  .ml-md-auto {
    margin-left: auto !important;
  }
  .mr-md-auto {
    margin-right: auto !important;
  }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .height-md-20 {
    min-height: 20px;
  }
  .height-md-25 {
    min-height: 25px;
  }
  .height-md-30 {
    min-height: 30px;
  }
  .height-md-35 {
    min-height: 35px;
  }
  .height-md-40 {
    min-height: 40px;
  }
  .height-md-45 {
    min-height: 45px;
  }
  .height-md-50 {
    min-height: 50px;
  }
  .height-md-55 {
    min-height: 55px;
  }
  .height-md-60 {
    min-height: 60px;
  }
  .height-md-65 {
    min-height: 65px;
  }
  .height-md-70 {
    min-height: 70px;
  }
  .height-md-80 {
    min-height: 80px;
  }
  .height-md-90 {
    min-height: 90px;
  }
  .height-md-100 {
    min-height: 100px;
  }
  .height-md-110 {
    min-height: 110px;
  }
  .height-md-120 {
    min-height: 120px;
  }
  .height-md-130 {
    min-height: 130px;
  }
  .height-md-140 {
    min-height: 140px;
  }
  .height-md-150 {
    min-height: 150px;
  }
  .height-md-160 {
    min-height: 160px;
  }
  .height-md-170 {
    min-height: 170px;
  }
  .height-md-180 {
    min-height: 180px;
  }
  .height-md-190 {
    min-height: 190px;
  }
  .height-md-200 {
    min-height: 200px;
  }
}
.border-image {
  margin-top: -30px;
  margin-bottom: -30px;
}
@media (max-width: 767px) {
  .border-image {
    float: right;
    max-width: 72%;
    margin-right: -65px;
    margin-top: auto;
    margin-bottom: auto;
  }
}
hr.footer-grey {
  border-top: 1px solid #e5e5e5;
}
.size9 {
  font-size: 9px !important;
  line-height: 14px !important;
}
.size10 {
  font-size: 10px !important;
  line-height: 15px !important;
}
.size11 {
  font-size: 11px !important;
  line-height: 17px !important;
}
.size12 {
  font-size: 12px !important;
  line-height: 18px !important;
}
.size13 {
  font-size: 13px !important;
  line-height: 19px !important;
}
.size14 {
  font-size: 14px !important;
  line-height: 24px !important;
}
.size15 {
  font-size: 15px !important;
  line-height: 25px !important;
}
.size16 {
  font-size: 16px !important;
  line-height: 26px !important;
}
.size17 {
  font-size: 17px !important;
  line-height: 27px !important;
}
.size18 {
  font-size: 18px !important;
  line-height: 28px !important;
}
.size20 {
  font-size: 20px !important;
  line-height: 28px !important;
}
.size22 {
  font-size: 22px !important;
  line-height: 28px !important;
}
.size24 {
  font-size: 24px !important;
  line-height: 30px !important;
}
.size26 {
  font-size: 26px !important;
  line-height: 32px !important;
}
.size28 {
  font-size: 28px !important;
  line-height: 34px !important;
}
.size30 {
  font-size: 30px !important;
  line-height: 36px !important;
}
.size34 {
  font-size: 34px !important;
  line-height: 40px !important;
}
.size36 {
  font-size: 36px !important;
  line-height: 42px !important;
}
.size38 {
  font-size: 38px !important;
  line-height: 44px !important;
}
.size40 {
  font-size: 40px !important;
  line-height: 46px !important;
}
.size42 {
  font-size: 42px !important;
  line-height: 48px !important;
}
.size44 {
  font-size: 44px !important;
  line-height: 50px !important;
}
.size48 {
  font-size: 48px !important;
  line-height: 54px !important;
}
.size58 {
  font-size: 58px !important;
  line-height: 56px !important;
}
.size80 {
  font-size: 80px !important;
  line-height: 60px !important;
}
.vertical-align {
  display: table !important;
}
.vertical-align .middle {
  display: table-cell !important;
  vertical-align: middle;
}
.vertical-align .bottom {
  display: table-cell !important;
  vertical-align: bottom;
}
.float-left {
  float: left !important;
}
.float-right {
  float: right !important;
}
.float-none {
  float: none !important;
}
.clear-both {
  clear: both;
}
.clearfix::after,
.clearfix::before {
  content: ' ';
  display: table;
}
.clearfix::after {
  clear: both;
}
.center-block {
  display: block;
  margin-left: auto;
  margin-right: auto;
  float: none !important;
}
.invisible {
  visibility: hidden;
}
.hide-text {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}
.hide-cta .btn,
.hide-cta .btn-wrap,
.hide-cta .link-arrow {
  display: none;
}
.hide-cta .link-wrap .link-arrow {
  display: inline-block;
}
.overflow-hidden {
  overflow: hidden;
}
.overflow-visible {
  overflow: visible;
}
.noscroll {
  overflow: hidden;
}
.affix {
  position: fixed;
}
.relative {
  position: relative;
}
.absolute {
  position: absolute;
}
.h-auto {
  height: auto;
}
.h-100 {
  height: 100%;
}
@media (max-width: 767px) {
  .size24 {
    font-size: 20px !important;
    line-height: 24px !important;
  }
  .size28 {
    font-size: 24px !important;
    line-height: 28px !important;
  }
  .size30 {
    font-size: 26px !important;
    line-height: 30px !important;
  }
  .size32 {
    font-size: 28px !important;
    line-height: 32px !important;
  }
  .size34 {
    font-size: 30px !important;
    line-height: 34px !important;
  }
  .size36 {
    font-size: 32px !important;
    line-height: 36px !important;
  }
  .size38 {
    font-size: 34px !important;
    line-height: 38px !important;
  }
  .size40 {
    font-size: 36px !important;
    line-height: 40px !important;
  }
  .size42 {
    font-size: 38px !important;
    line-height: 42px !important;
  }
  .size44 {
    font-size: 40px !important;
    line-height: 44px !important;
  }
  .size48 {
    font-size: 44px !important;
    line-height: 48px !important;
  }
  .text-xs-left {
    text-align: left;
  }
  .text-xs-right {
    text-align: right;
  }
  .text-xs-center {
    text-align: center;
  }
  .text-xs-justify {
    text-align: justify;
  }
  .text-xs-nowrap {
    white-space: nowrap;
  }
  .float-xs-left {
    float: left !important;
  }
  .float-xs-right {
    float: right !important;
  }
  .float-xs-none {
    float: none !important;
  }
  .hide-xs-text {
    font: 0/0 a;
    color: transparent;
    text-shadow: none;
    background-color: transparent;
    border: 0;
  }
  .hide-xs-cta .btn,
  .hide-xs-cta .btn-wrap,
  .hide-xs-cta .link-arrow {
    display: none;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .size24 {
    font-size: 22px !important;
    line-height: 26px !important;
  }
  .size28 {
    font-size: 26px !important;
    line-height: 30px !important;
  }
  .size30 {
    font-size: 28px !important;
    line-height: 32px !important;
  }
  .size32 {
    font-size: 30px !important;
    line-height: 34px !important;
  }
  .size34 {
    font-size: 32px !important;
    line-height: 36px !important;
  }
  .size36 {
    font-size: 34px !important;
    line-height: 38px !important;
  }
  .size38 {
    font-size: 36px !important;
    line-height: 40px !important;
  }
  .size40 {
    font-size: 38px !important;
    line-height: 42px !important;
  }
  .size42 {
    font-size: 40px !important;
    line-height: 44px !important;
  }
  .size44 {
    font-size: 42px !important;
    line-height: 46px !important;
  }
  .size48 {
    font-size: 46px !important;
    line-height: 50px !important;
  }
}
@media (min-width: 768px) {
  .text-sm-left {
    text-align: left;
  }
  .text-sm-right {
    text-align: right;
  }
  .text-sm-center {
    text-align: center;
  }
  .text-sm-justify {
    text-align: justify;
  }
  .text-sm-nowrap {
    white-space: nowrap;
  }
  .float-sm-left {
    float: left !important;
  }
  .float-sm-right {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
  .hide-sm-text {
    font: 0/0 a;
    color: transparent;
    text-shadow: none;
    background-color: transparent;
    border: 0;
  }
  .hide-sm-cta .btn,
  .hide-sm-cta .btn-wrap,
  .hide-sm-cta .link-arrow {
    display: none;
  }
}
@media (min-width: 992px) {
  .text-md-left {
    text-align: left;
  }
  .text-md-right {
    text-align: right;
  }
  .text-md-center {
    text-align: center;
  }
  .text-md-justify {
    text-align: justify;
  }
  .text-md-nowrap {
    white-space: nowrap;
  }
  .float-md-left {
    float: left !important;
  }
  .float-md-right {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
  .hide-md-text {
    font: 0/0 a;
    color: transparent;
    text-shadow: none;
    background-color: transparent;
    border: 0;
  }
  .hide-md-cta .btn,
  .hide-md-cta .btn-wrap,
  .hide-md-cta .link-arrow {
    display: none;
  }
}

/* ThreeColumnTestimonialsComponent */
.sem-quote-bubble {
  right: -19%;
  left: auto;
  top: -35%;
  width: 236px;
}
@media (max-width: 767px) {
  .sem-quote-bubble {
    top: -20px !important;
    right: 0 !important;
  }
}
@media (max-width: 1500px) {
  .sem-quote-bubble {
    right: -43px;
    top: -13px;
    width: 150px;
    height: 150px;
    margin: 0 auto;
    padding: 0;
    margin-bottom: 60px !important;
  }
  .sem-quote-bubble span {
    font-size: 16px;
    display: block;
    line-height: 1.4;
    padding: 34px 0;
    margin: 0;
  }
  .sem-testimonials h3.h1 {
    font-size: 30px;
  }
}
@media (max-width: 1030px) {
  .sem-testimonials {
    background-position: center;
    width: auto;
  }
}
@media (max-width: 1500px) {
  .sem-testimonials {
    min-height: 875px;
    padding: 140px 40px;
  }
}
@media (max-width: 500px) {
  .sem-testimonials {
    min-height: 1027px;
    background-size: auto 81%;
    background-position: bottom;
  }
}
@media (max-width: 767px) {
  .sem-testimonials {
    background-size: auto 81%;
    background-position: bottom;
  }
}

.user-locale ::before {
  content: '';
  float: left;
  width: 17px;
  height: 17px;
  background: center center/contain no-repeat;
  margin: 3px 5px 0 18px;
  background-image: url('./images/home-global/icn-globe-black.svg');
}
.trustMarkerV2-nav {
  position: relative;
}
.cursor-pointer {
  cursor: pointer;
}
.position-relative {
  position: relative;
}
.benefits-list ul {
  margin-bottom: 0 !important;
  padding-left: 0;
  list-style: none;
  margin-left: -5px;
}

.benefits-list ul li {
  padding: 0 40px;
  position: relative;
  display: inline-block;
  margin-bottom: 10px !important;
  font-size: 20px;
  font-weight: 300;
  line-height: 1.4;
}
.benefits-list ul li:before {
  content: '';
  width: 20px;
  height: 10px;
  border: 3px solid #4300d5;
  border-top: none;
  border-right: none;
  transform: rotate(-55deg);
  position: absolute;
  left: 8px;
  top: 6px;
}

.w-90px {
  width: 90px;
}
.-mx-15px {
  margin-left: -15px;
  margin-right: -15px;
}

.small__terms {
  position: absolute;
  bottom: -32px;
  width: 100%;
  left: 0;
}

@media (min-width: 768px) {
  .padding-override {
    padding: 55px !important;
  }
  .img-width-override {
    max-width: 90% !important;
  }
}

.sidebysideimgright {
  border-radius: 30px;
  padding: 25px 0 25px 0px;
}

.sidebysideimgrightwhite {
  background-color: white;
  border-radius: 30px;
  padding: 25px 0 25px 0px;
}

@media (max-width: 767px) {
  .sidebysideimgrightwhite,
  .sidebysideimgleftwhite {
    padding: 15px !important;
  }
}
.uppercase {
  text-transform: uppercase;
}

@media (max-width: 767px) {
  .header-wrap {
    max-width: 100vw;
  }

  .sticky {
    max-width: 100vw;
  }
}

.questions-override {
  font-size: 30px;
  margin-top: 24px;
}

@media (min-width: 463px) {
  .questions-override {
    height: 151px;
    line-height: 151px;
  }
}

.font42 {
  font-size: 42px;
}

@media (max-width: 767px) {
  .font42 {
    font-size: 33px;
  }
}

.font48 {
  font-size: 48px;
}

@media (max-width: 965px) {
  .font48 {
    font-size: 36px;
  }
}

@media (max-width: 767px) {
  .font48 {
    font-size: 33px;
  }
}

.max-w-45 {
  max-width: 45rem;
}

.max-w-55 {
  max-width: 55rem;
}

.h1 {
  font-size: 48px;
}
.font52 {
  font-size: 52px;
}

.lineheight66 {
  line-height: 66px;
}

.font30 {
  font-size: 30px;
}

.max-w-30 {
  max-width: 30rem;
}
.max-w-970px {
  max-width: 970px;
}
.terms-font {
  font-family: TaubSans_medium, Arial, sans-serif !important;
  display: block;
  color: #222 !important;
}

.terms-font p {
  font-family: TaubSans_medium, Arial, sans-serif !important;
}

.terms-font h1,
.terms-font h2,
.terms-font h3,
.terms-font h4,
.terms-font h5,
.terms-font h6 {
  font-family: TaubSans_medium, Arial, sans-serif !important;
}

.terms-font h2 {
  font-size: 34px;
  line-height: 40px;
  margin: 0 0 24px;
}

.note {
  font-size: 11px;
  line-height: 14px;
  color: #767676;
}
.font25 {
  font-size: 25px !important;
}

@media (min-width: 768px) {
  .feat-hero-mr-override {
    margin-right: -25% !important;
  }
}

/* trust-e */
.banner-cta.container-fluid {
  padding: 0;
}
.banner-cta.cookie-alert {
  position: fixed !important;
  z-index: 2147483638 !important;
  top: auto !important;
  bottom: 0 !important;
  width: 100% !important;
  margin: 0 !important;
  background-color: #fff;
}
.banner-cta.cookie-alert .text-main {
  justify-content: center;
  padding-top: 10px;
  padding-bottom: 10px;
}
.banner-cta.cookie-alert .copy {
  width: auto;
  float: none;
}
.banner-cta.cookie-alert .copy .vl {
  border-left: 2px solid #050d3a;
  margin-left: 10px;
  margin-right: 10px;
}
.banner-cta .text-main {
  padding: 37px 40px 40px;
}
.banner-cta .text-main .copy {
  width: 100%;
}
.banner-cta .text-main .btn,
.banner-cta .text-main .btn2,
.banner-cta .text-main input {
  margin-top: 12px;
}
.banner-cta .text-main input {
  height: 40px;
  border-radius: 20px;
}
.banner-cta p:last-of-type {
  margin-bottom: 0;
}
.banner-cta.cookie-alert .btn-block {
  padding: 0;
  width: auto;
}
.banner-cta .btn-block a {
  width: 100%;
}
.banner-cta .btn2 {
  display: inline-block;
  color: #fff;
  text-align: center;
  background-color: #121c4e;
  margin-top: 0 !important;
  white-space: nowrap;
}
.banner-cta .btn2.btn-xs {
  border-radius: 20px;
  font-size: 14px;
  line-height: 22px;
  padding: 9px 25px;
}
.banner-cta .btn2:hover {
  background-color: #414971;
}
@media (max-width: 767px) {
  .banner-cta.cookie-alert .text-main {
    text-align: center;
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .banner-cta .text-main {
    float: left;
    width: 100%;
  }
  .banner-cta .text-main .btn,
  .banner-cta .text-main .btn2 {
    width: 100%;
  }
  .banner-cta .btn-block,
  .banner-cta .btn-row {
    margin-top: 12px;
  }
}
@media (max-width: 991px) {
  .banner-cta .text-main {
    padding: 27px 30px 30px;
  }
}
@media (min-width: 768px) {
  .banner-cta.cookie-alert .copy {
    padding-right: 20px;
  }
  .banner-cta.cookie-alert .text-main {
    display: flex;
    align-items: center;
  }
  .banner-cta .text-main .copy p:last-child {
    margin-bottom: 0;
  }
  .banner-cta .text-main .btn-block a:first-of-type {
    margin-top: 0;
  }
}

.banner-cta.cookie-alert a:not([href]):not([tabindex]):focus,
.banner-cta.cookie-alert a:not([href]):not([tabindex]):hover {
  cursor: pointer;
  text-decoration: underline !important;
  color: #365cbd !important;
} /* Hover behavior */
.banner-cta.cookie-alert a:not([href]):not([tabindex]):active {
  cursor: pointer;
  border-color: #365cbd;
  color: #212529 !important; /* Left-click and hold behavior */
  box-shadow: 1px 1px 8px 2px rgba(54, 92, 189, 0.25);
  -webkit-box-shadow: 1px 1px 8px 2px rgba(54, 92, 189, 0.25);
  -moz-box-shadow: 1px 1px 8px 2px rgba(54, 92, 189, 0.25);
  transition: all 0.2s ease-in-out;
}

/* Override Native Styling */
#truste-show-consent {
  color: #5378d6;
}
#truste-show-consent A:hover {
  cursor: pointer;
  text-decoration: underline;
  color: #365cbd;
}
#truste-show-consent A:active {
  cursor: pointer;
  color: #212529;
}

.truste_cursor_pointer {
  display: none !important;
}

#footer-static {
  margin-bottom: 20px !important;
}

@media screen and (max-width: 640px) {
  #footer-static {
    margin-bottom: 120px !important;
  }
}

/* OVERRIDES */
#consent_blackbar {
  background-color: #121c4e !important;
  color: white;
}
#truste-consent-button {
  color: #fff !important;
  background-color: #cd0058 !important;
  border-color: #c6095a !important;
  padding: 14px 25px;
  font-size: 14px;
  line-height: 1.3;
  border-radius: 25px;
  display: inline-block;
  margin-bottom: 0;
  font-family: Specter_bold, Arial, sans-serif;
  text-transform: uppercase;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  width: min-content;
  cursor: pointer;
  background-image: none;
  border: none;
  transition: all 0.3s ease;
  text-decoration: none;
}
button:hover #tuste-consent-button {
  color: #fff;
  background-color: #cf095e !important;
  border-color: #c6095a !important;
}
#truste-consent-track {
  max-width: 1300px;
}
/* END OVERRIDES */

.margin-auto {
  margin: auto;
}

.js-carousel-cards .slick-arrow,
.js-carousel-mb-cards .slick-arrow {
  display: none !important;
}

#root {
  overflow-x: hidden;
}

.home .FAQsComponent .card-box {
  background: transparent;
}

.talk-to-sales-btn{
  cursor:pointer;

}
.talk-to-sales-btn:hover{
  color: #121c4e;
  text-decoration: underline;
}
.card-box-redone {
  width: 285px;
  height: auto;
  position: relative;
  padding: 0;
  background: #fff;
  border-radius: 30px;
}

.hero-qr-redone {
  width: 250px;
  margin: auto;
  padding-bottom: 20px;
}

picture{
  display: flex;
  object-fit: contain;
}

.qr-code-wrapper {
  position: relative;
}

.qr-code-wrapper canvas {
  position: absolute;
  top: 0;
  left: 70px;
  height: 200px;
  width: 200px;
}
