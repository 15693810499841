.logo-links {
  position: relative;
  list-style: none;
  margin-bottom: 0;
  margin-left: -10px;
}

.logo-links li:nth-of-type(1) {
  border-right: 1px solid #000000;
}

.logo-links li {
  display: inline-block;
  padding: 0 10px;
}

.scan-button {
  width: 339px;
}

.hero-qr {
  width: 240px;
  margin: auto;
  padding-bottom: 20px;
}

.purple-text {
  color: #4300d5;
  line-height: 32px;
}

.font25 {
  font-size: 25px;
}

.size12 {
  font-size: 12px !important;
  line-height: 18px !important;
}

#partnerHeroContainer .app-links {
  position: relative;
  left: 25%;
}

.hero-wrap .card-box {
  position: relative;
  height: auto;
  padding: 20px 40px;
}

.purple-text {
  color: #4300d5;
  line-height: 32px;
}

.big-purple {
  color: #4300d5;
  font-size: 37px;
}

.terms {
  text-decoration: none;
}

@media (max-width: 767px) {
  .card-box {
    padding: 42px;
  }

  .sem-hero .card-box {
    width: 260px;
    margin: 0 auto;
    float: none;
  }

  .sem-hero .card-box {
    width: 370px;
  }
}
