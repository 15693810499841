@font-face {
  font-family: Specter_regular;
  font-display: swap;
  src: local('Specter_regular'), url(./fonts/Specter-Regular.otf) format('embedded-opentype'),
    url(./fonts/SpecterApp-Regular.ttf) format('truetype'),
    url(./fonts/SpecterWeb-Regular.woff2) format('woff2');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: Specter_italic;
  font-display: swap;
  src: local('Specter_italic'), url(./fonts/Specter-RegularItalic.otf) format('embedded-opentype'),
    url(./fonts/SpecterApp-RegularItalic.ttf) format('truetype'),
    url(./fonts/SpecterWeb-RegularItalic.woff2) format('woff2');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: Specter_bold;
  font-display: swap;
  src: local('Specter_bold'), url(./fonts/Specter-Bold.otf) format('embedded-opentype'),
    url(./fonts/SpecterApp-Bold.ttf) format('truetype'),
    url(./fonts/SpecterWeb-Bold.woff2) format('woff2');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: Specter_bolditalic;
  font-display: swap;
  src: local('Specter_bolditalic'), url(./fonts/Specter-BoldItalic.otf) format('embedded-opentype'),
    url(./fonts/SpecterApp-BoldItalic.ttf) format('truetype'),
    url(./fonts/SpecterWeb-BoldItalic.woff2) format('woff2');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: Specter_ultra;
  font-display: swap;
  src: local('Specter_ultra'), url(./fonts/Specter-Ultra.otf) format('embedded-opentype'),
    url(./fonts/SpecterApp-Ultra.ttf) format('truetype'),
    url(./fonts/SpecterWeb-Ultra.woff2) format('woff2');
  font-weight: 400;
  font-style: normal;
}
