.sem-bg-footer {
  background: url('../../../assets/images/sem/roll_sem_lp_purple-bg-shape.svg') 0 101%/110%
    no-repeat;
}

.sem-bg-footer {
  background: url('../../../assets/images/sem/roll_sem_lp_purple-bg-shape.svg') 0 101%/100%
    no-repeat;
}

.font42 {
  font-size: 42px;
}

.font30 {
  font-size: 30px;
}

.purple-text {
  color: #4300d5;
  line-height: 32px;
}

.hero-qr {
  width: 240px;
  margin: auto;
  padding-bottom: 20px;
}

.promo_bold_text {
  font-family: Specter_bold, Arial, sans-serif !important;
}
