.purple-bg-features-alt {
  background: url('../../../assets/images/features/roll_industry_hero_shape_light_blue2.svg')
    center/contain no-repeat;
  top: -394px;
  right: -86px;
  position: absolute;
  display: block;
  z-index: -1;
  height: 516px !important;
  width: 454px !important;
  clip: rect(350px, auto, auto, auto);
}

@media (max-width: 767px) {
  .purple-bg-features-alt {
    width: 320px;
    height: 58px;
    top: -389px !important;
    right: -267px;
  }
}

.blue-bg-features-alt {
  background: url('../../../assets/images/features/roll_industry_hero_shape_charged_purple.svg')
    center/contain no-repeat;
  height: 383px;
  width: 434px;
  top: 290px;
  left: -148px;
  position: absolute;
  display: block;
  z-index: -1;
}

@media (max-width: 767px) {
  .blue-bg-features-alt {
    height: 365px;
    width: 414px;
    top: 320px;
    left: -302px;
  }
}

@media (max-width: 421) {
  .blue-bg-features {
    top: 320px !important;
  }
}

.blue-bg-industries {
  background: url(../../../assets/images/industries/shared/roll_industries_shape_light_blue.svg)
    center/contain no-repeat;
  height: 300px;
  width: 410px;
  top: -204px;
  left: 9px;
  position: absolute;
  display: block;
  clip: rect(160px, auto, auto, auto);
}

@media (max-width: 768px) {
  .blue-bg-industries {
    left: 266px;
  }
}

.text-hero-component .roll-breadcrumb {
  top: -44px;
}
