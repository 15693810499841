.card-box-redone {
  width: 400px;
  height: auto;
  position: relative;
  padding: 0px;
  background: #fff;
  border-radius: 30px;
}
@media (max-width: 768px) {
  .card-box-redone {
    width: 90% !important;
  }
}
.hero-qr-redone {
  width: 150px;
  margin: auto;
  padding-bottom: 20px;
}