@media (min-width: 768px) {
  .padding-override {
    padding: 55px !important;
  }
}

.center-vertically {
  justify-content: center;
}

.top-align {
  justify-content: start;
}

.align-left {
  flex-direction: row-reverse;
}

.align-right {
  flex-direction: row;
}

.side-by-side .btn-primary.light.pink{
  color: #cd0058;
  background-color: #fce3df;
  border-color: #fce3df;
}
.side-by-side .btn-primary.light.pink:hover{
  color: #cd0058;
    background-color: #fbd7d1;
    border-color: #fce3df;
}
