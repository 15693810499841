/* Key Features - Two Column Cube */

.sidebysideimgleftwhite {
  background-color: white;
  border-radius: 30px;
  padding: 25px 25px 25px 0;
}

.sidebysidewhite {
  background-color: white;
  border-radius: 30px;
  padding: 25px;
}

.btn {
  vertical-align: middle;
}

@media (min-width: 768px) {
  .btn-bottom-card-absolute {
    position: absolute;
    bottom: 46px;
    right: 0;
    left: 0;
    margin: auto;
  }
  .img-height-adjust {
    height: 84px !important;
  }
}

@media (min-width: 768px) and (max-width: 950px) {
  .card-box-height-adjust {
    position: relative;
    height: 450px !important;
  }
}

@media (min-width: 768px) {
  .d-xs-inline-block.col-sm-2.px-15.text-right.text-xs-left.h4.mb-0 > .img-responsive {
    padding-top: 5px;
  }
}

@media (min-width: 768px) {
  .btn-bottom-card-absolute {
    position: absolute;
    bottom: 46px;
    right: 0;
    left: 0;
    margin: auto;
  }
  .img-height-adjust {
    height: 84px !important;
  }
}

@media (min-width: 768px) and (max-width: 950px) {
  .card-box-height-adjust {
    position: relative;
    height: 450px !important;
  }
}
@media (min-width: 951px) {
  .card-box-height-adjust {
    position: relative;
    height: 404px !important;
  }
}

@media (max-width: 767px) {
  .js-carousel-mb-cards  .card-box {
    min-height: 472px;
    padding:20px !important;
  }
}