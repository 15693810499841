.drk-blue-faq-bg-features {
  /* FAQs_shape_dk_blue.svg */
  background: url('../../../assets/images/home-global/FAQs_shape_dk_blue.svg') center/contain
    no-repeat;
  height: 383px;
  width: 434px;
  top: 267rem;
  left: 1rem;
  position: absolute;
  z-index: -1;
}

.light-blue-faq-bg-features {
  background: url('../../../assets/images/home-global/FAQs_shape_lt_blue.svg') center/contain
    no-repeat;
  height: 383px;
  width: 434px;
  top: 278rem;
  left: 73rem;
  position: absolute;
  z-index: -1;
}

.amz-bg-large {
  background: url('../../../assets/images/partner/Roll_partner_bg_shape_gray_2x.png') bottom
    no-repeat;
  padding-bottom: 236px;
  top: 115px;
  background-position: top;
}

.fixed-height-84 {
  height: 84px;
}

@media (max-width: 1024px) {
  .drk-blue-faq-bg-features {
    height: 183px;
    width: 234px;
    top: 269rem;
    left: 131px;
  }
  .light-blue-faq-bg-features {
    height: 183px;
    width: 234px;
    top: 294.5rem;
    left: 45rem;
  }
}

@media (max-width: 768px) {
  .drk-blue-faq-bg-features {
    height: 183px;
    width: 234px;
    top: 398rem;
    left: -106px;
  }
  .light-blue-faq-bg-features {
    height: 183px;
    width: 234px;
    top: 247px;
    left: 314px;
  }
}
