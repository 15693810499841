.feature-industry-hero {
  position: relative;
  padding-top: 20px;
  z-index: 1000;
}
.feature-industry-hero h1 {
  margin-top: 45px;
}
.roll-breadcrumb {
  position: absolute;
  top: 0;
  padding-top: 12px;
}
@media (max-width: 767px) {
  .feature-industry-hero-rightcol {
    height: 100vw;
  }
}
@media (min-width: 768px) {
  .feat-hero-mr-override {
    margin-right: -25% !important;
  }
}
