a[data-component='ThreeColumnComponentWithCTA'] img {
  max-height: 70px;
}

.card-box-whitebg {
  background: #fff;
  border-radius: 30px;
  padding: 46px;
  height: 100%;
}
.card-box-nobg {
  border-radius: 30px;
  padding: 46px;
  height: 100%;
}
