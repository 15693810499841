.sem-hero .hero-wrap div.partner-hero-card-box{
  width: 450px;
  top: 0px;
  margin-left: 0px;
  margin-right: 50%;
}
.hero-qr-redone{
  width: 200px;
  padding-bottom: 0px !important;
}
@media (max-width: 478px) {
  .sem-hero .hero-wrap div.partner-hero-card-box{
    width: 100%;
  }
}