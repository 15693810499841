.amz-bg-large {
  background: url('../../../assets/images/partner/Roll_partner_bg_shape_gray_2x.png');
  background-repeat: no-repeat;
  background-position: 50% -53rem;
  background-size: 205rem;
  padding-bottom: 236px;
  overflow-x: hidden;
}

@media (max-width: 767px) {
  .amz-bg-large {
    background-position: 50% -16rem;
  }

  .img-radius {
    border-radius: 30px;
  }
}
