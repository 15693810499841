#languageDiv a {
  color: #121c4e;
  float: right;
}
#languageDiv a:hover {
  color: #7b7c7e;
  text-decoration: none;
}
.bb-gray-e9 {
  border-bottom: 1px solid #e9e9e9;
}
#featuresDropdown {
  width: 260px;
}
.user-locale ::before {
  content: '';
  float: left;
  width: 17px;
  height: 17px;
  background: center center/contain no-repeat;
  margin: 3px 5px 0 18px;
  /* background-image: url(/-/media/microsites/rollbyadp/images/home-global/icn-globe-black.svg); */
}

@media (max-width: 991px) {
  .global-header .header-wrap .nav-bar .nav-link-wrap.active {
    top: 44px;
  }

  .global-header .header-wrap #sticky-cta.sticky {
    display: block;
    width: 100%;
  }

  .global-header .header-wrap #sticky-cta {
    display: none;
    border-radius: 0;
  }

  .global-header .header-wrap .nav-bar .nav-links {
    top: 44px;
  }
  #featuresDropdown {
    width: 100%;
  }
}
@media (min-width: 992px) {
  .dropdown {
    position: relative;
    display: inline-block;
  }

  .dropmenu-content {
    display: none;
    position: absolute;
    background-color: #fff;
    min-width: 125px;
    box-shadow: 0 10px 20px 0 rgb(0 0 0 / 10%);
    z-index: 1;
    padding-top: 20px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    padding-bottom: 20px;
  }

  .dropmenu-content a {
    display: block !important;
    font-family: Specter_regular, Arial, sans-serif !important;
    font-size: 16px !important;
    text-transform: inherit !important;
    padding: 10px 0 10px 14px !important;
  }

  .dropmenu-content a:hover {
    background: #b3d4fc9a !important;
  }

  .dropdown:hover .dropmenu-content {
    display: block;
  }
}

#languageDiv a {
  color: #121c4e;
  float: right;
}
#languageDiv a:hover {
  color: #7b7c7e;
  text-decoration: none;
}
.bb-gray-e9 {
  border-bottom: 1px solid #e9e9e9;
}
#featuresDropdown {
  width: 260px;
}
/* .user-locale ::before {
    content: "";
    float: left;
    width: 17px;
    height: 17px;
    background: center center/contain no-repeat;
    margin: 3px 5px 0 18px;
    background-image: url(/-/media/microsites/rollbyadp/images/home-global/icn-globe-black.svg);
  } */
@media (max-width: 991px) {
  .global-header .header-wrap .nav-bar .nav-link-wrap.active {
    top: 44px;
  }
  .global-header .header-wrap .nav-bar .nav-links {
    top: 44px;
  }
  #featuresDropdown {
    width: 100%;
  }
}
@media (min-width: 992px) {
  .dropdown {
    position: relative;
    display: inline-block;
  }

  .dropmenu-content {
    display: none;
    position: absolute;
    background-color: #fff;
    min-width: 125px;
    box-shadow: 0 10px 20px 0 rgb(0 0 0 / 10%);
    z-index: 1;
    padding-top: 20px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    padding-bottom: 20px;
  }

  .dropmenu-content a {
    display: block !important;
    font-family: Specter_regular, Arial, sans-serif !important;
    font-size: 16px !important;
    text-transform: inherit !important;
    padding: 10px 0 10px 14px !important;
  }

  .dropmenu-content a:hover {
    background: #b3d4fc9a !important;
  }

  .dropdown:hover .dropmenu-content {
    display: block;
  }
}
